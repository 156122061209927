import { css } from '@emotion/react'

export const commonCss = {
  fullPage: css`
    min-height: calc(100vh - 64px - 339px);

    @media (min-width: 1280px) {
      min-height: calc(100vh - 152px - 261px);
    }
  `,

  noScroll: css`
    ::-webkit-scrollbar {
      display: none;
    }
  `,

  scrollbar: css`
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 8px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
  `,

  lineClamp: (lines: number) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,

  imageScaleUpOnHover: css`
    img {
      transition: transform 0.5s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  `,

  onlyMobileVisible: css`
    @media (min-width: 1280px) {
      display: none;
    }
  `,

  onlyPcVisibleBlock: css`
    display: none;

    @media (min-width: 1280px) {
      display: block;
    }
  `,

  onlyPcVisibleFlex: css`
    display: none;

    @media (min-width: 1280px) {
      display: flex;
    }
  `,

  bold: css`
    font-weight: 700;
  `,

  horizontalBar: css`
    background: #f2f4f8;
    color: #f2f4f8;
    width: 100%;
    height: 1px;
    border: none;
  `,

  overlay: css`
    background: rgba(0, 0, 0, 0.5);
  `,

  opacityZero: css`
    opacity: 0;
  `,

  observer: css`
    width: 0;
    height: 0;
    opacity: 0;
  `
}
