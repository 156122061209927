/** @jsxImportSource @emotion/react */
import { useId } from 'react';
import ReactSelect, { GroupBase, Props } from 'react-select';
import DropdownIndicator from './DropdownIndicator/DropdownIndicator';
import { SelectOption } from './types/select.types';
import { selectCss } from './Select.styles';
import type { Interpolation, Theme } from '@emotion/react';
import CustomSelectOption from './CustomSelectOption/CustomSelectOption';

export interface SelectProps {
  cssStyle?: Interpolation<Theme>;
}

export default function Select<Option extends SelectOption, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({ cssStyle, ...restProps }: SelectProps & Props<Option, IsMulti, Group>) {
  return (
    <div css={[selectCss.wrapper, cssStyle]}>
      <ReactSelect {...restProps} instanceId={useId()} components={{ DropdownIndicator, Option: CustomSelectOption }} unstyled={true} className="react-select-container" classNamePrefix="react-select" isSearchable={false} />
    </div>
  );
}
