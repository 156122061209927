import { css } from '@emotion/react';

export const sidebarContainer = css`
  width: 200px;
  min-width: 200px;
  height: 100%;
  background: #231f20;
`;

export const sidebarList = css`
  padding-left: 24px;
`;

export const sidebarItem = css`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 52px;
  padding: 12px 8px 12px 32px;
  border-radius: 10px 0 0 10px;
  background: inherit;
  color: #fff;
  font-style: normal;
  font-size: 12px;
  line-height: 17.38px;
  font-weight: 400;
`;

export const sidebarLabel = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const activeStyle = css`
  padding: 12px 8px;
  background: #6c696a;
  font-weight: 700;
  font-size: 14px;
  line-height: 20.27px;
`;
