import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateBrachWashMachineListApi } from 'features/branchManagement/api/washMachine';
import { BRANCH_WASH_MACHINE_LIST } from './useGetBranchWashMachineList';

export default function useUpdateBranchWashMachine({ onClose }: { onClose: () => void }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBrachWashMachineListApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BRANCH_WASH_MACHINE_LIST] });
      onClose();
    },
  });
}
