import { css } from '@emotion/react';

export const inputWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 40px;

  border-radius: 4px;
  background: #fff;
`;

export const inputStyle = css`
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: 0px var(--Padding-4, 4px);

  border: none;
  background: #fff;

  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ::placeholder {
    color: #989898;
  }

  &:disabled {
    cursor: not-allowed;
    color: #989898;
  }
`;

export const searchIconWrapper = css`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background: #6c696a;
`;
