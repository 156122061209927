import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchLogin } from 'features/auth/api/login';
import { getMeQueryOptions } from 'features/userProfile/hooks/useGetMe';
import { useNavigate } from 'react-router-dom';
import authStorage from 'storages/authStorage';

interface UseLogin {
  setErrorResCode: (resCode: number | null) => void;
}

export const useLogin = ({ setErrorResCode }: UseLogin) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fetchLogin,
    onSuccess: (data) => {
      authStorage.setAccessToken(data.token.accessToken);
      queryClient.ensureQueryData({ queryKey: getMeQueryOptions.queryKey, revalidateIfStale: true });

      navigate('/home');
    },
    onError: (error: any) => {
      if (error.response.data.message) {
        setErrorResCode(error.response.data.resCode);
      }
    },
  });
};
