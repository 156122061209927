import { useMemo } from 'react';
import useGetBranchRegionList from './react-query/useGetBranchRegionList';
import type { BranchRegionLabel, BranchRegionValue } from '../api/dtos/getBranchRegionList.dto';

export type BranchRegionOptionLabel = '전체' | BranchRegionLabel;
export type BranchRegionOptionValue = 'all' | BranchRegionValue;
export interface BranchRegionOption {
  label: BranchRegionOptionLabel;
  value: BranchRegionOptionValue;
}

export default function useBranchRegionOptions() {
  // 지역 조회
  const { data } = useGetBranchRegionList();

  const branchRegionOptionsWithoutAll = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }) as BranchRegionOption);
  }, [data]);

  const branchRegionOptions = useMemo(() => {
    if (!data) return [];

    const allOption = { label: '전체', value: 'all' } as BranchRegionOption;
    const options = data.map((item) => ({ label: item.codeValue, value: item.code }) as BranchRegionOption);

    return [allOption, ...options];
  }, [data]);

  return { branchRegionOptionsWithoutAll, branchRegionOptions };
}
