import { Flex, Text, Grid, useDisclosure } from '@chakra-ui/react';
import BranchInformationItem from './BranchInformationItem';
import Button from 'components/Button/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import useGetBranchManagementInformation from 'features/branchManagement/hooks/react-query/useGetBranchManagementInformation';
import { useDeleteBranch } from 'features/branchManagement/hooks/react-query/useDeleteBranch';
import useBranchTypeMap from 'features/branchSearch/hooks/useBranchTypeMap';
import useBranchRegionMap from 'features/branchSearch/hooks/useBranchRegionMap';
import { formatDate } from 'utils/formatDate';
import { STORAGE_URL } from 'common/constants';

interface Props {
  selectedBranchSequence: number;
  onEditClick: () => void;
  onCloseBranchInformationModal: () => void;
}

const BranchInformationView = ({ selectedBranchSequence, onEditClick, onCloseBranchInformationModal }: Props) => {
  const { isOpen: isOpenBranchModal, onOpen: onOpenBranchDeleteModal, onClose: onCloseBranchDeleteModal } = useDisclosure();

  // 지점 유형 변환
  const { branchTypeMap } = useBranchTypeMap();
  // 지역 코드 변환
  const { branchRegionMap } = useBranchRegionMap();
  // 지점 관리 상세페이지 조회
  const { data: branchInformation } = useGetBranchManagementInformation(selectedBranchSequence);
  // 지점 삭제
  const { mutate: deleteBranch } = useDeleteBranch({ onCloseBranchInformationModal, onCloseBranchDeleteModal });

  const onDeleteBranch = () => {
    deleteBranch(selectedBranchSequence);
  };

  return (
    <>
      {/* modal content */}
      <Flex align="flex-start" alignSelf="stretch" p="16px" gap="24px" h="100%">
        <Flex w="160px" h="120px" overflow="hidden">
          {branchInformation?.branchImgPath && <img src={`${STORAGE_URL}${branchInformation?.branchImgPath}`} alt="branch image" width="100%" height="100%" style={{ objectFit: 'cover' }} />}
          {!branchInformation?.branchImgPath && <img src="/assets/images/none_image.png" alt="none image" width="100%" height="100%" style={{ objectFit: 'cover' }} />}
        </Flex>

        <Flex justifyContent="center" gap="24px" flex={1}>
          <Grid h="full" templateRows="repeat(11, 60px)" templateColumns="repeat(1, 1fr)" color="white">
            <BranchInformationItem label="지점 구분" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation && branchTypeMap[branchInformation?.codeType]}점
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="지역 선택" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation && branchRegionMap[branchInformation?.codeRegion]}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="지점 이름" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchNm}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="지점장" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchOwnerNm}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label={'사업자\n등록번호'} row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchBrn}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="주소" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchAddr}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label={'지점장\n 전화번호'} row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchOwnerPhone}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label={'관리자\n 전화번호'} row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchAdminPhone}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="메모" row={3} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.branchMemo}
              </Text>
            </BranchInformationItem>
          </Grid>
          <Grid h="full" templateRows="repeat(11, 60px)" templateColumns="repeat(1, 1fr)" color="white">
            <BranchInformationItem label="설치일" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {formatDate(branchInformation?.dvcInstallDt)}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="CAT ID" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.catId}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="태블릿 버전" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.tabletVersion}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="PLC 버전" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.plcVersion}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="외부 아이피" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.pubIp}
              </Text>
            </BranchInformationItem>

            <BranchInformationItem label="DDNS" row={1} col={1}>
              <Text fontSize={14} fontWeight={400} p="10px 8px">
                {branchInformation?.ddnsAddr}
              </Text>
            </BranchInformationItem>
          </Grid>
        </Flex>
      </Flex>

      {/* modal footer */}
      <Flex justify="center" align="center" gap="16px" w="full" p="24px 0">
        <Button buttonType="fill" buttonSize={40} onClick={onEditClick}>
          수 정
        </Button>
        <Button buttonType="outline" buttonSize={40} onClick={onOpenBranchDeleteModal}>
          삭 제
        </Button>
        <Button buttonType="fill" buttonSize={40} onClick={onCloseBranchInformationModal}>
          닫 기
        </Button>
      </Flex>

      <ConfirmModal title="삭제하시겠습니까?" isOpen={isOpenBranchModal} onConfirm={onDeleteBranch} onClose={onCloseBranchDeleteModal} />
    </>
  );
};

export default BranchInformationView;
