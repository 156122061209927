import { atom } from 'recoil';

export const googleMapState = atom<google.maps.Map | null>({
  key: 'googleMapState',
  default: null,
  dangerouslyAllowMutability: true,
});

export const selectedMarkerKeyState = atom<string | null>({
  key: 'selectedMarkerKeyState',
  default: null,
});

export const selectedBranchSeqState = atom<number | null>({
  key: 'selectedBranchSeqState',
  default: null,
});
