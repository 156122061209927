/** @jsxImportSource @emotion/react */
import { logoImage, titleContainer, titleText } from './Title.styles';

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  return (
    <div css={titleContainer}>
      <img src="/assets/images/title_logo.png" alt="title logo" css={logoImage} />
      <h2 css={titleText}>{title}</h2>
    </div>
  );
};

export default Title;
