import { Button as ChakraButton, Box, Flex, Radio, RadioGroup, Text, Grid, useDisclosure } from '@chakra-ui/react';
import Button from 'components/Button/Button';
import { FormProvider } from 'react-hook-form';
import WashMachineItem from './WashMachineItem';
import ConfirmModal from 'components/Modals/ConfirmModal';
import AlertModal from 'components/Modals/AlertModal';
import useWashMachineForm from '../../../hooks/useWashMachineForm';

interface Props {
  branchSequence: number;
  onClose: () => void;
}

const WashMachineCreateForm = ({ branchSequence, onClose }: Props) => {
  const { isOpen: isOpenRequiredFieldsModal, onOpen: onOpenRequiredFieldsModal, onClose: onCloseRequiredFieldsModal } = useDisclosure();

  const {
    methods,
    fields,
    machineTypeOptions,
    machineModelTypeOptions,
    plcProviderTypeOptions,
    machineModelType,
    isOpenCodeTypeChangeModal,
    addWashMachine,
    removeWashMachineCheck,
    handleRadioChange,
    confirmCodeTypeChange,
    cancelCodeTypeChange,
    handleSubmit,
  } = useWashMachineForm({ branchSequence, onOpenRequiredFieldsModal, onClose });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Box m="16px 0" w="full">
          <Flex alignItems="center" w="full" h="40px" borderLeft="3px solid #C30D24">
            <Text fontSize={14} fontWeight={700} color="white" w="160px" p="0 8px">
              지점 타입
            </Text>
            <RadioGroup display="flex" justifyContent="flex-start" alignItems="center" gap="35px" value={machineModelType} onChange={handleRadioChange}>
              {machineTypeOptions.map((option) => (
                <Radio key={option.value} value={option.value} flex={1} textAlign="center">
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </Flex>
        </Box>

        <Grid templateColumns="repeat(2, 1fr)" gap="16px" w="full" h="560px">
          {fields.map((field, index) => (
            <WashMachineItem key={field.id} index={index} removeWashMachineCheck={removeWashMachineCheck} machineModelTypeOptions={machineModelTypeOptions} plcProviderTypeOptions={plcProviderTypeOptions} />
          ))}
          {fields.length < 4 && (
            <Flex justifyContent="center" align="center" borderRadius={10} bg="#393536" color="white" w="full" h="272px">
              <ChakraButton type="button" flexDirection="column" gap="8px" w="104px" h="80px" p="17px 24.5px" bg="#6C696A" color="white" onClick={addWashMachine}>
                <img src="/assets/icons/plus.png" alt="plus icons" width={18} height={18} />
                항목 추가
              </ChakraButton>
            </Flex>
          )}
        </Grid>

        <Flex justifyContent="center" gap="16px" p="24px 0">
          <Button type="submit" buttonType="fill" buttonSize={40}>
            확 인
          </Button>
          <Button type="button" buttonType="outline" buttonSize={40} onClick={onClose}>
            취 소
          </Button>
        </Flex>
      </form>

      {/* 지점 타입 변경 시 안내 모달 */}
      <ConfirmModal
        isOpen={isOpenCodeTypeChangeModal}
        title={'지점 타입을 변경하시겠습니까?'}
        description="지점 타입을 변경하면 이전에 저장된 내용이 초기화됩니다."
        confirmText="확인"
        closeText="취소"
        onConfirm={confirmCodeTypeChange}
        onClose={cancelCodeTypeChange}
      />

      {/* 세차기 미입력 안내 */}
      <AlertModal title="세차기 정보를 입력해주세요." isOpen={isOpenRequiredFieldsModal} onClose={onCloseRequiredFieldsModal} />
    </FormProvider>
  );
};

export default WashMachineCreateForm;
