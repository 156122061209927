import { Flex } from '@chakra-ui/react';
import useDashboard from '../hooks/useDashboard';
import DashboardTableContainer from './DashboardTableContainer';

const DashboardContainer = () => {
  const { allData, regionData } = useDashboard();

  return (
    <Flex h="100vh" alignItems="center" padding={'40px'} gap="40px">
      <DashboardTableContainer isDirectStore tableData={allData} regionName="직영" />
      {regionData?.map((data, tableIndex) => <DashboardTableContainer key={tableIndex} isDirectStore={false} tableData={data.list} regionName={data.regionName} />)}
    </Flex>
  );
};

export default DashboardContainer;
