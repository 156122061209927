import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const edit = defineStyle({
  padding: '4px',
  alignItems: 'flex-start',
  background: '#fff',
  borderRadius: '4px',

  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',

  caretColor: '#0040E5', // 커서 색상
  resize: 'none',
});

export const textareaTheme = defineStyleConfig({
  variants: { edit },
});
