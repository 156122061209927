import { useState } from 'react';
import AlarmModal from '../../components/AlarmModal';
import LogoutModal from '../../components/LogoutModal';
import Header from '../../components/Header/Header';
import useNewUrgentAlarm from '../../features/alarm/hooks/useNewUrgentAlarm';
import { useLogout } from 'features/auth/hooks/useLogout';

const HeaderContainer = () => {
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);
  const { hasUrgentAlarm, isModalOpen: isAlarmModalOpen, onCloseAlarmModal, onOpenAlarmModal, tableHeader, tableData } = useNewUrgentAlarm();

  const handleToggleAlarm = () => {
    onOpenAlarmModal();
    setIsOpenLogoutModal(false);
  };

  const handleToggleLogoutModal = () => {
    setIsOpenLogoutModal((prev) => !prev);
  };

  const { logout } = useLogout();

  return (
    <>
      <Header hasUrgentAlarm={hasUrgentAlarm} isAlarmModalOpen={isAlarmModalOpen} onClickUrgentAlarm={handleToggleAlarm} onLogout={handleToggleLogoutModal} />
      {isAlarmModalOpen && <AlarmModal tableData={tableData} tableHeader={tableHeader} onClose={onCloseAlarmModal} />}
      {isOpenLogoutModal && <LogoutModal onClick={logout} />}
    </>
  );
};

export default HeaderContainer;
