import { useMemo } from 'react';
import useGetPlcProViderTypeList from './react-query/useGetPlcProviderTypeList';
import { PlcProviderTypeCode, PlcProviderTypeLabel } from '../api/dtos/getPlcProviderTypeList.dto';

type PlcProviderTypeMap = Record<PlcProviderTypeCode, PlcProviderTypeLabel>;

export default function usePlcProviderTypeMap() {
  const { data } = useGetPlcProViderTypeList();

  const plcProviderTypeMap = useMemo(() => {
    if (!data) return {} as PlcProviderTypeMap;

    return data.reduce((acc, item) => {
      acc[item.code] = item.codeValue;
      return acc;
    }, {} as PlcProviderTypeMap);
  }, [data]);

  return { plcProviderTypeMap };
}
