import { api } from 'api/api';
import { BranchDetailType, BranchWashCountType, UpdateBranchRequestType } from '../types';

// 지점상세 정보 조회 (지점 상세 페이지)
export const getBranchDetail = async (branchSeq: string): Promise<BranchDetailType> => {
  try {
    const response = await api.get(`/branch/getBranchs/Detail/${branchSeq}`);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

// 지점별 세차횟수 통계
export const getBranchWashCount = async (branchSeq: string): Promise<BranchWashCountType> => {
  try {
    const response = await api.get(`/branch/getBranchs/branchWashCount/${branchSeq}`);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

// 지점 수정
export const updateBranch = async (data: UpdateBranchRequestType): Promise<any> => {
  try {
    const response = await api.put(`/branch/updBranchs`, data);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
