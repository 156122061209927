import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import { colors } from 'theme';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { branchListLoadingCss } from '../styles/branchListLoading.styles';
import useTranslateBranchStatus from '../hooks/useTranslateBranchStatus';
import type { BranchSearchParams } from '../types/BranchSearch.types';
import useInfiniteBranchStatusList from '../hooks/useInfiniteBranchStatusList';

const BranchStatusList = ({ codeType, codeRegion, searchVal }: BranchSearchParams) => {
  const { branchStatusList, observerRef, hasNextPage, isFetching } = useInfiniteBranchStatusList({ codeType, codeRegion, searchVal });
  const { translateBranchStatusColor } = useTranslateBranchStatus();

  return (
    <Stack gap="0px" overflowY={'auto'} flex={1}>
      <Box bg="red.500" h="10" display={'flex'} justifyContent={'center'} alignItems={'center'} borderTopRightRadius={10} borderTopLeftRadius={10} fontSize={'14px'} fontWeight={700}>
        지점 요약 현황
      </Box>
      <Scrollbar
        css={css`
          flex: 1;
          overflow-y: auto;
          color: white;
        `}
        style={{ backgroundColor: '#231f20' }}
      >
        <Grid templateColumns="repeat(4, 1fr)" gap={'2px'} backgroundColor={colors.gray}>
          {branchStatusList.map((item) => (
            <Flex key={item.brancSeq} alignItems={'center'} color="white" p={'11px 15px'} height={'36px'} gap="15px">
              <Box w="10px" h="10px" bg={translateBranchStatusColor(item.branchStatus)} borderRadius={'50%'} />
              <Text fontSize={'10px'} fontWeight={700} lineHeight={'14.48px'}>
                {item.branchNm}
              </Text>
            </Flex>
          ))}
        </Grid>

        {isFetching ? <LoadingSpinner cssStyle={branchListLoadingCss.block} /> : hasNextPage && <Box h={'114px'} />}
        <Box marginTop={'-8px'} w={'0px'} h={'0px'} opacity={0} ref={hasNextPage ? observerRef : null} />
      </Scrollbar>
      <Box bg="red.500" h="16px" display={'flex'} justifyContent={'center'} alignItems={'center'} borderBottomRightRadius={10} borderBottomLeftRadius={10} />
    </Stack>
  );
};

export default BranchStatusList;
