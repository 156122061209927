import { css } from '@emotion/react';

export const customPaginationCss = {
  container: css`
    display: flex;
    gap: 6px;
  `,

  list: css`
    display: flex;
    gap: 6px;

    li {
      width: 24px;
      height: 24px;
    }
  `,

  button: css`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    border: 1px solid #adb5bd;
  `,

  page: css`
    font-weight: 700;
    font-size: 14px;

    color: #adb5bd;
  `,

  active: css`
    background: #c30d24;
    color: #fff;
    border-color: #c30d24;
  `,
};
