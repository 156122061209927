import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getAlarmListApi } from 'features/notificationLog/api/alarm';
import type { GetAlarmListRequestDto } from 'features/notificationLog/api/dtos/getAlarmList.dto';

const ALARM_LIST = 'ALARM_LIST';

export default function useGetAlarmList({
  //
  searchStartDt,
  searchEndDt,
  notificationType,
  keywordType,
  keyword,
  page,
  size,
}: GetAlarmListRequestDto) {
  return useQuery({
    queryKey: [ALARM_LIST, { searchStartDt }, { searchEndDt }, { notificationType }, { keywordType }, { keyword }, { page }, { size }],
    queryFn: () =>
      getAlarmListApi({
        searchStartDt,
        searchEndDt,
        notificationType,
        keywordType,
        keyword,
        page,
        size,
      }),
    placeholderData: keepPreviousData,
  });
}
