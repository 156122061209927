import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const editStyle = definePartsStyle({
  field: {
    height: '40px',
    padding: '4px',
    alignItems: 'center',
    gap: '6px',
    background: '#fff',
    borderRadius: '4px',

    color: '#000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',

    caretColor: '#0040E5', // 커서 색상

    _invalid: {
      border: '3px solid #C30D24', // 입력 필드가 비어 있을 때
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: { edit: editStyle },
});
