import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useForm, useFieldArray } from 'react-hook-form';
import useMachineModelTypeOptions from 'features/branchManagement/hooks/useMachineModelTypeOptions';
import usePlcProviderTypeOptions from 'features/branchManagement/hooks/usePlcProviderTypeOptions';
import useMachineTypeOptions from 'features/branchSearch/hooks/useMachineTypeOptions';
import useCreateBranchWashMachine from 'features/branchManagement/hooks/react-query/useCreateBranchWashMachine';
import { BranchWashMachineInitialFormSchema, branchWashMachineCreateFormSchema } from 'features/branchManagement/schema/branchWashMachineForm.schema';
import { MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import { CreateBranchWashMachineDto } from '../api/dtos/createBranchWashMachineList.dto';

interface FormValues {
  branchWashMachineList: BranchWashMachineInitialFormSchema[];
}

interface UseWashMachineFormProps {
  branchSequence: number;
  onOpenRequiredFieldsModal: () => void;
  onClose: () => void;
}

export default function useWashMachineForm({ branchSequence, onOpenRequiredFieldsModal, onClose }: UseWashMachineFormProps) {
  const [machineModelType, setMachineModelType] = useState<MachineTypeValue>('MT0001');
  const [pendingMachineModelType, setPendingMachineModelType] = useState<MachineTypeValue | null>(null);

  // 모달 상태 관리
  const { isOpen: isOpenCodeTypeChangeModal, onOpen: onOpenCodeTypeChangeModal, onClose: onCloseCodeTypeChangeModal } = useDisclosure();

  // 세차기 모델 타입 조회 (일반형 - MT0001 / 터널형 - MT0002 )
  const { machineTypeOptions } = useMachineTypeOptions();
  // 세차기 모델 옵션 조회
  const { machineModelTypeOptions } = useMachineModelTypeOptions(machineModelType);
  // PLC 제조사 옵션 조회
  const { plcProviderTypeOptions } = usePlcProviderTypeOptions();

  // react-hook-form 사용하여 폼 상태 관리
  const methods = useForm<FormValues>({
    defaultValues: {
      branchWashMachineList: [
        {
          branchSeq: branchSequence,
          codeType: machineModelType,
          codeModel: undefined,
          plcPrivider: undefined,
          plcModel: undefined,
          plcVersion: '',
        },
      ],
    },
  });

  const { control, reset, handleSubmit } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'branchWashMachineList',
  });

  // 세차기 등록
  const { mutate: createBranchWashMachine } = useCreateBranchWashMachine();

  // 세차기 추가
  const addWashMachine = () => {
    if (fields.length < 4) {
      append({
        branchSeq: branchSequence,
        codeType: machineModelType,
        codeModel: undefined,
        plcPrivider: undefined,
        plcModel: undefined,
        plcVersion: '',
      });
    }
  };

  // 세차기 삭제
  const removeWashMachineCheck = (index: number) => {
    remove(index);
  };

  // 지점 타입 변경 시 필드 초기화
  const clearValues = () => {
    const clearedValues = fields.map((field) => ({
      ...field,
      codeModel: undefined,
      plcPrivider: undefined,
      plcModel: undefined,
      plcVersion: '',
    }));
    reset({ branchWashMachineList: clearedValues });
  };

  // 지점 타입 변경
  const handleRadioChange = (nextValue: string) => {
    setPendingMachineModelType(nextValue as MachineTypeValue);
    onOpenCodeTypeChangeModal();
  };

  // 지점 타입 변경 확인
  const confirmCodeTypeChange = () => {
    if (pendingMachineModelType) {
      setMachineModelType(pendingMachineModelType);
    }
    clearValues();
    onCloseCodeTypeChangeModal();
  };

  // 지점 타입 변경 취소
  const cancelCodeTypeChange = () => {
    setPendingMachineModelType(null);
    onCloseCodeTypeChangeModal();
  };

  // 생성하기
  const onSubmit = (data: FormValues) => {
    const validationResults = data.branchWashMachineList.map((item) => branchWashMachineCreateFormSchema.safeParse(item));
    const hasInvalid = validationResults.some((result) => !result.success);

    if (hasInvalid) {
      return onOpenRequiredFieldsModal();
    }

    const transformedData: CreateBranchWashMachineDto[] = data.branchWashMachineList.map((item) => ({
      branchSeq: item.branchSeq,
      codeType: item.codeType,
      codeModel: item.codeModel!,
      plcPrivider: item.plcPrivider!,
      plcModel: item.plcModel!,
      plcVersion: item.plcVersion,
    }));

    createBranchWashMachine(transformedData, {
      onSuccess: () => onClose(),
    });
  };

  return {
    methods,
    fields,
    machineTypeOptions,
    machineModelTypeOptions,
    plcProviderTypeOptions,
    machineModelType,
    isOpenCodeTypeChangeModal,
    addWashMachine,
    removeWashMachineCheck,
    handleRadioChange,
    confirmCodeTypeChange,
    cancelCodeTypeChange,
    handleSubmit: handleSubmit(onSubmit),
  };
}
