import { useCallback, useEffect } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { alarmSearchSchema, type AlarmSearchSchema } from '../schema/alarmSearch.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatDate } from 'utils/formatDate';
import type { AlarmTypeValue } from '../api/dtos/getAlarmTypeList.dto';
import type { AlarmSearchTypeValue } from '../api/dtos/getAlarmSearchTypeList.dto';
import dayjs from 'dayjs';
import useErrorAlertEffect from 'hooks/useErrorAlertEffect';

export default function useAlarmSearch() {
  const { control, reset, handleSubmit, formState, clearErrors } = useForm<AlarmSearchSchema>({
    reValidateMode: 'onSubmit',
    resolver: zodResolver(alarmSearchSchema),
    defaultValues: {
      searchStartDt: null,
      searchEndDt: null,
      notificationType: 'NTTP001',
      keywordType: 'SKT001',
      keyword: undefined,
    },
  });

  useErrorAlertEffect({ formState, clearErrors });

  const [query, setQuery] = useQueryParams({
    searchStartDt: StringParam,
    searchEndDt: StringParam,
    notificationType: StringParam,
    keywordType: StringParam,
    keyword: StringParam,
    page: NumberParam,
  });

  useEffect(() => {
    reset({
      searchStartDt: query.searchStartDt ? new Date(query.searchStartDt) : null,
      searchEndDt: query.searchEndDt ? new Date(query.searchEndDt) : null,
      notificationType: (query.notificationType as AlarmTypeValue) || 'NTTP001',
      keywordType: (query.keywordType as AlarmSearchTypeValue) || 'SKT001',
      keyword: query.keyword || '',
    });
  }, [query.keyword, query.keywordType, query.notificationType, query.searchEndDt, query.searchStartDt, reset]);

  const onSubmit: SubmitHandler<AlarmSearchSchema> = useCallback(
    (values) => {
      setQuery({
        searchStartDt: values.searchStartDt ? formatDate(values.searchStartDt) : formatDate(dayjs().subtract(1, 'week')),
        searchEndDt: values.searchEndDt ? formatDate(values.searchEndDt) : formatDate(new Date()),
        notificationType: values.notificationType,
        keywordType: values.keywordType,
        keyword: values.keyword,
        page: 1,
      });
    },
    [setQuery]
  );

  return {
    control,
    handleSubmit,
    onSubmit,
    query,
  };
}
