import { useQuery } from '@tanstack/react-query';
import { getCommonCodeApi } from 'features/common/api/common';
import type { AlarmSearchType } from 'features/notificationLog/api/dtos/getAlarmSearchTypeList.dto';

const ALARM_SEARCH_TYPE_LIST = 'ALARM_SEARCH_TYPE_LIST';

export default function useGetAlarmSearchTypeList() {
  return useQuery({
    queryKey: [ALARM_SEARCH_TYPE_LIST],
    queryFn: () => getCommonCodeApi<AlarmSearchType>('search_keyword_type'),
  });
}
