import { Button, Flex } from '@chakra-ui/react';
import { colors } from 'theme';
import type { BranchTypeOptionValue, BranchTypeOption } from '../hooks/useBranchTypeOptions';

interface BranchDivisionProps {
  branchTypeOptions: BranchTypeOption[];
  codeType: string[];
  onSelectBranchDivision: (selectedBranch: BranchTypeOptionValue) => () => void;
  style?: React.CSSProperties;
}

const BranchDivision = ({ branchTypeOptions, codeType, onSelectBranchDivision, style }: BranchDivisionProps) => {
  return (
    <Flex w="100%" style={style}>
      {branchTypeOptions.map(({ label, value }) => (
        <Button
          key={value}
          w="62px"
          height={'36px'}
          bg={codeType.includes(value) ? 'red.500' : colors.grayBlack}
          fontSize={'14px'}
          fontWeight={codeType.includes(value) ? 700 : 400}
          _hover={{ bg: colors.gray }}
          color={'white'}
          onClick={onSelectBranchDivision(value)}
        >
          {label}
        </Button>
      ))}
    </Flex>
  );
};

export default BranchDivision;
