import { css } from '@emotion/react';

const heading1Style = css`
  font-size: 56px;
  font-style: normal;
  line-height: 70px; /* 125% */
  letter-spacing: -0.7px;
`;
const heading2Style = css`
  font-size: 48px;
  font-style: normal;
  line-height: 60px; /* 125% */
  letter-spacing: -0.6px;
`;
const heading3Style = css`
  font-size: 42px;
  font-style: normal;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.6px;
`;
const heading4Style = css`
  font-size: 36px;
  font-style: normal;
  line-height: 48px; /* 133.333% */
  letter-spacing: -0.6px;
`;
const heading5Style = css`
  font-size: 32px;
  font-style: normal;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.5px;
`;
const heading6Style = css`
  font-size: 28px;
  font-style: normal;
  line-height: 38px; /* 135.714% */
  letter-spacing: -0.5px;
`;
const heading7Style = css`
  font-size: 24px;
  font-style: normal;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.4px;
`;

const paragraph1Style = css`
  font-size: 20px;
  font-style: normal;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
`;
const paragraph2Style = css`
  font-size: 18px;
  font-style: normal;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.3px;
`;
const paragraph3Style = css`
  font-size: 16px;
  font-style: normal;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.3px;
`;
const paragraph4Style = css`
  font-size: 14px;
  font-style: normal;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.2px;
`;
const paragraph5Style = css`
  font-size: 12px;
  font-style: normal;
  line-height: 14px; /* 116.667% */
  letter-spacing: -0.2px;
`;
const paragraph6Style = css`
  font-size: 15px;
  font-style: normal;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.2px;
`;

const typography = {
  'H1/56r': css`
    ${heading1Style};
    font-weight: 400;
  `,
  'H1/56b': css`
    ${heading1Style};
    font-weight: 700;
  `,

  'H2/48r': css`
    ${heading2Style};
    font-weight: 400;
  `,
  'H2/48b': css`
    ${heading2Style};
    font-weight: 700;
  `,

  'H3/42r': css`
    ${heading3Style};
    font-weight: 400;
  `,
  'H3/42b': css`
    ${heading3Style};
    font-weight: 700;
  `,

  'H4/36r': css`
    ${heading4Style};
    font-weight: 400;
  `,
  'H4/36b': css`
    ${heading4Style};
    font-weight: 700;
  `,

  'H5/32r': css`
    ${heading5Style};
    font-weight: 400;
  `,
  'H5/32b': css`
    ${heading5Style};
    font-weight: 700;
  `,

  'H6/28r': css`
    ${heading6Style};
    font-weight: 400;
  `,
  'H6/28b': css`
    ${heading6Style};
    font-weight: 700;
  `,

  'H7/24r': css`
    ${heading7Style};
    font-weight: 400;
  `,
  'H7/24b': css`
    ${heading7Style};
    font-weight: 700;
  `,

  'P1/20r': css`
    ${paragraph1Style};
    font-weight: 400;
  `,
  'P1/20b': css`
    ${paragraph1Style};
    font-weight: 700;
  `,

  'P2/18r': css`
    ${paragraph2Style};
    font-weight: 400;
  `,
  'P2/18b': css`
    ${paragraph2Style};
    font-weight: 700;
  `,

  'P3/16r': css`
    ${paragraph3Style};
    font-weight: 400;
  `,
  'P3/16b': css`
    ${paragraph3Style};
    font-weight: 700;
  `,

  'P4/14r': css`
    ${paragraph4Style};
    font-weight: 400;
  `,
  'P4/14b': css`
    ${paragraph4Style};
    font-weight: 700;
  `,

  'P5/12r': css`
    ${paragraph5Style};
    font-weight: 400;
  `,
  'P5/12b': css`
    ${paragraph5Style};
    font-weight: 700;
  `,

  'P6/15r': css`
    ${paragraph6Style};
    font-weight: 400;
  `,
  'P6/15m': css`
    ${paragraph6Style};
    font-weight: 500;
  `,
  'P6/15b': css`
    ${paragraph6Style};
    font-weight: 700;
  `,
};

export default typography;
