import { useQuery } from '@tanstack/react-query';
import type { BranchRegion } from 'features/branchSearch/api/dtos/getBranchRegionList.dto';
import { getCommonCodeApi } from 'features/common/api/common';

const BRANCH_REGION_LIST = 'BRANCH_REGION_LIST';

const sortOrder = ['서울', '경기', '인천', '강원', '충북', '충남', '대전', '세종', '광주', '전북', '전남', '경북', '경남', '대구', '부산', '울산', '제주'];

export default function useGetBranchRegionList() {
  return useQuery({
    queryKey: [BRANCH_REGION_LIST],
    queryFn: () => getCommonCodeApi<BranchRegion>('region'),
    select: (data) => {
      return data.sort((a, b) => {
        return sortOrder.indexOf(a.codeValue) - sortOrder.indexOf(b.codeValue);
      });
    },
  });
}
