import { z } from 'zod';

export const alarmSearchSchema = z
  .object({
    searchStartDt: z.date().nullable(),
    searchEndDt: z.date().nullable(),
    notificationType: z.enum(['NTTP001', 'NTTP002', 'NTTP003']),
    keywordType: z.enum(['SKT001', 'SKT002', 'SKT003', 'SKT004']).optional(),
    keyword: z.string().optional(),
  })
  .superRefine((value, ctx) => {
    // 시작일 설정 시 종료일은 필수값
    if (value.searchStartDt !== null && value.searchEndDt === null) {
      ctx.addIssue({
        path: ['searchEndDt'],
        code: z.ZodIssueCode.invalid_date,
        message: '종료일을 선택해주세요.',
      });
    }

    // 키워드를 입력했을 경우에는 최소 2글자 이상
    if (value.keyword && 1 <= value.keyword.length && value.keyword.length < 2) {
      ctx.addIssue({
        path: ['keyword'],
        code: z.ZodIssueCode.too_small,
        message: '키워드는 최소 2글자 이상 입력해주세요.',
        minimum: 2,
        inclusive: true,
        type: 'string',
      });
    }

    return true;
  });

export type AlarmSearchSchema = z.infer<typeof alarmSearchSchema>;
