import { css } from '@emotion/react';
import theme from 'theme';

export const scrollbarStyles = css`
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.red};
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.red};
    cursor: pointer;
  }
`;
