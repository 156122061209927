import { useCallback } from 'react';

export default function useBranchScrollTo() {
  const scrollToTop = useCallback(() => {
    const branchListContainer = document.getElementById('branchList-scroll-container');
    if (!branchListContainer) return;
    branchListContainer.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const scorllToTarget = useCallback((branchSeq: number) => {
    const branchListContainer = document.getElementById('branchList-scroll-container');
    if (!branchListContainer) return;
    const branchListItem = branchListContainer.querySelector(`#branch-${branchSeq}`) as HTMLDivElement | null;
    if (!branchListItem) return;

    branchListContainer.scrollTo({ top: branchListItem.offsetTop, behavior: 'smooth' });
  }, []);

  return { scrollToTop, scorllToTarget };
}
