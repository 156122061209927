import { Box, Image, Input } from '@chakra-ui/react';
import { useRef } from 'react';
import { createFile } from '../api/fileApi';
import { useMutation } from '@tanstack/react-query';
import { UploadFileResponseType } from '../types/file.type';

interface FileUploadButtonProps {
  onChange: (res: UploadFileResponseType) => void;
}

// waring을 제거하기 위해 임시로 _ 처리
export default function FileUploadButton({ onChange: _onChange }: FileUploadButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickButton = () => {
    if (inputRef.current && inputRef.current?.click) {
      inputRef.current?.click();
    }
  };
  const handleChangeThumbnail = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    mutateCreateFile(formData);
  };

  // 파일 업로드
  const { mutate: mutateCreateFile } = useMutation({
    mutationKey: ['createFile'],
    mutationFn: createFile,
    onSuccess: (res) => _onChange(res),
  });

  return (
    <Box position={'absolute'} bottom={'8px'} left={'8px'} w={'32px'} h={'32px'} onClick={handleClickButton}>
      <Input display="none" ref={inputRef} type="file" onChange={handleChangeThumbnail} />
      <Image src={'/assets/images/file_upload.png'} />
    </Box>
  );
}
