import { useCallback } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

interface UseCustomPaginationParams {
  totalPages: number;
}

export default function useCustomPagination({ totalPages }: UseCustomPaginationParams) {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));

  const onPreviousPage = useCallback(() => {
    setPage((prev) => {
      if (!prev) return;

      if (prev - 1 < 1) {
        return prev;
      }
      return prev - 1;
    });
  }, [setPage]);

  const onNextPage = useCallback(() => {
    setPage((prev) => {
      if (!prev) return;

      if (prev + 1 > totalPages) {
        return prev;
      }
      return prev + 1;
    });
  }, [setPage, totalPages]);

  const onClickPage = useCallback(
    (page: number) => () => {
      setPage(page);
    },
    [setPage]
  );

  return {
    page,
    onPreviousPage,
    onNextPage,
    onClickPage,
  };
}
