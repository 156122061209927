import { css } from '@emotion/react';
import type { ButtonSize, ButtonType } from './Button';

export const size = {
  28: css`
    height: 28px;
    padding: 0px 8px;
    gap: 8px;
    border-radius: 5px;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
  `,
  36: css`
    height: 36px;
    padding: 0px 16px;
    gap: 8px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
  `,
  40: css`
    height: 40px;
    padding: 0px 20px;
    gap: 8px;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
  `,
  51: css`
    height: 51px;
    padding: 0px 80px;
    border-radius: 100px;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
  `,
  56: css`
    height: 56px;
    padding: 0px 49px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
  `,
};

export const buttonCss = {
  button: (buttonType: ButtonType, capsule: boolean, buttonSize: ButtonSize) => css`
    /* fill size */
    ${size[buttonSize]}
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    ${capsule && 'border-radius: 50px;'}
    white-space: nowrap;

    &:disabled {
      cursor: not-allowed;
    }

    /* fill / outline */
    ${buttonCss.buttonType(buttonType)}
  `,

  buttonType(buttonType: ButtonType) {
    switch (buttonType) {
      case 'fill':
        return css`
          background: var(--red-normal--, #c30d24);
          color: #ffffff;

          &:hover {
            background: #72000e;
          }

          &:disabled {
            background: #9a9898;
            color: #a2a9b0;
          }
        `;
      case 'outline':
        return css`
          border: 2px solid var(--red-normal--, #c30d24);
          background: var(--grey-500, #231f20);
          color: #ffffff;

          &:hover {
            border: 2px solid var(--red-normal--, #c30d24);
            background: var(--red-dark--, #72000e);
          }

          &:disabled {
          }
        `;
      default:
        break;
    }
  },
};
