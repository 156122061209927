import { Flex, Text } from '@chakra-ui/react';

interface Props {
  title: string;
}

const ModalHeader = ({ title }: Props) => {
  return (
    <Flex justifyContent="center" alignItems="center" alignSelf="stretch" bg="#393536" p="6px 16px">
      <Text fontSize={16} fontWeight={700} color="white">
        {title}
      </Text>
    </Flex>
  );
};

export default ModalHeader;
