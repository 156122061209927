export interface BranchManagementColDefsData {
  label: string;
  minWidth: string;
}

export const branchManagementColDefsData: BranchManagementColDefsData[] = [
  { label: 'No', minWidth: '48px' },
  { label: '지역', minWidth: '56px' },
  { label: '지점 이름', minWidth: '184px' },
  { label: '지점장', minWidth: '128px' },
  { label: '설치일', minWidth: '128px' },
  { label: '지점장 전화번호', minWidth: '128px' },
  { label: '주소', minWidth: '352px' },
  { label: '연동 관리', minWidth: '176px' },
];
