import { Flex, Text } from '@chakra-ui/react';
import ModalLayout from 'components/Modals/ModalLayout';
import Button from 'components/Button/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
}

const AlertModal = ({ isOpen, onClose, title, description }: Props) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column" w="498px" h="100%" bg="#393536">
        {/* modal header */}
        <Flex justifyContent="center" alignItems="center" alignSelf="stretch" h="40px" p="6px 16px">
          <Text fontSize="16px" fontWeight="700" color="white">
            알림
          </Text>
        </Flex>

        {/* modal content */}
        <Flex flexDirection="column" alignItems="center" p="16px" gap="24px" color="white" minH="82px">
          <Text fontSize="20px" fontWeight="700" textAlign="center" whiteSpace="pre-wrap">
            {title}
          </Text>

          {description && (
            <Text fontSize="14px" fontWeight="400" textAlign="center" whiteSpace="pre-wrap">
              {description}
            </Text>
          )}
        </Flex>

        {/* modal footer */}
        <Flex justifyContent="center" gap="16px" p="24px 0">
          <Button buttonType="fill" buttonSize={40} onClick={onClose}>
            확 인
          </Button>
        </Flex>
      </Flex>
    </ModalLayout>
  );
};

export default AlertModal;
