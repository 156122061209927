import { Box, Flex, Table, Thead, Tr, Tbody, Td, Th, Image } from '@chakra-ui/react';
import theme, { colors } from '../theme';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import type { Alarm } from 'features/alarm/api/dtos/getNewUrgentAlarm.dto';
import Scrollbar from '../components/Scrollbar/Scrollbar';
import { css } from '@emotion/react';
import { urgentAlarmTableHeaderType } from 'features/alarm/hooks/useNewUrgentAlarm';

interface NotificationProps {
  tableData: Alarm[];
  tableHeader: urgentAlarmTableHeaderType[];
  onClose: () => void;
}

const AlarmModal = ({ tableData, tableHeader, onClose }: NotificationProps) => {
  return (
    <Box position="absolute" top="70px" right="16px" width="670px" height={'fit-content'} bg={colors.grayBlack} boxShadow="lg" borderRadius="16px" zIndex={10} border="2px solid #C30D24" overflow="hidden">
      <Flex justify="space-between" align="center" p={'6px'}>
        <Box w="100%" fontSize="sm" textAlign={'center'} fontWeight="bold" color={'white'}>
          알림
        </Box>
        <Box w="28px" h="28px" cursor={'pointer'} onClick={onClose}>
          <Image src="/assets/images/noti_modal_close_icon.png" alt="noti_modal_close_icon" />
        </Box>
      </Flex>
      <Box width="100%" maxH="300px" paddingBottom={'16px'} backgroundColor={theme.colors.black}>
        <Scrollbar
          css={css`
            overflow: auto;
            height: 100%;
            max-height: 258px;
            background: #231f20;
          `}
        >
          <Table variant="simple" size={'sm'}>
            <Thead bg={colors.grayBlack} position="sticky" top={0} zIndex={1}>
              <Tr>
                {tableHeader.map((title) => (
                  <Th key={title.label} minW={title.minWidth} color="white" textAlign="center">
                    {title.label}
                  </Th>
                ))}
              </Tr>
            </Thead>

            {isEmpty(tableData) && (
              <Tbody bg={colors.gray} color="white">
                <Tr width="100%" h={'218px'} m={0} p={0}>
                  <Td colSpan={4} textAlign="center" verticalAlign="middle" borderBottom={'none'}>
                    새로운 알림이 없습니다.
                  </Td>
                </Tr>
              </Tbody>
            )}

            {!isEmpty(tableData) && (
              <Tbody bg={colors.gray} color="white">
                {tableData.map((item, index) => (
                  <Tr key={index} width="100%" h={'40px'} m={0} p={0}>
                    <Td textAlign="center" verticalAlign="middle">
                      {format(new Date(item.occurDt), 'hh:mm:ss')}
                    </Td>
                    <Td textAlign="center" verticalAlign="middle">
                      {item.branchNm}
                    </Td>
                    <Td textAlign="center" verticalAlign="middle">
                      {item.washMachineNm}
                    </Td>
                    <Td verticalAlign="middle">{item.codeAlarmCodeMsg}</Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </Scrollbar>
      </Box>
    </Box>
  );
};

export default AlarmModal;
