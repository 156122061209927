import { css } from '@emotion/react';

export const imageUploadContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  gap: 8px;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export const imageWrapper = css`
  width: 160px;
  height: 120px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
