import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateBranchApi } from 'features/branchManagement/api/branchManagement';
import { BRANCH_MANAGEMENT_LIST } from './useGetBranchManagementList';
import { produce } from 'immer';
import type { GetBranchManagementListResponseDto } from 'features/branchManagement/api/dtos/getBranchManagementList.dto';

interface UseUploadBranchParams {
  onCloseBranchInformationModal: () => void;
}

export function useUpdateBranch({ onCloseBranchInformationModal }: UseUploadBranchParams) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBranchApi,
    onSuccess: (updatedData) => {
      queryClient.setQueriesData<GetBranchManagementListResponseDto>({ queryKey: [BRANCH_MANAGEMENT_LIST] }, (oldData) =>
        produce(oldData, (draft) => {
          if (!draft) return draft;

          const targetIndex = draft.resData.findIndex((branch) => branch.branchSeq === updatedData.branchSeq);
          if (targetIndex === -1) return draft;

          draft.resData[targetIndex] = {
            ...draft.resData[targetIndex],
            branchNm: updatedData.branchNm,
            branchOwnerNm: updatedData.branchOwnerNm,
            dvcInstallDt: updatedData.dvcInstallDt,
            branchOwnerPhone: updatedData.branchOwnerPhone,
          };
        })
      );

      onCloseBranchInformationModal();
    },
  });
}
