import { useEffect, useState } from 'react';
import { Flex, Text, Input } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import usePlcModelTypeOptions from 'features/branchManagement/hooks/usePlcModelTypeOptions';
import usePlcProviderTypeMap from 'features/branchManagement/hooks/usePlcProviderTypeMap';
import { PlcProviderTypeCode } from 'features/branchManagement/api/dtos/getPlcProviderTypeList.dto';
import { plcModelType } from 'features/branchManagement/hooks/react-query/useGetPlcModelTypeList';
import { washMachineItemSelectCss } from 'features/branchManagement/styles/WashMachineItem.styles';

interface Option {
  label: string;
  value: string;
}

interface Props {
  index: number;
  removeWashMachineCheck: (index: number) => void;
  machineModelTypeOptions: Option[];
  plcProviderTypeOptions: Option[];
}

const WashMachineItem = ({ index, removeWashMachineCheck, machineModelTypeOptions, plcProviderTypeOptions }: Props) => {
  const [plcProvider, setPlcProvider] = useState<PlcProviderTypeCode>('PM0001');

  // PLC 제조사 코드 변환
  const { plcProviderTypeMap } = usePlcProviderTypeMap();
  // PLC 모델명 옵션 조회
  const lowercasePlcProvider = plcProviderTypeMap[plcProvider]?.toLowerCase() as plcModelType;
  const { plcModelTypeOptions } = usePlcModelTypeOptions(lowercasePlcProvider);

  const { control, watch, setValue } = useFormContext();

  const selectedPlcProvider = watch(`branchWashMachineList.${index}.plcPrivider`);

  const plcModelOptions = selectedPlcProvider ? plcModelTypeOptions : [];

  useEffect(() => {
    if (selectedPlcProvider) {
      setPlcProvider(selectedPlcProvider);
    }
  }, [selectedPlcProvider]);

  return (
    <Flex flexDirection="column" borderRadius={10} bg="#393536" w="full" h="272px">
      <Flex justifyContent="center" align="center" alignSelf="stretch" p="18px" borderBottom="2px solid #C30D24" position="relative">
        <Text fontSize="14px" fontWeight={700} color="white">
          세차기 {index + 1}
        </Text>
        <Button type="button" buttonType="fill" buttonSize={40} cssStyle={{ position: 'absolute', top: '8px', right: '8px' }} onClick={() => removeWashMachineCheck(index)}>
          삭 제
        </Button>
      </Flex>

      <Flex flexDirection="column" gap="8px" p="16px">
        <Flex justify="flex-start" align="center">
          <Text fontSize={14} fontWeight={700} w="96px" flexShrink={0} color="white">
            세차기 모델
          </Text>
          <Controller
            control={control}
            name={`branchWashMachineList.${index}.codeModel`}
            render={({ field: { onChange, value } }) => (
              <Select
                options={machineModelTypeOptions}
                placeholder="-- 선택 --"
                onChange={(option) => onChange(option?.value)}
                value={machineModelTypeOptions.find((option) => option.value === value) ?? null}
                cssStyle={[washMachineItemSelectCss.select, washMachineItemSelectCss.indicator]}
              />
            )}
          />
        </Flex>
        <Flex align="center">
          <Text fontSize={14} fontWeight={700} w="96px" flexShrink={0} color="white">
            PLC 제조사
          </Text>
          <Controller
            control={control}
            name={`branchWashMachineList.${index}.plcPrivider`}
            render={({ field: { onChange, value } }) => (
              <Select
                options={plcProviderTypeOptions}
                placeholder="-- 선택 --"
                onChange={(option) => {
                  onChange(option?.value);
                  setValue(`branchWashMachineList.${index}.plcModel`, undefined);
                }}
                value={plcProviderTypeOptions.find((option) => option.value === value) ?? null}
                cssStyle={[washMachineItemSelectCss.select, washMachineItemSelectCss.indicator]}
              />
            )}
          />
        </Flex>
        <Flex align="center">
          <Text fontSize={14} fontWeight={700} w="96px" flexShrink={0} color="white">
            PLC 모델명
          </Text>
          <Controller
            control={control}
            name={`branchWashMachineList.${index}.plcModel`}
            render={({ field: { onChange, value } }) => (
              <Select
                options={plcModelOptions}
                placeholder={selectedPlcProvider ? '-- 선택 --' : '제조사를 먼저 선택해주세요.'}
                onChange={(option) => onChange(option?.value)}
                value={plcModelOptions.find((option) => option.value === value) ?? null}
                cssStyle={[washMachineItemSelectCss.select, washMachineItemSelectCss.indicator]}
              />
            )}
          />
        </Flex>
        <Flex align="center">
          <Text fontSize={14} fontWeight={700} w="96px" flexShrink={0} color="white">
            PLC 버전 정보
          </Text>
          <Controller control={control} name={`branchWashMachineList.${index}.plcVersion`} render={({ field }) => <Input variant="edit" {...field} />} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WashMachineItem;
