// src/storages/authStorage.ts

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

// 액세스토큰과, 리프레시토큰을 로컬스토리지에서 조작하기 위한 로직
const authStorage = {
  getAccessToken() {
    try {
      const rawData = window.localStorage.getItem(ACCESS_TOKEN);
      if (!rawData) return null;

      return JSON.parse(rawData) as string;
    } catch (err) {
      throw new Error('Access Token 불러오기에 실패하였습니다.');
    }
  },

  setAccessToken(accessToken: string) {
    try {
      window.localStorage.setItem(ACCESS_TOKEN, JSON.stringify(accessToken));
    } catch (err) {
      throw new Error('Access Token 저장에 실패하였습니다.');
    }
  },

  getRefreshToken() {
    try {
      const rawData = window.localStorage.getItem(REFRESH_TOKEN);
      if (!rawData) return null;

      return JSON.parse(rawData) as string;
    } catch (err) {
      throw new Error('Refresh Token 불러오기에 실패하였습니다.');
    }
  },

  setRefreshToken(refreshToken: string) {
    try {
      window.localStorage.setItem(REFRESH_TOKEN, JSON.stringify(refreshToken));
    } catch (err) {
      throw new Error('Refresh Token 저장에 실패하였습니다.');
    }
  },

  clear() {
    window.localStorage.removeItem(ACCESS_TOKEN);
    // window.localStorage.removeItem(REFRESH_TOKEN);
  },
};

export default authStorage;
