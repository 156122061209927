import { useInView } from 'react-intersection-observer';
import useGetBranchStatusList from './react-query/useGetBranchStatusList';
import { useEffect, useMemo } from 'react';
import type { BranchStatusListItem } from '../api/dtos/getBranchStatusList.dto';
import type { BranchSearchParams } from '../types/BranchSearch.types';

export default function useInfiniteBranchStatusList({ codeType, codeRegion, searchVal }: BranchSearchParams) {
  // 댓글 무한 스크롤 observer
  const [observerRef, isInView] = useInView();
  // 지점 상태 목록 조회
  const { data, hasNextPage, fetchNextPage, isFetching } = useGetBranchStatusList({
    codeRegion,
    codeType,
    searchVal,
  });

  const branchStatusList = useMemo(() => {
    if (!data) return [];

    return ([] as BranchStatusListItem[]).concat(...data.pages.map((page) => page.resultList));
  }, [data]);

  // 무한 스크롤
  useEffect(() => {
    if (!isInView || !hasNextPage) return;

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isInView]);

  return {
    branchStatusList,
    observerRef,
    hasNextPage,
    isFetching,
  };
}
