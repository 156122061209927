import { useCallback, useMemo } from 'react';
import { useQueryParam, type QueryParamConfig } from 'use-query-params';
import type { BranchTypeOption, BranchTypeOptionValue } from './useBranchTypeOptions';

interface UseBranchDivisionQueryParams {
  branchTypeOptions: BranchTypeOption[];
}

export default function useBranchDivisionQuery({ branchTypeOptions }: UseBranchDivisionQueryParams) {
  const BranchOfficeArrayParam: QueryParamConfig<string | (string | null)[] | null | undefined, BranchTypeOptionValue[]> = useMemo(
    () => ({
      encode: (branchOffices: string | (string | null)[] | null | undefined) => branchOffices,
      decode: (values: string | (string | null)[] | null | undefined) => (values ? (Array.isArray(values) ? values.filter((v) => branchTypeOptions.find((branchType) => branchType.value === v)) : [values]) : ['all']) as BranchTypeOptionValue[],
    }),
    [branchTypeOptions]
  );
  const [codeType, setCodeType] = useQueryParam<BranchTypeOptionValue[]>('codeType', BranchOfficeArrayParam);

  const onSelectBranchDivision = useCallback(
    (selectedBranch: BranchTypeOptionValue) => () => {
      setCodeType((prevCodeType) => {
        if (!prevCodeType) return [];

        const addedBranch = [...prevCodeType, selectedBranch];

        // 'all'이 이미 선택된 경우, 'all'을 제외한 나머지를 선택
        if (prevCodeType.includes('all')) {
          return addedBranch.filter((branch) => branch !== 'all');
        }

        // 선택된 지점이 이미 포함된 경우, 해당 지점을 제거
        if (prevCodeType.includes(selectedBranch)) {
          return prevCodeType.filter((branch) => branch !== selectedBranch);
        }

        // all'이 선택된 경우, 'all'만 선택
        // 모든 지점이 선택된 경우, 'all'만 선택
        if (selectedBranch === 'all' || addedBranch.length === branchTypeOptions.length - 1) {
          return ['all'];
        }

        // 선택된 지점을 추가
        return addedBranch;
      });
    },
    [branchTypeOptions.length, setCodeType]
  );

  return {
    codeType,
    onSelectBranchDivision,
  };
}
