import { Routes, Route, Navigate } from 'react-router-dom';
import IndexPage from './Home';
import { RegisterPage } from './RegisterPage';
import Providers from '../components/Providers';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import DashboardPage from './DashboardPage';
import BranchStatusPage from './BranchStatusPage';
import BranchDetailPage from './BranchDetailPage';
import NotificationLogPage from './NotificationLogPage';
import authStorage from '../storages/authStorage';
import BranchManagementPage from './BranchManagementPage';

// 예: 토큰이 있는지 여부를 확인하는 함수
const isAuthenticated = () => {
  const token = authStorage.getAccessToken();
  return !!token; // 토큰이 있으면 true, 없으면 false 반환
};

const PrivateRoute = ({ element: Element, ...rest }: any) => {
  return isAuthenticated() ? <Element {...rest} /> : <Navigate to="/login" replace />;
};

const App = () => {
  return (
    <Providers>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<PrivateRoute element={DashboardPage} />} />
        <Route path="/home" element={<PrivateRoute element={HomePage} />} />
        <Route path="/branch" element={<PrivateRoute element={BranchStatusPage} />} />
        <Route path="/branch/:id" element={<PrivateRoute element={BranchDetailPage} />} />
        <Route path="/branch/management" element={<PrivateRoute element={BranchManagementPage} />} />
        <Route path="/notification" element={<PrivateRoute element={NotificationLogPage} />} />
        <Route path="/register" element={<RegisterPage />} />
      </Routes>
    </Providers>
  );
};

export default App;
