import AppLayoutContainer from '../containers/AppLayoutContainer';
import NotificationLogContainer from '../features/notificationLog/containers/NotificationLogContainer';

const NotificationLogPage = () => {
  return (
    <AppLayoutContainer>
      <NotificationLogContainer />
    </AppLayoutContainer>
  );
};

export default NotificationLogPage;
