// import { useState } from 'react';
import { Box, Flex, Text, Image, Stack } from '@chakra-ui/react';
import colors from '../../../theme/colors';
import { machineStatusValue } from 'features/dashboard/data/dashboardData';
import theme from 'theme';
import Button from 'components/Button/Button';
import type { WashMachineInfoItem } from '../api/dtos/getWashMachineInfo.dto';
import MachineMonitoringSensorTag from './MachineMonitoringSensorTag';
import type { WashSensorListItem } from '../api/dtos/getWashSensorList.dto';

interface CountDisplayProps {
  label: string;
  count?: number;
}

const CountDisplay = ({ label, count }: CountDisplayProps) => (
    <Flex justifyContent={'space-between'} align={'center'} w={'full'}>
      <Text fontWeight={700} fontSize={'12px'} padding={'4px'} borderLeft={'2px solid #c30d24'}>
        {label}
      </Text>
      <Text fontWeight={400} fontSize={'12px'}>
        {count?.toLocaleString()} 회
      </Text>
    </Flex>
);

interface MachineMonitoringProps {
  data: WashMachineInfoItem;
  activeMachineIndex: number;
  handleClickRefresh: () => void;
  sensors: WashSensorListItem | undefined;
}

const MachineMonitoring = ({ data, activeMachineIndex, handleClickRefresh, sensors }: MachineMonitoringProps) => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  console.log('sensors', sensors);

  // const onClickRefresh = () => {
  //   if (!isLoading) {
  //     setIsLoading(true);
  //     handleClickRefresh();
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 3000);
  //   }
  // };

  const onClickRefresh = () => {
    handleClickRefresh();  // 바로 새로 고침 함수 실행
  };

  return (
      <Stack spacing={'0'} position={'relative'} h={'533px'} minH={'533px'} bg={theme.colors.black} borderRadius={'16px'} color={'white'} overflow={'hidden'}>
        {/* header */}
        <Flex p={'6px 16px'} bg="red.500" h={'40px'} position={'relative'}>
          <Text w="100%" textAlign={'center'} fontSize={'16px'} fontWeight={700}>
            세차기 {activeMachineIndex + 1}
          </Text>
          <Flex
              alignItems={'center'}
              justifyContent={'center'}
              h={'24px'}
              whiteSpace={'nowrap'}
              textAlign={'center'}
              bg={colors.black}
              borderRadius={'100px'}
              color="white"
              p={'5px 13px'}
              gap={'4px'}
              w={'fit-content'}
              position={'absolute'}
              top={'8px'}
              right={'8px'}
          >
            <Box w={'10px'} h={'10px'} bg={machineStatusValue[data?.codeStatus]?.color} borderRadius={'50%'} />
            <Text fontSize={'10px'} fontWeight={700} color={machineStatusValue[data?.codeStatus]?.color}>
              {machineStatusValue[data?.codeStatus]?.text}
            </Text>
          </Flex>
        </Flex>

        {/* body */}
        <Flex direction={'column'} p={'16px'} gap="16px" h={'full'}>
          {/* content */}
          <Flex justifyContent={'space-between'}>
            {/* 세차기 box */}
            <Flex justifyContent={'center'} alignItems={'center'} w={'208px'} h={'72px'} bg={theme.colors.gray} borderRadius={'50px'} fontSize={'14px'} fontWeight={700}>
              {data?.washMachineNm}
            </Flex>

            <Flex gap={'16px'}>
              {/* 오늘 가동 횟수 */}
              <Flex flexDirection={'column'} justifyContent={'space-between'} align={'flex-end'}>
                <Box w={'144px'} p={'2px 16px'} borderRadius={'100px'} bg="red.500" textAlign={'center'} fontSize={'14px'} fontWeight={700}>
                  오늘 가동 횟수
                </Box>
                <Box fontSize={'x-large'} h={'32px'} fontWeight={700}>
                  {data?.todayCnt?.toLocaleString()} 회
                </Box>
              </Flex>

              {/* 어제, 이번 주 */}
              <Flex flexDirection={'column'} justifyContent={'space-between'} align={'center'} minW={'136px'}>
                <CountDisplay label="어제" count={data?.yesterdayCnt} />
                <CountDisplay label="이번 주" count={data?.weeklyCnt} />
              </Flex>

              {/* 이번 달, 누럭 */}
              <Flex flexDirection={'column'} justifyContent={'space-between'} align={'center'} minW={'136px'}>
                <CountDisplay label="이번 달" count={data?.monthlyCnt} />
                <CountDisplay label="누적" count={data?.totalCnt} />
              </Flex>
            </Flex>
          </Flex>

          {/* image content */}
          <Flex position={'relative'} justifyContent={'center'} alignItems={'center'} w={'full'} h={'357px'} p={'8px'} bg={theme.colors.gray} borderRadius={'8px'} overflow={'hidden'}>
            <Flex position={'absolute'} top={'8px'} left={'8px'} flexDirection={'column'}>
              <Box padding={'2px'} fontSize={'12px'} fontWeight={700} textAlign={'center'} borderBottom={'1px solid #fff'}>
                세차기 운행 모드
              </Box>
              <Box padding={'8px'} fontSize={'24px'} fontWeight={700} textAlign={'center'}>
                {data.machineMode === 0 ? '자동' : '수동'}
              </Box>
            </Flex>

            <Box position={'relative'}>
              <MachineMonitoringSensorTag
                  //
                  // label={sensors?.machineLower.kor ?? ''}
                  label="하부"
                  isActive={!!sensors?.machineLower.val}
                  left={'186px'}
                  bottom={'125px'}
              />
              <MachineMonitoringSensorTag
                  //
                  // label={sensors?.stop.kor ?? ''}
                  label="정지"
                  isActive={!!sensors?.stop.val}
                  left={'375px'}
                  bottom={'148px'}
              />
              <MachineMonitoringSensorTag
                  //
                  // label={sensors?.frontOverlimit.kor ?? ''}
                  label="전면초과"
                  isActive={!!sensors?.frontOverlimit.val}
                  left={'429px'}
                  bottom={'180px'}
              />
              <MachineMonitoringSensorTag
                  // label={sensors?.ultrasound.kor ?? ''}
                  label="초음파"
                  isActive={!!sensors?.ultrasound.val}
                  left={'442px'}
                  top={'87px'}
              />
              <MachineMonitoringSensorTag
                  //
                  // label={sensors?.mainBodyOrigin.kor ?? ''}
                  label="본체원점"
                  isActive={!!sensors?.mainBodyOrigin.val}
                  left={'524px'}
                  top={'90px'}
              />
              <MachineMonitoringSensorTag
                  //
                  // label={sensors?.boomOrigin.kor ?? ''}
                  label="스윙암 원점"
                  isActive={!!sensors?.boomOrigin.val}
                  left={'441px'}
                  top={'55px'}
              />

              <img src="/assets/images/modelling2.png" alt="machine image" style={{ width: '659px' }} />
            </Box>

            <Button buttonSize={36} buttonType="fill" leftIcon={<Image src={'/assets/images/refresh.png'} alt="refresh image" w={'28px'} height={'28px'} />} style={{ position: 'absolute', bottom: '8px', left: '8px' }} capsule onClick={onClickRefresh}>
              Refresh
            </Button>
          </Flex>
        </Flex>
      </Stack>
  );
};

export default MachineMonitoring;
