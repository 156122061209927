import { useMemo } from 'react';
import useGetBranchTypeList from './react-query/useGetBranchTypeList';
import { BranchTypeLabel, BranchTypeValue } from '../api/dtos/getBranchTypeList.dto';

type BranchTypeMap = Record<BranchTypeValue, BranchTypeLabel>;

export default function useBranchTypeMap() {
  const { data } = useGetBranchTypeList();

  const  branchTypeMap = useMemo(() => {
    if (!data) return {} as BranchTypeMap;

    return data.reduce((acc, item) => {
      acc[item.code] = item.codeValue;
      return acc;
    }, {} as BranchTypeMap);
  }, [data]);

  return { branchTypeMap };
}
