import { Box, GridItem, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  row: number;
  col?: number;
  label: string;
  children: ReactNode;
}

const BranchInformationItem = ({ row, col, label, children }: Props) => {
  return (
    <GridItem rowSpan={row} colSpan={col} display="flex" alignItems="center" borderLeft="3px solid #C30D24" m="10px 0" w="440px">
      <Text fontSize={14} fontWeight={700} w="92px" p="0 8px" whiteSpace="pre-line" flexShrink={0} color="white">
        {label}
      </Text>
      <Box w="full">{children}</Box>
    </GridItem>
  );
};

export default BranchInformationItem;
