import { css } from '@emotion/react';

export const loadingSpinnerCss = {
  loader: css`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-left: 50%;
    margin-right: 50%;

    padding: 10px;
    border-radius: 10px;

    div {
      width: 6%;
      height: 20%;
      background: #fff;
      position: absolute;
      left: 49%;
      top: 43%;
      opacity: 0;
      border-radius: 50px;
      /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); */
      box-shadow: 0 0 3px rgba(0, 0, 0, 1);
      animation: fade 1s linear infinite;
    }

    @keyframes fade {
      from {
        opacity: 1;
      }
      to {
        opacity: 0.25;
      }
    }

    div:nth-of-type(1) {
      transform: rotate(0deg) translate(0, -130%);
      animation-delay: 0s;
    }

    div:nth-of-type(2) {
      transform: rotate(30deg) translate(0, -130%);
      animation-delay: -0.9167s;
    }

    div:nth-of-type(3) {
      transform: rotate(60deg) translate(0, -130%);
      animation-delay: -0.833s;
    }

    div:nth-of-type(4) {
      transform: rotate(90deg) translate(0, -130%);
      animation-delay: -0.7497s;
    }

    div:nth-of-type(5) {
      transform: rotate(120deg) translate(0, -130%);
      animation-delay: -0.667s;
    }

    div:nth-of-type(6) {
      transform: rotate(150deg) translate(0, -130%);
      animation-delay: -0.5837s;
    }

    div:nth-of-type(7) {
      transform: rotate(180deg) translate(0, -130%);
      animation-delay: -0.5s;
    }

    div:nth-of-type(8) {
      transform: rotate(210deg) translate(0, -130%);
      animation-delay: -0.4167s;
    }

    div:nth-of-type(9) {
      transform: rotate(240deg) translate(0, -130%);
      animation-delay: -0.333s;
    }

    div:nth-of-type(10) {
      transform: rotate(270deg) translate(0, -130%);
      animation-delay: -0.2497s;
    }

    div:nth-of-type(11) {
      transform: rotate(300deg) translate(0, -130%);
      animation-delay: -0.167s;
    }

    div:nth-of-type(12) {
      transform: rotate(330deg) translate(0, -130%);
      animation-delay: -0.0833s;
    }
  `,
};
