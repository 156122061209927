import { css } from '@emotion/react';
import { commonCss } from './styles/common.styles';

export const selectSize = {
  40: css`
    .react-select__control {
      min-height: 40px;
      padding: 0 10px;
      gap: 6px;
      border-radius: 4px;

      .react-select__value-container {
        color: var(--grey-400, #393536);
        font-family: 'Noto Sans KR';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  `,
  48: css`
    .react-select__control {
      min-height: 48px;
      padding: 0 20px;
      gap: 8px;
      border-radius: 12px;

      .react-select__value-container {
        font-size: 16px;
        font-style: normal;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.3px;
        font-weight: 400;
      }
    }
  `,
};

export const selectCss = {
  wrapper: css`
    /* default size */
    ${selectSize[40]}

    /* Nesting을 해야 기본 스타일을 overriding 할 수 있으니 nesting을 제거하지 말 것 */

  // container > 1.control
  // container > 2.menu
  // reactSelectContainerStyle
  .react-select-container {
      --rs-background: #ffffff;
      --rs-border: #dde1e6;
      --rs-placeholder: #21272a;
      --rs-active: #c30d24;
      --rs-active-text: #fff;
      --rs-active-border: #a2a9b0;
      --rs-scroll-thumb: #ccc;
      --rs--disabled-background: #f2f4f8;
      --rs--disabled-color: #a2a9b0;

      &:hover:not(.react-select--is-disabled) {
        .react-select__control {
          border: 1px solid var(--rs-active-border);
        }
      }

      // control > 1.value-container
      // control > 2.indicators
      // 컨트롤 컨테이너 (default => min-height:38px, cursor: default 등등)
      .react-select__control {
        cursor: pointer;
        // padding-top, padding-bottom을 주게되면 placeholder를 사용할 때 문제가 발생
        // 따라서 위아래 패딩 값은 절대로 주어서는 안되고, 양옆으로만 가능
        min-width: 0;
        background: var(--rs-background);

        padding-right: 0px;

        // value-container > 1.placeholder
        // value-container > 2.single-value
        .react-select__value-container {
          color: #21272a;

          .react-select__placeholder {
            color: #a2a9b0;
          }

          // 텍스트 스타일 설정
          .react-select__single-value {
          }
        }

        // indicators > indicator-separator, dropdown-indicator
        .react-select__indicators {
          width: 40px;

          display: flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #393536;
          // 구분선: 설정할 일 거의 없으므로 diplay:none
          .react-select__indicator-separator {
            display: none;
          }

          // clear 인디케이터 (X표시)
          .react-select__clear-indicator {
          }
          // 드롭다운 스타일 설정
          // .react-select__dropdown-indicator {
          // }
        }
      }

      // 메뉴가 열렸을 때, 컨테이너(컨트롤) 스타일
      .react-select__control--menu-is-open {
        border: 1px solid var(--rs-active-border);
      }
    }

    // 선택 불가일 때 스타일
    .react-select--is-disabled {
      .react-select__control--is-disabled {
        background: var(--rs--disabled-background);
        pointer-events: auto;
        cursor: not-allowed;

        // value-container > 1.placeholder
        // value-container > 2.single-value
        .react-select__value-container {
          color: var(--Cool-Gray-300, #a2a9b0);
        }

        // indicators > indicator-separator, dropdown-indicator
        .react-select__indicators {
          /* 드롭다운 스타일 설정 */
          .react-select__dropdown-indicator {
          }
        }
      }
    }

    // reactSelectMenuStyle
    .react-select-container {
      // 메뉴 리스트 컨테이너 > 메뉴 리스트 > 메뉴 리스트 아이템
      // 메뉴 리스트 컨테이너(default => position:absolute, top:100%, width:100%)
      .react-select__menu {
        top: calc(100% + 8px);

        // 메뉴 리스트(default => position:relative, overflow-y:auto, max-height: 300px)
        .react-select__menu-list {
          background: var(--rs-background);
          border-radius: 4px;

          display: flex;
          flex-direction: column;
          gap: 6px;
          padding: 4px;

          max-height: 360px;
          overflow: auto;
          ${commonCss.scrollbar}
        }

        // 메뉴 리스트 아이템
        .react-select__option {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 14px;
          height: 34px;
          border-radius: 8px;
          font-size: 14px;
          font-style: normal;
          line-height: 18px; /* 128.571% */
          letter-spacing: -0.2px;
          font-weight: 400;
        }

        // 선택된 리스트 아이템
        .react-select__option--is-focused,
        .react-select__option--is-selected {
          background: var(--rs-active);
          border-radius: 0;
          color: var(--rs-active-text);
        }

        .react-select__option--is-selected {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
        }

        // 선택불가로 설정된 리스트 아이템
        .react-select__option--is-disabled {
          cursor: not-allowed;
          background: var(--rs--disabled-background);
          color: var(--rs--disabled-color);
        }
      }
    }
  `,
};
