import { googleMapState, selectedBranchSeqState, selectedMarkerKeyState } from 'atoms/googleMap';
import { MAP_DEFAULT_CENTER } from 'common/constants';
import type { Location } from 'features/branchMap/types';
import { smoothZoom } from 'features/branchMap/utils/smoothZoom';
import useBranchScrollTo from 'features/branchSearch/hooks/useBranchScrollTo';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export interface HandleBranchClickParams extends Location {
  branchSeq: number;
  isScroll: boolean;
}

export default function useSelectBranch() {
  const map = useRecoilValue(googleMapState);
  const [selectedMarkerKey, setSelectedMarkerKey] = useRecoilState(selectedMarkerKeyState);
  const setSelectedBranchSeq = useSetRecoilState(selectedBranchSeqState);
  const { scrollToTop, scorllToTarget } = useBranchScrollTo();

  const handleInfoWindowClose = useCallback(() => {
    if (!map) return;

    // 팝업창 닫았을 때, default 상태로 복구
    setSelectedBranchSeq(null);
    setSelectedMarkerKey(null);
    smoothZoom({ map, targetZoom: 7, currentZoom: map.getZoom() ?? 0 });
    map.panTo(MAP_DEFAULT_CENTER);

    // 지점 리스트 스크롤 상단으로 위치
    scrollToTop();
  }, [map, scrollToTop, setSelectedBranchSeq, setSelectedMarkerKey]);

  const handleBranchClick = useCallback(
    ({ branchSeq, isScroll, ...location }: HandleBranchClickParams) => {
      if (!map) return;

      if (selectedMarkerKey === location.name) {
        setSelectedMarkerKey(null);
        return;
      }

      setSelectedBranchSeq(branchSeq);
      setSelectedMarkerKey(location.name);

      // 마커 클릭 시 지점 리스트에서 해당 지점으로 스크롤
      if (isScroll) {
        scorllToTarget(branchSeq);
      }

      map.panTo({ lat: location.position.lat - 0.004, lng: location.position.lng });
      smoothZoom({ map, targetZoom: 16, currentZoom: map.getZoom() ?? 0 });
    },
    [map, scorllToTarget, selectedMarkerKey, setSelectedBranchSeq, setSelectedMarkerKey]
  );

  return { selectedMarkerKey, handleInfoWindowClose, handleBranchClick };
}
