import { api } from 'api/api';
import type { BaseResponse } from 'api/api.types';
import { GetWashMachineListResponseDto } from './dtos/getWashMachineList.dto';
import { UpdateWashMachineConnectDto } from './dtos/updateWashMachineConnect.dto';
import { BranchWashMachineListResponseDto } from './dtos/getBranchWashMachineList.dto';
import { UpdateBranchWashMachineDto } from './dtos/updateBranchWashMachineList.dto';
import { CreateBranchWashMachineDto } from './dtos/createBranchWashMachineList.dto';
import { isAxiosError } from 'axios';

// 세차기 연동 관리 목록 조회
export async function getWashMachineListApi(branchSeq: number) {
  const { data } = await api.get<BaseResponse<GetWashMachineListResponseDto>>(`/branch/getBranchs/connectInfo/${branchSeq}`);
  return data.data;
}

// 세차기 연동
export async function updateWashMachineConnectApi(WashMachineList: UpdateWashMachineConnectDto[]) {
  const { data } = await api.put<BaseResponse<UpdateWashMachineConnectDto[]>>('/mac/connectInfo', WashMachineList);
  return data;
}

// 지점별 세차기 목록 조회(세차기 최대 4개)
export async function getBranchWashMachineListApi(branchSeq: number) {
  try {
    const { data } = await api.get<BaseResponse<BranchWashMachineListResponseDto>>(`/mac/GetBranchWashMachineInfo/${branchSeq}`);
    return data.data.branchWashMachineInfoList;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      return [];
    }
  }
}

// 지점별 세차기 목록 등록(세차기 최대 4개 - 초과 등록 시도 시 8311 에러 발생)
export async function createBranchWashMachineListApi(body: CreateBranchWashMachineDto[]) {
  const { data } = await api.post<BaseResponse<void>>('/mac/InsWash', body);
  return data;
}

// 지점별 세차기 목록 수정(세차기 최대 4개)
export async function updateBrachWashMachineListApi(body: UpdateBranchWashMachineDto[]) {
  const { data } = await api.post<BaseResponse<void>>('/mac/ModifyWashMachineInfo/', body);
  return data;
}

// 세차기 삭제
export async function deleteBranchWashMachineApi(washMachineKey: string) {
  const { data } = await api.put<BaseResponse<void>>(`/mac/DelWashMachine/${washMachineKey}`, { washMachineKey });
  return data;
}
