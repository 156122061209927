import { useQuery } from '@tanstack/react-query';
import type { MachineType } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import { getCommonCodeApi } from 'features/common/api/common';

const MACHINE_TYPE_LIST = 'MACHINE_TYPE_LIST';

export default function useGetMachineTypeList() {
  return useQuery({
    queryKey: [MACHINE_TYPE_LIST],
    queryFn: () => getCommonCodeApi<MachineType>('machine_type'),
  });
}
