import { useQuery } from '@tanstack/react-query';
import { getCommonCodeApi } from 'features/common/api/common';
import type { AlarmType } from 'features/notificationLog/api/dtos/getAlarmTypeList.dto';

const ALARM_TYPE_LIST = 'ALARM_TYPE_LIST';

export default function useGetAlarmTypeList() {
  return useQuery({
    queryKey: [ALARM_TYPE_LIST],
    queryFn: () => getCommonCodeApi<AlarmType>('noti_type'),
  });
}
