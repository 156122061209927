/** @jsxImportSource @emotion/react */

import { ChangeEvent, useRef } from 'react';
import Button from 'components/Button/Button';
import { UploadFileItem } from 'api/dtos/uploadFile.dto';
import { imageUploadContainer, imageWrapper } from './ImageUploadButton.styles';
import { STORAGE_URL } from 'common/constants';

interface Props {
  uploadFileData?: UploadFileItem;
  onImageUpload: (file: File) => void;
  initialImage?: string; // 초기화된 이미지 URL
}

const ImageUploadButton = ({ uploadFileData, onImageUpload, initialImage = '/assets/images/none_image.png' }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      onImageUpload(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const imageUrl = uploadFileData ? `${STORAGE_URL}`+uploadFileData.fileDomain + uploadFileData.filePath : initialImage;

  return (
    <div css={imageUploadContainer}>
      <div css={imageWrapper}>
        <img src={imageUrl} alt="upload image" />
      </div>

      <Button type="button" buttonType="fill" buttonSize={40} cssStyle={{ width: '100%', borderRadius: '5px' }} onClick={handleButtonClick}>
        사진등록
      </Button>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} />
    </div>
  );
};

export default ImageUploadButton;
