import { useInView } from 'react-intersection-observer';
import useGetBranchStatusDetailList from './react-query/useGetBranchStatusDetailList';
import { useEffect, useMemo } from 'react';
import type { BranchStatusDetailListItem } from '../api/dtos/getBranchStatusDetailList.dto';
import type { BranchSearchParams } from '../types/BranchSearch.types';
import useTranslateBranchStatus from './useTranslateBranchStatus';
import { Box, Flex, Text } from '@chakra-ui/react';
import useBranchRegionMap from './useBranchRegionMap';

export interface TransformedBranch {
  brancSeq: number;
  codeRegion: string;
  branchNm: string;
  [key: string]: React.ReactNode;
}

export default function useInfiniteBranchStatusDetailList({ codeType, codeRegion, searchVal }: BranchSearchParams) {
  // 댓글 무한 스크롤 observer
  const [observerRef, isInView] = useInView();
  // 지점 상태 상세 목록 조회
  const { data, hasNextPage, fetchNextPage, isFetching } = useGetBranchStatusDetailList({
    codeRegion,
    codeType,
    searchVal,
  });

  const branchStatusDetailList = useMemo(() => {
    if (!data) return [];

    return ([] as BranchStatusDetailListItem[]).concat(...data.pages.map((page) => page.resData));
  }, [data]);

  const { branchRegionMap } = useBranchRegionMap();
  const { translateBranchStatusColor, translateBranchStatusLabel } = useTranslateBranchStatus();

  const transformedBranchStatusDetailList = useMemo(() => {
    return branchStatusDetailList.map((branch) => {
      const { brancSeq, branchNm, codeRegion, washMachines } = branch;
      const transformedBranch: TransformedBranch = { brancSeq, codeRegion: branchRegionMap[codeRegion], branchNm };

      // washMachines 배열의 길이가 4보다 작으면 부족한 세차기 항목을 ""로 채우기
      const fullWashMachines = [...Array(4)].map((_, i) => {
        const machine = washMachines[i] || null;

        return machine ? (
          <Flex key={i} alignItems={'center'} color="white" gap="4px" justifyContent={'center'}>
            <Box w="10px" h="10px" bg={translateBranchStatusColor(machine.washMachineStatus)} borderRadius={'50%'} />
            <Text fontSize={'10px'} fontWeight={700} lineHeight={'14.48px'} whiteSpace={'nowrap'}>
              {translateBranchStatusLabel(machine.washMachineStatus)}
            </Text>
          </Flex>
        ) : (
          <div />
        );
      });

      const machineNames = ['세차기1', '세차기2', '세차기3', '세차기4'];
      machineNames.forEach((name, index) => {
        transformedBranch[name] = fullWashMachines[index];
      });

      return transformedBranch;
    });
  }, [branchRegionMap, branchStatusDetailList, translateBranchStatusColor, translateBranchStatusLabel]);

  // 무한 스크롤
  useEffect(() => {
    if (!isInView || !hasNextPage) return;

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isInView]);

  return {
    transformedBranchStatusDetailList,
    observerRef,
    hasNextPage,
    isFetching,
  };
}
