import { Flex, Button } from '@chakra-ui/react';
import { colors } from 'theme';
import type { BranchRegionOption, BranchRegionOptionValue } from '../hooks/useBranchRegionOptions';

interface BranchRegionsProps {
  branchRegionOptions: BranchRegionOption[];
  codeRegion: BranchRegionOptionValue[];
  onSelectBranchRegion: (selectedRegion: BranchRegionOptionValue) => () => void;
  style?: React.CSSProperties;
}

const BranchRegions = ({ branchRegionOptions, codeRegion, onSelectBranchRegion, style }: BranchRegionsProps) => {
  return (
    <Flex w="100%" h="fit-content" style={style} flexWrap={'wrap'}>
      {branchRegionOptions.map(({ label, value }) => (
        <Button
          w="62px"
          h="36px"
          key={value}
          p={2}
          borderRadius="md"
          textAlign="center"
          fontSize={'14px'}
          fontWeight={codeRegion.includes(value) ? '700' : '400'}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          bg={codeRegion.includes(value) ? 'red.500' : colors.grayBlack}
          _hover={{ bg: colors.gray }}
          color={'white'}
          onClick={onSelectBranchRegion(value)}
        >
          {label}
        </Button>
      ))}
    </Flex>
  );
};

export default BranchRegions;
