import { queryOptions, useQuery } from '@tanstack/react-query';
import authStorage from 'storages/authStorage';
import { getMeApi } from '../api/getMe';

const USER_ME = 'USER_ME';

export const getMeQueryOptions = queryOptions({
  queryKey: [USER_ME],
  queryFn: () => {
    const accessToken = authStorage.getAccessToken();
    if (!accessToken) throw null;
    return getMeApi();
  },
  staleTime: 5 * 60 * 1000,
});

export default function useGetMe() {
  return useQuery(getMeQueryOptions);
}
