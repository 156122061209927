import { SelectOption } from 'components/Pagination/hooks/usePagination';
import { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { branchSearchOptions } from '../data/BranchSearchOptions.data';

export default function useSearchBranchManagement() {
  const [searchType, setSearchType] = useState<SelectOption | null>(null);
  const [searchVal, setSearchVal] = useQueryParam<string>('searchVal', withDefault(StringParam, ''));

  const [keyword, setKeyword] = useState('');
  const [keywordType, setKeywordType] = useQueryParam('keywordType', StringParam);

  // 초기화
  useEffect(() => {
    setKeyword(searchVal);
    setSearchType(branchSearchOptions.find((option) => option.value === keywordType) ?? branchSearchOptions[0]);
  }, [keywordType, searchVal]);

  const onChangeSearchType = useCallback((option: SelectOption | null) => {
    setSearchType(option);
  }, []);

  const onChangeKeyword = useCallback((value: string) => {
    setKeyword(value);
  }, []);

  const onSearch = useCallback(() => {
    if (searchType) {
      setKeywordType(searchType.value);
    }

    if (keyword) {
      setSearchVal(keyword);
    }
  }, [keyword, searchType, setKeywordType, setSearchVal]);

  return {
    searchType,
    onChangeSearchType,
    keyword,
    onChangeKeyword,
    onSearch,
    keywordType,
    searchVal,
  };
}
