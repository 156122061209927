import { Flex, Text } from '@chakra-ui/react';

interface Props {
  title: string;
}

const WashMachineLinkHeader = ({ title }: Props) => {
  return (
    <Flex alignItems="center" gap="8px" p="8px 0px" borderBottom="4px solid #C30D24" w="full">
      <img src="/assets/images/title_logo.png" alt="title logo" width="40px" height="40px" />
      <Text fontSize={16} fontWeight={700} color="white">
        {title}
      </Text>
    </Flex>
  );
};

export default WashMachineLinkHeader;
