import { useQuery } from '@tanstack/react-query';
import { getBranchDetailForMainPopUp } from 'features/branchMap/api/branchMap';

const BRANCH_DETAIL_FOR_MAIN_POP_UP = 'BRANCH_DETAIL_FOR_MAIN_POP_UP';

export default function useGetBranchDetailForMainPopUp(branchSeq: number) {
  return useQuery({
    queryKey: [BRANCH_DETAIL_FOR_MAIN_POP_UP, { branchSeq }],
    queryFn: () => getBranchDetailForMainPopUp(branchSeq),
    enabled: !!branchSeq,
  });
}
