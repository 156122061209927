import LoginForm from '../components/LoginForm/LoginForm';
import { useLogin } from '../hooks/react-query/useLogin';

import _ from 'lodash';
import { LoginFormValues } from '../types';
import { useState } from 'react';
import AlertModal from 'components/Modals/AlertModal';

const LoginContainer = () => {
  const [errorResCode, setErrorResCode] = useState<number | null>(null);
  const { mutate: login } = useLogin({ setErrorResCode });

  const handleLoginSubmit = (data: LoginFormValues) => {
    login({ userId: data.userId, userPw: data.userPassword });
  };

  const onCloseModal = () => {
    setErrorResCode(null);
  };

  const getModalContent = (code: number | null) => {
    switch (code) {
      case 8101:
        return { title: '사용자 ID 정보가 유효하지 않습니다.', description: '사용자 ID를 확인하고 다시 시도하세요.' };
      case 8102:
        return { title: '비밀번호 정보가 유효하지 않습니다.', description: '입력된 비밀번호가 올바르지 않습니다.\n다시 시도하세요.' };
      default:
        return { title: '', description: '' };
    }
  };

  const { title, description } = getModalContent(errorResCode);

  return (
    <>
      <LoginForm onSubmit={handleLoginSubmit} />

      <AlertModal isOpen={!_.isEmpty(title)} onClose={onCloseModal} title={title} description={description} />
    </>
  );
};

export default LoginContainer;
