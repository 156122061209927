import type { UserInfo } from 'features/userProfile/api/dtos/getMe.dto';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload extends UserInfo {
  sub: string;
  iat: number;
  exp: number;
}

export function getExpirationDate(token: string): Date | null {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    if (decoded.exp === undefined) {
      return null;
    }

    // `exp` 값은 초 단위로 되어 있으므로, 밀리초 단위로 변환
    const expirationDate = new Date(decoded.exp * 1000);
    return expirationDate;
  } catch (error) {
    console.error('Failed to decode JWT token:', error);
    return null;
  }
}
