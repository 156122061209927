import { api } from 'api/api';
import type { BaseResponse } from 'api/api.types';
import type { CommonCodeCategory, GetCommonCodeCategoryResponseDto } from './dtos/getCommonCodeCategory.dto';
import type { GetCommonCodeResponseDto } from './dtos/getCommonCode.dto';

export async function getCommonCodeCategoryApi() {
  const { data } = await api.get<BaseResponse<GetCommonCodeCategoryResponseDto>>('/common/code/category');
  return data.data.codeCategoryList;
}

export async function getCommonCodeApi<TData>(category: CommonCodeCategory) {
  const { data } = await api.get<BaseResponse<GetCommonCodeResponseDto<TData>>>(`/common/code/${category}`);
  return data.data.commonCodeList;
}
