import CustomDatePicker from 'components/DatePicker/DatePicker';
import Select from 'components/Select/Select';
import { Box, Flex, Text } from '@chakra-ui/react';
import BranchSearchForm from '../../branchSearch/components/BranchSearchForm';
import type { AlarmTypeOption } from '../hooks/useGetAlarmTypeOptions';
import type { AlarmSearchTypeOption } from '../hooks/useGetAlarmSearchTypeOptions';
import { Controller, type SubmitHandler, type UseFormReturn } from 'react-hook-form';
import type { AlarmSearchSchema } from '../schema/alarmSearch.schema';

interface NotificationFormProps {
  alarmTypeOptions: AlarmTypeOption[];
  alarmSearchTypeOptions: AlarmSearchTypeOption[];
  control: UseFormReturn<AlarmSearchSchema>['control'];
  handleSubmit: UseFormReturn<AlarmSearchSchema>['handleSubmit'];
  onSubmit: SubmitHandler<AlarmSearchSchema>;
}

function NotificationLogForm({ alarmTypeOptions, alarmSearchTypeOptions, control, handleSubmit, onSubmit }: NotificationFormProps) {
  return (
    <Box mt={6}>
      <Flex gap="40px">
        <Flex alignItems={'center'}>
          <Flex alignItems={'center'} w={'430px'} gap="16px">
            <Text fontWeight={700} color={'white'} whiteSpace={'nowrap'} fontSize={'14px'}>
              검색 기간
            </Text>
            <Controller
              control={control}
              name="searchStartDt"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  //
                  modalName="시작일"
                  placeholder="---시작일---"
                  selectedDate={value}
                  onChangeDate={onChange}
                />
              )}
            />
            <Text color="white" fontWeight={700}>
              ~
            </Text>
            <Controller
              control={control}
              name="searchEndDt"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  //
                  modalName="종료일"
                  placeholder="---종료일---"
                  selectedDate={value}
                  onChangeDate={onChange}
                />
              )}
            />
          </Flex>
        </Flex>

        <Flex gap={'16px'} alignItems={'center'} flex={1}>
          <Flex alignItems={'center'} gap={'16px'}>
            <Text fontWeight={700} color={'white'} fontSize={'14px'}>
              유형
            </Text>
            <Controller
              control={control}
              name="notificationType"
              render={({ field: { onChange, value } }) => (
                <Select
                  //
                  options={alarmTypeOptions}
                  cssStyle={{ width: '150px' }}
                  onChange={(option) => onChange(option?.value)}
                  value={alarmTypeOptions.find((option) => option.value === value) ?? alarmTypeOptions[0]}
                />
              )}
            />
          </Flex>
          <Flex alignItems={'center'} gap={'16px'}>
            <Text fontWeight={700} color={'white'} fontSize={'14px'}>
              검색 내용
            </Text>
            <Controller
              control={control}
              name="keywordType"
              render={({ field: { onChange, value } }) => (
                <Select
                  //
                  options={alarmSearchTypeOptions}
                  cssStyle={{ width: '150px' }}
                  onChange={(option) => onChange(option?.value)}
                  value={alarmSearchTypeOptions.find((option) => option.value === value) ?? alarmSearchTypeOptions[0]}
                />
              )}
            />
          </Flex>
          <Flex flex="1">
            <Controller
              control={control}
              name="keyword"
              render={({ field: { onChange, value } }) => (
                <BranchSearchForm
                  //
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="검색할 내용을 입력해주세요."
                  onClickSearch={handleSubmit(onSubmit)}
                />
              )}
            />
          </Flex>
        </Flex>
      </Flex>
      <Text color="white" fontSize={'10px'} fontWeight={400} marginTop={'2px'} marginLeft={'110px'}>
        * 검색 시작일과 종료일을 지정하지 않을 시, <strong style={{ fontWeight: 700 }}>최근 7일의 내역</strong>으로 검색 됩니다.
      </Text>
    </Box>
  );
}

export default NotificationLogForm;
