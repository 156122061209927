import { DashBoardHeaderType } from '../types';

export const dashboardHeaderData: DashBoardHeaderType[] = [
  { regionName: '수도권', regionCode: 'BMRG1001' },
  { regionName: '충청/전라/강원', regionCode: 'BMRG1002' },
  { regionName: '경상/제주', regionCode: 'BMRG1003' },
];

export const regionNameValues: any = {
  KR00011: '서울',
  KR00021: '부산',
  KR00022: '대구',
  KR00023: '인천',
  KR00024: '광주',
  KR00025: '대전',
  KR00026: '울산',
  KR00029: '세종',
  KR00031: '경기',
  KR00032: '강원',
  KR00033: '충북',
  KR00034: '충남',
  KR00035: '전북',
  KR00036: '전남',
  KR00037: '경북',
  KR00038: '경남',
  KR00039: '제주',
};

export const machineStatusValue: any = {
  W001: { text: '운영중', color: '#00FF00' },
  W002: { text: '대기중', color: '#FFB800' },
  W003: { text: '확인필요', color: '#FF0000' },
  W004: { text: '연결끊김', color: '#D1D1D1' },
  W005: { text: '등록필요', color: '#D1D1D1 ' },
};

interface TableHeader {
  label: string;
  minWidth: string;
}

export const dashboardTableHeaders: TableHeader[] = [
  { label: 'No', minWidth: '50px' },
  { label: '지역', minWidth: '70px' },
  { label: '지점 이름', minWidth: '120px' },
  { label: '세차기1', minWidth: '80px' },
  { label: '세차기2', minWidth: '80px' },
  { label: '세차기3', minWidth: '80px' },
  { label: '세차기4', minWidth: '80px' },
];
