import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteBranchWashMachineApi } from 'features/branchManagement/api/washMachine';
import { BRANCH_WASH_MACHINE_LIST } from './useGetBranchWashMachineList';

export function useDeleteBranchWashMachine() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBranchWashMachineApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BRANCH_WASH_MACHINE_LIST] });
    },
  });
}
