import { useQuery } from '@tanstack/react-query';
import { getBranchManagementInformationApi } from 'features/branchManagement/api/branchManagement';

const BRANCH_MANAGEMENT_INFORMATION = 'BRANCH_MANAGEMENT_INFORMATION';

export default function useGetBranchManagementInformation(branchSeq: number) {
  return useQuery({
    queryKey: [BRANCH_MANAGEMENT_INFORMATION, branchSeq],
    queryFn: () => getBranchManagementInformationApi(branchSeq),
    enabled: !!branchSeq,
  });
}
