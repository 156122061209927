import { MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import { MAchineModelTypeLabel, MachineModelTypeCode } from '../api/dtos/getMachineModelTypeList.dto';
import useGetMachineModelTypeList from './react-query/useGetMachineModelTypeList';
import { useMemo } from 'react';

export interface MachineModelTypeOption {
  label: MAchineModelTypeLabel;
  value: MachineModelTypeCode;
}

export default function useMachineModelTypeOptions(modelType: MachineTypeValue) {
  const { data } = useGetMachineModelTypeList(modelType);

  const machineModelTypeOptions = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }));
  }, [data]);

  return { machineModelTypeOptions };
}
