/** @jsxImportSource @emotion/react */

import DatePicker, { CalendarContainer, ReactDatePickerCustomHeaderProps, registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { datepickerCss, headerCss, InputCss, containerCss } from './DatePicker.styles';
import { ko } from 'date-fns/locale';
import { forwardRef } from '@chakra-ui/react';

registerLocale('ko', ko);
setDefaultLocale('ko');

interface DatePickerProps {
  modalName: string;
  placeholder?: string;
  disabled?: boolean;
  selectedDate: Date | null;
  customStyles?: any;
  onChangeDate: (date: Date | null) => void;
}

/* datepicker input */
const CustomInput = forwardRef(({ onClick, disabled, selectedDate, placeholder }) => (
  <div css={[InputCss.inputWrapper, disabled && { cursor: 'not-allowed' }]} onClick={!disabled ? onClick : undefined}>
    <input type="text" value={selectedDate ? selectedDate.toLocaleDateString() : ''} readOnly css={InputCss.inputStyle} placeholder={placeholder} disabled={disabled} />
    <div css={InputCss.imageWrapper}>
      <img src={'/assets/images/calendar_icon.png'} alt="Calendar Icon" css={InputCss.imageStyle} />
    </div>
  </div>
));

/* datepicker header */
const renderCustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }: ReactDatePickerCustomHeaderProps) => (
  <div css={headerCss}>
    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      <img src="/assets/icons/arrowLeft.png" alt="left arrow icon" />
    </button>

    <span>
      {date.getFullYear()}. {(date.getMonth() + 1).toString().padStart(2, '0')}월
    </span>

    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      <img src="/assets/icons/arrowRight.png" alt="right arrow icon" />
    </button>
  </div>
);

/* datepicker container */
const CustomContainer = forwardRef(({ modalName, children }, ref) => (
  <div css={containerCss.datepickerContainer} ref={ref}>
    <CalendarContainer>
      <p css={containerCss.datepickerTitle}>{modalName}</p>
      <div>{children}</div>
    </CalendarContainer>
  </div>
));

const CustomDatePicker = ({ modalName, placeholder, disabled, selectedDate, customStyles, onChangeDate }: DatePickerProps) => {
  const handleChangeDate = (date: Date | null) => {
    onChangeDate(date);
  };

  return (
    <div css={[datepickerCss, customStyles]}>
      <DatePicker
        dateFormat="yyyy.MM.dd"
        selected={selectedDate}
        onChange={handleChangeDate}
        customInput={<CustomInput selectedDate={selectedDate} disabled={disabled} placeholder={placeholder} />}
        renderCustomHeader={renderCustomHeader}
        calendarContainer={(props) => <CustomContainer {...props} modalName={modalName} />}
        popperPlacement="bottom-end"
      />
    </div>
  );
};

export default CustomDatePicker;
