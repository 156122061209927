import { Box, Flex, Image, List, ListItem, Stack, Text } from '@chakra-ui/react';
import { colors } from 'theme';
import WashMachineStatus from './WashMachineStatus';
import useGetBranchDetailForMainPopUp from '../hooks/react-query/useGetBranchDetailForMainPopUp';
import { STORAGE_URL } from 'common/constants';
import { useNavigate } from 'react-router-dom';

interface MapMarkerPopUpProps {
  branchSeq: number;
  onClose: () => void;
}

export default function MapMarkerPopUp({ branchSeq, onClose }: MapMarkerPopUpProps) {
  const { data: branchDetail } = useGetBranchDetailForMainPopUp(branchSeq);
  
  const navigate = useNavigate();

  function ToDetail(){
    navigate('/branch/'+branchSeq);
  }
  return (
    <Box position="absolute" top="50px" left={-188} width={400} minH={382} borderRadius="2xl" border={`3px solid ${colors.darkRed}`} bg="#3a3536" overflow="hidden" onClick={(e) => e.stopPropagation()}>
      {branchDetail && (
        <>
          {/* Header */}
          <Flex fontWeight="bold" color="white" bg={colors.grayBlack} justify="center" p="6px" pos="relative" fontSize={16} textAlign={'center'} align="center" height="40px">
            {branchDetail.branchNm}
            <Box
              pos="absolute"
              top="6px"
              right="6px"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <Image src={'/assets/images/noti_modal_close_icon.png'} width="28px" height="28px" />
            </Box>
          </Flex>

          {/* Content */}
          <Stack padding={4} spacing="4">
            <Flex justify="space-between" onClick={ToDetail}>
              <Box color={colors.white} flex="1" pl="4" fontSize="12" lineHeight={7}>
                <List listStyleType="initial">
                  <ListItem fontWeight="bold">
                    <Text fontSize={'12px'} fontWeight={'700'}>
                      {branchDetail.branchAddr}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Flex>
                      <Text fontSize={'12px'} fontWeight={'700'} w={91}>
                        지점장
                      </Text>{' '}
                      <Text fontSize={'12px'} fontWeight={'400'}>
                        {branchDetail.branchOwnerNm}
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex>
                      <Text fontSize={'12px'} fontWeight={'700'} w={91}>
                        지점장 전화번호
                      </Text>{' '}
                      <Text fontSize={'12px'} fontWeight={'400'}>
                        {branchDetail.branchOwnerPhone}
                      </Text>
                    </Flex>
                  </ListItem>
                </List>
              </Box>
              <Flex width="135px" height="90px" justify="center" objectFit={'contain'} objectPosition={'center'}>
                {/*<Image src={branchDetail.branchImgPath ? `${STORAGE_URL}${branchDetail.branchImgPath}` : '/assets/images/default_thumbnail_img.png'} height="100%" />*/}
                <Image src={branchDetail.branchImgPath ? `${STORAGE_URL}${branchDetail.branchImgPath}` : '/assets/images/logo-img-red.png'} height="100%" />
              </Flex>
            </Flex>

            <Box>
              <Flex h="8" justify="center" align="center" fontSize="14" color={colors.white} fontWeight={700} borderBottom={`2px solid ${colors.red}`}>
                금일 가동 횟수
              </Flex>
            </Box>

            <Flex justify="center">
              <Flex w="328px" columnGap={6} rowGap={2} flexWrap={'wrap'} align="center" justify="flex-start" justifySelf="center">
                {branchDetail.washMachines.map((washMachine, i) => (
                  <WashMachineStatus
                    //
                    key={i}
                    name={`세차기 ${i + 1}`}
                    status={washMachine.washMachineStatus}
                    operatingFrequency={washMachine.todayCnt}
                  />
                ))}
              </Flex>
            </Flex>
          </Stack>
        </>
      )}
    </Box>
  );
}
