import { useQuery } from '@tanstack/react-query';
import { PlcModelType } from 'features/branchManagement/api/dtos/getPlcModelTypeList.dto';
import { getCommonCodeApi } from 'features/common/api/common';

export type plcModelType = 'simense' | 'ls';

const PLC_MODEL_TYPE_LIST = 'PLC_MODEL_TYPE_LIST';

export default function useGetPlcModelTypeList(modelType: plcModelType) {
  return useQuery({
    queryKey: [PLC_MODEL_TYPE_LIST, modelType],
    queryFn: () => getCommonCodeApi<PlcModelType>(`plc_model_${modelType}`),
  });
}
