/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const washMachineItemSelectCss = {
  select: css`
    width: 100%;
  `,

  indicator: css`
    .react-select-container {
      .react-select__control {
        .react-select__indicators {
          background: #6c696a;
        }
      }
    }
  `,
};
