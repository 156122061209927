import axios, { isAxiosError } from 'axios';
import { BaseError } from './api.types';
import authStorage from '../storages/authStorage';
const baseURL = process.env.REACT_APP_API_PATH;


export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json;charset=UTF-8',
  },
});

// 요청 인터셉터 추가 - 모든 요청에 액세스토큰을 헤더에 포함
api.interceptors.request.use((config) => {
  const accessToken = authStorage.getAccessToken(); // 로컬스토리지에서 액세스토큰 가져오기
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`; // 헤더에 토큰 추가
  }
  return config; // 수정된 config 반환
});

export function applyToken(token: string) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function clearToken() {
  api.defaults.headers.common.Authorization = '';
}

export const isBaseError = isAxiosError<BaseError>;
