import { useQuery } from '@tanstack/react-query';
import { getDashboardAlarmStatusApi } from 'features/alarm/api/alarm';
import type { RegionCode } from 'features/alarm/api/dtos/getDashboardAlarmStatus.dto';

const DASHBOARD_ALARM_STATUS = 'DASHBOARD_ALARM_STATUS';

export default function useGetDashboardAlarmStatus(region: RegionCode) {
  return useQuery({
    queryKey: [DASHBOARD_ALARM_STATUS, { region }],
    queryFn: () => getDashboardAlarmStatusApi(region),
    refetchInterval: 1000 * 10,
  });
}
