import { dashboardHeaderData, regionNameValues } from '../data/dashboardData';
import { CommonCodeType, DashBoardBranchType, DashBoardDetailType, DashBoardHeaderType, WashMachineType } from '../types';
import { useQuery } from '@tanstack/react-query';
import { fetchDashBoardDetail, fetchRegion } from '../api/regionApi';

export default function useDashboard() {
  const fetcherRegionList = async () => {
    try {
      const finalResult = await Promise.allSettled(
        dashboardHeaderData.map(async ({ regionCode, regionName }: DashBoardHeaderType) => {
          const res = await fetchRegion(regionCode);
          const list: DashBoardDetailType[] = [];
          await Promise.allSettled(
            res.data.commonCodeList.map(async ({ code }: CommonCodeType) => {
              const res = await fetchDashBoardDetail({
                page: 1,
                size: 99,
                codeRegion: code,
                codeType: 'codeType=BT02&codeType=BT03&codeType=BT04',
              });
              list.push(...res.data.resData);
            })
          );
          return {
            regionCode,
            regionName,
            list: parseBoardDetailList(list),
          };
        })
      );
      return finalResult.map(({ value }: any) => value);
    } catch (error) {
      error;
    }
  };

  /**
   * 불러온 데이터를 table에 맞게 parsing하는 함수
   */
  const parseBoardDetailList = (data: DashBoardDetailType[]) => {
    if (data) {
      const list = data.map(({ branchNm, codeRegion, washMachines }: DashBoardDetailType, index: number) => {
        const item: DashBoardBranchType = {
          no: index + 1,
          regionName: regionNameValues[codeRegion],
          branchName: branchNm,
        };
        washMachines.map(({ washMachineStatus }: WashMachineType, index: number) => {
          item['washMachine'+(index+1)] = washMachineStatus;
        });
        return item;
      });
      const wholeList = Array(10 * Math.ceil(data.length / 10))
        .fill(1)
        .map((_e, index) => ({
          ...list[index],
        }));
      return wholeList;
    }
  };

  // 직영 데이터 fetch query
  const { data: allData } = useQuery({
    queryKey: ['fetchRegion', 'all'],
    queryFn: () =>
      fetchDashBoardDetail({
        page: 1,
        size: 9999,
        codeRegion: 'all',
        codeType: 'codeType=BT01',
      }),
    select: (data) => parseBoardDetailList(data.data.resData),
  });

  // 지역 데이터 fetch
  const { data: regionData } = useQuery({ queryKey: ['regionList'], queryFn: fetcherRegionList });

  return { allData, regionData };
}
