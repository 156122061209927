import styled from '@emotion/styled';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
  flex: 1;
  .swiper-pagination {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
    bottom: 36px;
  }

  .swiper-pagination-bullet {
    width: 40px;
    height: 40px;
    background-color: #231f20;
    opacity: 1;
    margin: 0;
  }

  .swiper-pagination-bullet-active {
    background-color: white;
    width: 56px;
    height: 56px;
  }
`;
