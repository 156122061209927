import { useCallback } from 'react';

interface Coordinates {
  lat: number;
  lng: number;
}

interface GeocoderResult {
  address_name: string;
  y: string; // 위도(latitude)
  x: string; // 경도(longitude)
}

/**
 * @docs https://apis.map.kakao.com/web/documentation/#services_Geocoder_addressSearch
 */
export default function useCoordinates() {
  // 주소 <-> 좌표 변환
  const getAddressCoords = useCallback((address: string): Promise<Coordinates | null> => {
    return new Promise((resolve) => {
      const geocoder = new kakao.maps.services.Geocoder();

      geocoder.addressSearch(address, (result: GeocoderResult[], status: string) => {
        if (status !== kakao.maps.services.Status.OK || result.length === 0) {
          resolve(null);
          return;
        }

        resolve({
          lat: parseFloat(result[0].y),
          lng: parseFloat(result[0].x),
        });
      });
    });
  }, []);

  return { getAddressCoords };
}
