import { api } from 'api/api';
import qs from 'qs';
import type { BaseResponse } from 'api/api.types';
import { GetBranchManagementListRequestDto, GetBranchManagementListResponseDto } from './dtos/getBranchManagementList.dto';
import { GetBranchManagementInformationItem } from './dtos/getBranchManagementInformation.dto';
import { CreateBranchInformationDto, CreateBranchInformationItem } from './dtos/createBranchInformation.dto';
import { UpdateBranchInformationDto, UpdateBranchInformationItem } from './dtos/updateBranchInformations.dto';


// 관리자 설정 지점 목록 조회
export async function getBranchManagementListApi(params: GetBranchManagementListRequestDto) {
  const { data } = await api.get<BaseResponse<GetBranchManagementListResponseDto>>('/branch/getBranchs/AdminSearch', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data.data;
}

// 지점 전체 정보 조회
export async function getBranchManagementInformationApi(branchSeq: number) {
  const { data } = await api.get<BaseResponse<GetBranchManagementInformationItem>>(`/branch/getBranchs/allInfo/${branchSeq}`);
  return data.data;
}

// 지점 등록
export async function createBranchApi(body: CreateBranchInformationDto) {
  const { data } = await api.post<BaseResponse<CreateBranchInformationItem>>('/branch/insBranchs', body);
  return data.data;
}

// 지점 수정
export async function updateBranchApi(body: UpdateBranchInformationDto) {
  const { data } = await api.put<BaseResponse<UpdateBranchInformationItem>>('/branch/updBranchs', body);
  return data.data;
}

// 지점 삭제
export async function deleteBranchApi(branchSeq: number) {
  const { data } = await api.delete<BaseResponse<void>>(`/branch/delBranchs/${branchSeq}`);
  return data;
}
