import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import AppLayoutContainer from '../containers/AppLayoutContainer';
import useBranchDivisionQuery from 'features/branchSearch/hooks/useBranchDivisionQuery';
import BranchDivision from 'features/branchSearch/components/BranchDivision';
import BranchRegions from 'features/branchSearch/components/BranchRegions';
import useBranchRegionQuery from 'features/branchSearch/hooks/useBranchRegionQuery';
import BranchSearchForm from 'features/branchSearch/components/BranchSearchForm';
import useSearchBranch from 'features/branchSearch/hooks/useSearchBranch';
import BranchList from 'features/branchSearch/components/BranchList';
import useBranchTypeOptions from 'features/branchSearch/hooks/useBranchTypeOptions';
import useBranchRegionOptions from 'features/branchSearch/hooks/useBranchRegionOptions';
import { APIProvider } from '@vis.gl/react-google-maps';
import GoogleMap from 'features/branchMap/components/GoogleMap';
import useLoadGoogleMap from 'features/branchMap/hooks/useLoadGoogleMap';
import useGetBranchListForMain from 'features/branchSearch/hooks/react-query/useGetBranchListForMain';
import { useMemo } from 'react';

function HomePage() {
  // 지점 유형 옵션
  const { branchTypeOptions } = useBranchTypeOptions();
  // 지점 선택
  const { codeType, onSelectBranchDivision } = useBranchDivisionQuery({ branchTypeOptions });
  // 지역 옵션
  const { branchRegionOptions } = useBranchRegionOptions();
  // 지역 선택
  const { codeRegion, onSelectBranchRegion } = useBranchRegionQuery({ branchRegionOptions });

  // 검색
  const { searchVal, keyword, onChangeKeyword, onSearch } = useSearchBranch();
  // 지점 목록 조회
  const { data } = useGetBranchListForMain({
    codeRegion,
    codeType,
    searchVal,
  });
  const branchList = useMemo(() => data?.resData ?? [], [data?.resData]);
  // 구글맵 로드
  const { googleMapApiKey, isLoaded, loadError } = useLoadGoogleMap();

  return (
    <AppLayoutContainer>
      <Box w="100%" display={'flex'} flex={1} overflowY={'auto'}>
        <Stack gap="24px" w="424px" p={'12px 16px'}>
          <Box w="100%">
            <Flex h="48px" alignItems="center" justifyContent="center" bg="red.500" color="white" marginBottom={'8px'}>
              <Text textAlign="center" fontWeight={700}>
                지점 리스트
              </Text>
            </Flex>

            <BranchDivision branchTypeOptions={branchTypeOptions} codeType={codeType} style={{ justifyContent: 'space-between' }} onSelectBranchDivision={onSelectBranchDivision} />

            <div style={{ background: '#c30d24', height: '2px', width: '296px', margin: '8px auto' }} />

            <BranchRegions branchRegionOptions={branchRegionOptions} codeRegion={codeRegion} style={{ gap: '8px 4px' }} onSelectBranchRegion={onSelectBranchRegion} />
            <Box marginTop={'8px'}>
              <BranchSearchForm
                //
                placeholder="주소 또는 지점 이름"
                value={keyword}
                onChange={onChangeKeyword}
                onClickSearch={onSearch}
              />
            </Box>
          </Box>
          <BranchList branchList={branchList} />
        </Stack>

        <Box flex={1}>
          {loadError ? (
            <div>Error loading Google Maps</div>
          ) : !isLoaded ? (
            <div>Loading Google Maps...</div>
          ) : (
            <APIProvider apiKey={googleMapApiKey}>
              <GoogleMap branchList={branchList} />
            </APIProvider>
          )}
        </Box>
      </Box>
    </AppLayoutContainer>
  );
}

export default HomePage;
