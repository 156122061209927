import { Thead, Table as TableWrapper, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import colors from 'theme/colors';
import { machineStatusValue } from '../../data/dashboardData';
import DashboardTableItem from './DashboardTableItem';
import { DashboardTableProps } from 'features/dashboard/types';

const DashboardTable = ({ tableHeader, tableBody }: DashboardTableProps) => {
  return (
    <TableWrapper style={{ flex: 1, borderCollapse: 'collapse' }} bg={colors.grayBlack} variant="simple" size={'sm'} h="100%" w="100%">
      <Thead h="50px">
        <Tr>
          {tableHeader.map((header, index) => (
            <Th key={index} minW={header.minWidth} color="white" textTransform={'none'} fontSize={'md'} textAlign="center" verticalAlign="middle" borderX={`1px solid #f9f9f9`} _last={{ borderX: 'none' }} _first={{ borderX: 'none' }}>
              {header.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody color={'#fff'}>
        {tableBody?.length > 0 &&
          tableBody?.map((item, innerIndex) => {
            return (
              <Tr key={innerIndex} bg={colors.gray}>
                {Array(7)
                  .fill(1)
                  .map((_e, index) => {
                    const value = Object.keys(item)[index];
                    const status = index > 2 ? machineStatusValue[item[value]] : null;
                    return <DashboardTableItem value={item[value]} status={status} key={value} />;
                  })}
              </Tr>
            );
          })}
        {tableBody?.length === 0 && (
          <Tr bg={colors.grayBlack}>
            <Td colSpan={tableHeader.length} padding="288px 0" color="#fff" fontSize={16} textAlign="center">
              일치하는 검색 결과가 없습니다.
            </Td>
          </Tr>
        )}
      </Tbody>
    </TableWrapper>
  );
};

export default DashboardTable;
