import { PlcModelTypeCode, PlcModelTypeLabel } from '../api/dtos/getPlcModelTypeList.dto';
import useGetPlcModelTypeList, { plcModelType } from './react-query/useGetPlcModelTypeList';
import { useMemo } from 'react';

export interface PlcModelTypeOption {
  label: PlcModelTypeLabel;
  value: PlcModelTypeCode;
}

export default function usePlcModelTypeOptions(modelType: plcModelType) {
  const { data } = useGetPlcModelTypeList(modelType);

  const plcModelTypeOptions = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }));
  }, [data]);

  return { plcModelTypeOptions };
}
