import { Flex, Image } from '@chakra-ui/react';
import colors from '../theme/colors';
import Button from './Button/Button';

const LogoutModal = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      position="absolute"
      top="70px"
      right="16px"
      width="150px"
      height={'70px'}
      bg={colors.grayBlack}
      color="white"
      border="2px solid #C30D24"
      boxShadow="lg"
      borderRadius="10px"
      zIndex={10}
      p={'16px 24px'}
    >
      <Button buttonSize={36} buttonType="fill" leftIcon={<Image src="/assets/images/logout.png" alt="user_icon" boxSize={7} />} onClick={onClick} style={{ borderRadius: '20px' }} capsule>
        로그아웃
      </Button>
    </Flex>
  );
};

export default LogoutModal;
