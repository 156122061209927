import { css } from '@emotion/react';

export const buttonStyle = css`
  display: flex;
  width: 72px;
  height: 28px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 5px;
  border: 2px solid var(--red-normal--, #c30d24);

  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;
