import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getBranchListForMainApi } from 'features/branchSearch/api/branchSearch';
import type { BranchSearchParams } from 'features/branchSearch/types/BranchSearch.types';

const BRANCH_LIST_FOR_MAIN = 'BRANCH_LIST_FOR_MAIN';

export default function useGetBranchListForMain({ codeRegion, codeType, searchVal }: BranchSearchParams) {
  return useQuery({
    queryKey: [BRANCH_LIST_FOR_MAIN, { codeRegion }, { codeType }, { searchVal }],
    queryFn: () =>
      getBranchListForMainApi({
        codeRegion,
        codeType,
        searchVal: searchVal ? searchVal : undefined,
      }),
    enabled: !!codeRegion && !!codeType,
    placeholderData: keepPreviousData,
    refetchInterval: 1000 * 10,
  });
}
