import { Box, Flex, Text } from '@chakra-ui/react';
import { machineStatusValue } from 'features/dashboard/data/dashboardData';
import { colors } from 'theme';
import { WashMachineType } from '../types/washmachine.type';
import FileUploadButton from './FileUploadButton';
import { UploadFileResponseType } from '../types/file.type';
import { STORAGE_URL } from 'common/constants';
import Button from 'components/Button/Button';

interface BranchMachineProps {
  index: number;
  machine: WashMachineType;
  activeMachineKey: string;
  onClickDetailMachineButton: (key: string, index: number) => void;
  onChangeThumbnail: (washMachineKey: string, res: UploadFileResponseType) => void;
}

const BranchMachine = ({ index, machine, activeMachineKey, onClickDetailMachineButton, onChangeThumbnail }: BranchMachineProps) => {
  const handleChangeThumbnail = (res: UploadFileResponseType) => {
    onChangeThumbnail(machine.washMachineKey, res);
  };

  return (
    <Box w="100%" border={'1px solid #c30d24'} borderRadius={'16px'} overflow={'hidden'} h={'100%'} flex={1} minH={'173px'}>
      {/* header */}
      <Flex justifyContent={'space-between'} h={'40px'} bg={machine?.washMachineKey === activeMachineKey ? 'red.500' : colors.gray} borderTopRadius={'15px'} justifyItems={'center'} alignItems={'center'} p={'0 8px 0 16px'}>
        <Flex alignItems={'center'} gap="18px">
          <Text fontWeight={700} color="white">
            {`세차기${index + 1}`}
          </Text>
          <Text fontSize={'15px'} fontWeight={400} color="white">
            {machine?.washMachineNm}
          </Text>
        </Flex>

        <Flex alignItems={'center'} justifyContent={'center'} p={'5px 13px'} textAlign={'center'} bg={colors.grayBlack} borderRadius={'100px'} color="white" h={'24px'} gap="4px">
          <Box w="10px" h="10px" bg={machineStatusValue[machine?.codeStatus]?.color} borderRadius={'50%'} />
          <Text fontSize={'10px'} fontWeight={700} color={machineStatusValue[machine?.codeStatus]?.color}>
            {machineStatusValue[machine?.codeStatus]?.text}
          </Text>
        </Flex>
      </Flex>

      {/* body */}
      <Flex position={'relative'} gap="16px" h={'calc(100% - 40px)'} p={'8px'} bg={colors.grayBlack} color="white">
        {/* image */}
        <Flex justify={'center'} align={'center'} w={'224px'} h={'full'} overflow="hidden">
          {/*<img src={machine.thumbImgPath ? `${STORAGE_URL}${machine.thumbImgPath}` : '/assets/images/default_img.png'} alt="logo" style={{ width: '100%', height: 'auto', maxHeight: '100%' }} />*/}
          <img src={machine.thumbImgPath ? `${STORAGE_URL}${machine.thumbImgPath}` : '/assets/images/logo-img-red.png'} alt="logo" style={{ width: '100%', height: 'auto', maxHeight: '100%' }} />
        </Flex>

        {/* content */}
        <Flex direction={'column'} justifyContent={'space-between'} align={'center'} w={'153px'}>
          <Text mt={2} fontSize={'14px'} fontWeight={700} borderBottom={'2px solid #c30d24'} padding={'6px 16px'}>
            오늘 가동 횟수
          </Text>

          <Text fontSize={'22px'} fontWeight={700}>
            {machine?.todayCnt.toLocaleString()} 회
          </Text>

          <Button
            buttonType="fill"
            buttonSize={40}
            style={{
              width: '148px',
              background: machine?.washMachineKey === activeMachineKey ? '#72000e' : '',
            }}
            onClick={() => onClickDetailMachineButton(machine?.washMachineKey, index)}
          >
            상세 정보
          </Button>
        </Flex>
        <FileUploadButton onChange={handleChangeThumbnail} />
      </Flex>
    </Box>
  );
};

export default BranchMachine;
