import React from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Flex } from '@chakra-ui/react';
import { colors } from '../../../theme';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  remoteName: string | null;
}

function ControllerErrorAlertModal({ isOpen, onClose, remoteName }: ModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.5)" />
      <ModalContent position="fixed" m="0 auto" left={0} right={0} top="40%" maxW="500px" h="200px" border="2px solid red" color="white" bg={colors.gray}>
        <Flex flexDirection="column" justify="center" align="center" height="100%">
          <ModalHeader fontSize={'15px'} fontWeight={'700'}>
            알림
          </ModalHeader>
          <ModalBody textAlign={'center'} fontSize={'18px'} fontWeight={'700'}>
            {remoteName}
            <br />
            요청에 실패하였습니다.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={'red'} mr={5} size="md" onClick={onClose}>
              확인
            </Button>
          </ModalFooter>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default ControllerErrorAlertModal;
