import { useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Title from 'components/Title/Title';
import NotificationLogForm from '../components/NotificationLogForm';
import Table from 'components/Table';
import _ from 'lodash';
import { notificationLogTableHeaders } from '../data/notificationLog';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import { css } from '@emotion/react';
import useGetAlarmTypeOptions from '../hooks/useGetAlarmTypeOptions';
import useGetAlarmSearchTypeOptions from '../hooks/useGetAlarmSearchTypeOptions';
import useGetAlarmList from '../hooks/react-query/useGetAlarmList';
import { formatDate } from 'utils/formatDate';
import { useSearchParams } from 'react-router-dom';
import type { AlarmTypeValue } from '../api/dtos/getAlarmTypeList.dto';
import type { AlarmSearchTypeValue } from '../api/dtos/getAlarmSearchTypeList.dto';
import useAlarmSearch from '../hooks/useAlarmSearch';
import CustomPagination from 'components/Pagination/CustomPagination';
import useCustomPagination from 'components/Pagination/hooks/useCustomPagination';

const NotificationLogContainer = () => {
  // 페이지네이션
  const [searchParams] = useSearchParams();
  const page = useMemo(() => {
    const page = searchParams.get('page');

    return page ? +page : 1;
  }, [searchParams]);

  // 알림 유형 옵션
  const { alarmTypeOptions } = useGetAlarmTypeOptions();
  // 검색 유형 옵션
  const { alarmSearchTypeOptions } = useGetAlarmSearchTypeOptions();
  // 검색
  const { control, handleSubmit, onSubmit, query } = useAlarmSearch();
  // 알림 목록 조회
  const { data: alarmListResponse } = useGetAlarmList({
    searchStartDt: query.searchStartDt ?? formatDate(new Date()),
    searchEndDt: query.searchEndDt ?? formatDate(new Date()),
    notificationType: (query.notificationType as AlarmTypeValue) ?? 'NTTP001',
    keywordType: (query.keywordType as AlarmSearchTypeValue) || undefined,
    keyword: query.keyword || undefined,
    page,
    size: 15,
  });
  // 조회한 알림 목록을 테이블 데이터로 변형
  const tableBody = useMemo(() => {
    if (!alarmListResponse) return [];

    return alarmListResponse.data.alarmList.map((alarm) => ({
      codeAlarmTypeNm: alarm.codeAlarmTypeNm,
      occurDt: alarm.occurDt,
      codeRegionNm: alarm.codeRegionNm,
      branchNm: alarm.branchNm,
      washMachineNm: alarm.washMachineNm,
      codeAlarmCodeMsg: alarm.codeAlarmCodeMsg,
    }));
  }, [alarmListResponse]);

  const { onPreviousPage, onNextPage, onClickPage } = useCustomPagination({ totalPages: alarmListResponse?.data.totalPages ?? 0 });

  return (
    <Box w="100%" h="100vh" display={'flex'} flexDirection={'column'} padding={'16px'}>
      <Title title="알림 로그" />
      <Box w="80%" h="20%" mx="auto">
        <NotificationLogForm
          //
          alarmTypeOptions={alarmTypeOptions}
          alarmSearchTypeOptions={alarmSearchTypeOptions}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
        {query.keyword && (
          <Flex justifyContent="center" alignItems="center" h="48px" bg="#231F20" borderBottom="3px solid #c30d24">
            <Text color={'white'} textAlign={'center'} fontSize={'lg'}>
              {`"${query.keyword}" 검색 결과`}
            </Text>
          </Flex>
        )}

        <Box mt="24px" w="100%" h="55%">
          <Scrollbar
            css={css`
              width: 100%;
              overflow-y: auto;
            `}
          >
            {<Table tableHeader={notificationLogTableHeaders} tableBody={tableBody} />}
          </Scrollbar>

          {alarmListResponse && (
            <CustomPagination
              //
              totalPages={alarmListResponse.data.totalPages}
              currentPage={page}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
              onClickPage={onClickPage}
              cssStyle={{ width: 'fit-content', margin: '0 auto', marginTop: 24 }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationLogContainer;
