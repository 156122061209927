import { api } from 'api/api';
import { BaseResponse } from './api.types';
import { UploadFileDto, UploadFileItem } from './dtos/uploadFile.dto';

// 파일 업로드
export async function uploadFileApi({ formData }: UploadFileDto) {
  const { data } = await api.post<BaseResponse<UploadFileItem>>('/file/fileupload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data.data;
}
