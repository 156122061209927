import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteBranchApi } from 'features/branchManagement/api/branchManagement';
import { BRANCH_MANAGEMENT_LIST } from './useGetBranchManagementList';
import { GetBranchManagementListResponseDto } from 'features/branchManagement/api/dtos/getBranchManagementList.dto';

interface UseDeleteBranchParams {
  onCloseBranchInformationModal: () => void;
  onCloseBranchDeleteModal: () => void;
}

export function useDeleteBranch({ onCloseBranchInformationModal, onCloseBranchDeleteModal }: UseDeleteBranchParams) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBranchApi,
    onSuccess: (_, branchSeq) => {
      queryClient.setQueriesData<GetBranchManagementListResponseDto>({ queryKey: [BRANCH_MANAGEMENT_LIST] }, (oldData) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          resData: oldData.resData.filter((item) => item.branchSeq !== branchSeq),
        };
      });

      onCloseBranchInformationModal();
    },
    onError: () => onCloseBranchDeleteModal(),
  });
}
