import { useMemo } from 'react';
import type { AlarmSearchTypeLabel, AlarmSearchTypeValue } from '../api/dtos/getAlarmSearchTypeList.dto';
import useGetAlarmSearchTypeList from './react-query/useGetAlarmSearchTypeList';

export interface AlarmSearchTypeOption {
  label: AlarmSearchTypeLabel;
  value: AlarmSearchTypeValue;
}

export default function useGetAlarmSearchTypeOptions() {
  const { data } = useGetAlarmSearchTypeList();

  const alarmSearchTypeOptions = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }) as AlarmSearchTypeOption);
  }, [data]);

  return { alarmSearchTypeOptions };
}
