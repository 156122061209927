import { api } from 'api/api';
import type { BaseResponse } from 'api/api.types';
import type { GetAlarmListRequestDto, GetAlarmListResponseDto } from './dtos/getAlarmList.dto';

export async function getAlarmListApi({ searchStartDt, searchEndDt, notificationType, keywordType, keyword, page, size }: GetAlarmListRequestDto) {
  const { data } = await api.get<BaseResponse<GetAlarmListResponseDto>>(`/alarm/search/${searchStartDt}/${searchEndDt}/${notificationType}`, {
    params: {
      keywordType,
      keyword,
      page,
      size,
    },
  });
  return data;
}
