import colors from 'theme/colors';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import { css } from '@emotion/react';
import { branchInformationList, branchWashCountList } from '../data/branchDetail';
import { useForm } from 'react-hook-form';
import { BranchDetailType, BranchInformationListType, BranchWashCountListType, BranchWashCountType } from '../types';
import BranchInformationItem from './BranchInformationItem';
import Button from 'components/Button/Button';
import theme from 'theme';

interface BranchInformationProps {
  isEditMode: boolean;
  branchInfoData: BranchDetailType;
  branchWashCountData: BranchWashCountType;
  onSubmit: (formValue: any) => void;
  onChangeIsEditMode: (is: boolean) => void;
  onClickEditCancel: () => void;
}

const BranchInformation = ({ branchInfoData, isEditMode, branchWashCountData, onSubmit, onChangeIsEditMode, onClickEditCancel }: BranchInformationProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch, // watch 추가
    formState: { isDirty },
  } = useForm();

  return (
      <Stack flex={1} w={'404px'} bg={colors.grayBlack} borderRadius={'15px'} border={`2px solid ${theme.colors.red}`} overflow={'hidden'}>
        <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'48px'} p={'6px 8px'} position={'relative'}>
          <Text textAlign={'center'} color="white" fontSize={'16px'} fontWeight={700} width={'236px'}>
            지점 정보
          </Text>
          {!isEditMode && (
              <Button buttonSize={36} buttonType="fill" style={{ position: 'absolute', top: '6px', right: '8px' }} onClick={() => onChangeIsEditMode(true)}>
                정보수정
              </Button>
          )}
          {isEditMode && (
              <Flex gap="16px" position={'absolute'} top={'6px'} right={'8px'}>
                <Button buttonSize={36} buttonType="fill" onClick={handleSubmit(onSubmit)}>
                  저장
                </Button>
                <Button
                    buttonSize={36}
                    buttonType="outline"
                    onClick={() => {
                      isDirty ? onClickEditCancel() : onChangeIsEditMode(false);
                    }}
                >
                  취소
                </Button>
              </Flex>
          )}
        </Flex>
        <Scrollbar
            css={css`
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 32px;
          flex: 1;
          margin-top: 8px;
          margin-bottom: 24px;
          margin-right: 8px;
        `}
            style={{ backgroundColor: colors.grayBlack }}
        >
          {branchInformationList.map(({ name, value }: BranchInformationListType) => (
              <BranchInformationItem
                  key={name}
                  controllerName={value}
                  name={name}
                  value={branchInfoData[value] || ''}
                  isEditMode={isEditMode}
                  control={control}
                  setValue={setValue}
                  watch={watch} // watch를 BranchInformationItem에 전달
              />
          ))}
        </Scrollbar>
        <Box h={'290px'} bgColor={theme.colors.gray} p="5px 8px">
          <Flex w="209px" flexDirection={'column'} mx="auto" alignItems={'center'} color="white" height={'96px'}>
            <Text mt={3} fontSize={'16px'} fontWeight={700}>
              금일 세차기 가동 횟수 집계
            </Text>

            <div style={{ background: '#c30d24', height: '2px', width: '209px', margin: '8.5px 0 14.5px' }} />

            <Text fontSize={'24px'} fontWeight={700}>
              {branchWashCountData['todayCnt']?.toLocaleString()} 회
            </Text>
          </Flex>
          <Stack gap="8px" color="white">
            {branchWashCountList.map(({ name, value }: BranchWashCountListType) => {
              return (
                  <Flex key={name} w="100%" justifyContent={'space-between'} h={'40px'} px="8px">
                    <Flex alignItems={'center'}>
                      <Box w="5px" h="5px" bg="white" mr={2} borderRadius={'50%'} />
                      <Text fontWeight={700}>{name}</Text>
                    </Flex>
                    <Box>{branchWashCountData[value]?.toLocaleString()}</Box>
                  </Flex>
              );
            })}
          </Stack>
        </Box>
      </Stack>
  );
};

export default BranchInformation;
