import AppLayoutContainer from '../containers/AppLayoutContainer';
import BranchStatusList from '../features/branchSearch/components/BranchStatusList';
import { Box, Flex } from '@chakra-ui/react';
import { dashboardTableHeaders } from '../features/dashboard/data/dashboardData';
import { useNavigate } from 'react-router-dom';
import Title from '../components/Title/Title';
import Scrollbar from '../components/Scrollbar/Scrollbar';
import { css } from '@emotion/react';
import useBranchDivisionQuery from 'features/branchSearch/hooks/useBranchDivisionQuery';
import BranchDivision from 'features/branchSearch/components/BranchDivision';
import BranchRegions from 'features/branchSearch/components/BranchRegions';
import useBranchRegionQuery from 'features/branchSearch/hooks/useBranchRegionQuery';
import BranchSearchForm from 'features/branchSearch/components/BranchSearchForm';
import useSearchBranch from 'features/branchSearch/hooks/useSearchBranch';
import useBranchTypeOptions from 'features/branchSearch/hooks/useBranchTypeOptions';
import useBranchRegionOptions from 'features/branchSearch/hooks/useBranchRegionOptions';
import useInfiniteBranchStatusDetailList from 'features/branchSearch/hooks/useInfiniteBranchStatusDetailList';
import BranchStatusDetailTable from 'features/branchSearch/components/BranchStatusDetailTable';

const BranchStatusPage = () => {
  const navigate = useNavigate();
  const onRowClick = (id: number) => {
    navigate(`/branch/${id}`);
  };

  // 지점 유형 옵션
  const { branchTypeOptions } = useBranchTypeOptions();
  // 지점 선택
  const { codeType, onSelectBranchDivision } = useBranchDivisionQuery({ branchTypeOptions });
  // 지역 옵션
  const { branchRegionOptions } = useBranchRegionOptions();
  // 지역 선택
  const { codeRegion, onSelectBranchRegion } = useBranchRegionQuery({ branchRegionOptions });
  // 검색
  const { keyword, onChangeKeyword, onSearch, searchVal } = useSearchBranch();
  // 지점 상태 상세 목록
  const { transformedBranchStatusDetailList, observerRef, hasNextPage, isFetching } = useInfiniteBranchStatusDetailList({ codeRegion, codeType, searchVal });

  return (
    <AppLayoutContainer>
      <Flex w="100%" flex={1} p={'16px'} gap="24px" overflowY={'auto'}>
        <Flex direction={'column'} w="50%" color="white" fontWeight={600} flex={1} overflowY={'auto'}>
          <Box>
            <Flex direction="column">
              <Title title={'지점 현황 대시보드'} />
            </Flex>

            {/* 지점 구분 필터 */}
            <Box w="70%" display={'flex'} alignItems={'center'} marginTop={'16px'}>
              <Box minW="80px" textAlign={'center'} fontSize={'14px'} fontWeight={700}>
                지점 구분
              </Box>
              <BranchDivision branchTypeOptions={branchTypeOptions} codeType={codeType} style={{ justifyContent: 'flex-start', gap: '13px' }} onSelectBranchDivision={onSelectBranchDivision} />
            </Box>

            <div style={{ background: '#c30d24', height: '2px', width: '80%', margin: '16px 0 16px 76px' }} />

            {/* 지역 구분 필터 */}
            <Box w="100%" display={'flex'} alignItems={'center'}>
              <Box minW="80px" textAlign={'center'} fontSize={'14px'} fontWeight={700}>
                지역 구분
              </Box>
              <BranchRegions branchRegionOptions={branchRegionOptions} codeRegion={codeRegion} style={{ gap: '8px 13px' }} onSelectBranchRegion={onSelectBranchRegion} />
            </Box>

            {/* 검색창 */}
            <Box w="100%" margin={'16px 0'} display={'flex'} alignItems={'center'}>
              <Box minW="80px" textAlign={'center'} fontSize={'14px'} fontWeight={700}>
                검색
              </Box>
              <BranchSearchForm placeholder="주소 또는 지점 이름" value={keyword} onChange={onChangeKeyword} onClickSearch={onSearch} />
            </Box>
          </Box>

          {/* 지점 요약 현황 목록 */}
          <BranchStatusList codeType={codeType} codeRegion={codeRegion} searchVal={searchVal} />
        </Flex>
        <Scrollbar
          css={css`
            overflow: auto;
            border-radius: 15px;
            flex: 1;
            min-width: 832px;
          `}
        >
          {/* 지점 상세 현황 목록 */}
          <BranchStatusDetailTable onRowClick={onRowClick} tableBody={transformedBranchStatusDetailList} tableHeader={dashboardTableHeaders} isFetching={isFetching} hasNextPage={hasNextPage} observerRef={observerRef} />
        </Scrollbar>
      </Flex>
    </AppLayoutContainer>
  );
};

export default BranchStatusPage;
