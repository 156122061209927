import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDeleteBranchWashMachine } from './react-query/useDeleteBranchWashMachine';
import type { UseFieldArrayReturn } from 'react-hook-form';
import type { WashMachineUpdateFormValues } from '../types/WashMachineEdit.types';

interface UseRemoveWashMachineParams {
  remove: UseFieldArrayReturn<WashMachineUpdateFormValues>['remove'];
}

export default function useRemoveWashMachine({ remove }: UseRemoveWashMachineParams) {
  const { isOpen: isOpenDeleteMachineCheckModal, onOpen: onOpenDeleteMachineCheckModal, onClose: onCloseDeleteMachineCheckModal } = useDisclosure();
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  // 세차기 삭제 버튼
  const removeWashMachineCheck = useCallback(
    (index: number) => {
      setDeleteIndex(index);
      onOpenDeleteMachineCheckModal();
    },
    [onOpenDeleteMachineCheckModal]
  );

  // 세차기 단일 삭제
  const { mutate: deleteBranchWashMachine } = useDeleteBranchWashMachine();
  const onDeleteSuccess = useCallback(() => {
    onCloseDeleteMachineCheckModal();
    setDeleteIndex(null);
  }, [onCloseDeleteMachineCheckModal]);
  // 세차기 삭제
  const removeWashMachine = useCallback(
    (washMachineKey: string | undefined) => () => {
      // 항목 추가로 생성된 아이템을 제거
      if (!washMachineKey) {
        if (!deleteIndex) return;

        remove(deleteIndex);
        onDeleteSuccess();
      } else {
        deleteBranchWashMachine(washMachineKey, {
          onSuccess: onDeleteSuccess,
        });
      }
    },
    [deleteBranchWashMachine, deleteIndex, onDeleteSuccess, remove]
  );

  return {
    isOpenDeleteMachineCheckModal,
    onCloseDeleteMachineCheckModal,
    deleteIndex,
    removeWashMachineCheck,
    removeWashMachine,
  };
}
