import colors from 'theme/colors';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { DashboardTableContainerPropTypes } from 'features/dashboard/types';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { StyledSwiper } from '../components/DashboardTable/DashboardTable.styles';
import DashboardTable from '../components/DashboardTable/DashboardTable';
import { dashboardTableHeaders } from '../data/dashboardData';
import type { RegionCode } from 'features/alarm/api/dtos/getDashboardAlarmStatus.dto';
import useGetDashboardAlarmStatus from 'features/alarm/hooks/react-query/useGetDashboardAlarmStatus';

const DashboardTableContainer = ({ regionName, isDirectStore, tableData }: DashboardTableContainerPropTypes) => {
  function chunkArray(array: any[], chunkSize: number) {
    const result = [];
    if (array?.length < 24) {
      const arr = new Array(24).fill({});
      for (let i = 0; i < array.length; i++) {
        arr[i] = array[i];
      }
      result.push(arr);
    } else {
      for (let i = 0; i < array?.length; i += chunkSize) {
        const chunk = array?.slice(i, i + chunkSize);
        result.push(chunk);
      }
    }
    return result;
  }

  // 20개씩 잘라서 배열로 반환
  const chunkedArrays = chunkArray(tableData, 24);

  const regionCode: RegionCode = useMemo(() => {
    switch (regionName) {
      case '직영':
        return 'BMRG0001';
      case '수도권':
        return 'BMRG1001';
      case '충청/전라/강원':
        return 'BMRG1002';
      case '경상/제주':
        return 'BMRG1003';
    }
  }, [regionName]);

  const { data: dashboardAlarmStatus } = useGetDashboardAlarmStatus(regionCode);

  return (
    <Stack gap="0" w={isDirectStore ? '40%' : '20%'} height={'100%'} bg={colors.grayBlack} overflow="hidden">
      <Flex align={'center'} px={'24px'} justifyContent={'space-between'} h="240px" bg="red.500" position={'relative'}>
        <Box w={'88px'} />

        <Text color={'white'} fontSize={'80px'} fontWeight={700} textShadow={`6.809px 13.617px 13.617px rgba(0, 0, 0, 0.50)`} whiteSpace={'nowrap'}>
          {regionName}
        </Text>

        {dashboardAlarmStatus?.alarmTF ? (
          // <Flex w={'88px'} h={'88px'} justifyContent={'center'} alignItems={'center'} bg={'#FFB800'} border={'4px solid #fff'} borderRadius={'50%'}>
          <Flex  h={'88px'} justifyContent={'center'} alignItems={'center'}  borderRadius={'50%'}>
            {/*<Image src="/assets/icons/dashboard_bell_icon.png" alt="dashboard icon" w={'56px'} h={'56px'} />*/}
            <Image src="/assets/icons/alert-siren.gif" alt="dashboard icon" w={'146px'} h={'48px'} />
          </Flex>
        ) : (
          <Box w={'88px'} />
        )}
      </Flex>

      <Flex h="90%" bg={'#6c696a'} flex={1} padding={'20px 20px 148px'} position={'relative'}>
        {chunkedArrays.length > 0 && (
          <StyledSwiper
            style={{ flex: 1, position: 'unset' }}
            slidesPerView={isDirectStore && tableData?.length > 10 ? 2 : 1}
            spaceBetween={40}
            autoplay={{ delay: 20000, disableOnInteraction: false }}
            loop={true}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {chunkedArrays.map((data, i) => (
              <SwiperSlide key={i}>
                <Flex overflowX={'auto'} h="100%" borderWidth="1px" borderRadius="16px" overflow="hidden" paddingBottom={'24px'} backgroundColor={colors.grayBlack}>
                  <DashboardTable tableHeader={dashboardTableHeaders} tableBody={data} />
                </Flex>
                <div className="swiper-pagination"></div>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        )}
      </Flex>
    </Stack>
  );
};

export default DashboardTableContainer;
