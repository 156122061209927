import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBranchApi } from 'features/branchManagement/api/branchManagement';
import { BRANCH_MANAGEMENT_LIST } from './useGetBranchManagementList';

interface UseCreateBranchParams {
  onCloseBranchInformationModal: () => void;
}

// // 매핑 함수 정의
// function mapCreateBranchToBranchManagementListItem(newBranch: CreateBranchInformationItem): BranchManagementListItem {
//   return {
//     branchSeq: newBranch.branchSeq,
//     codeRegion: newBranch.codeRegion as BranchRegionValue,
//     branchNm: newBranch.branchNm,
//     branchOwnerNm: newBranch.branchOwnerNm,
//     dvcInstallDt: newBranch.dvcInstallDt,
//     branchOwnerPhone: newBranch.branchOwnerPhone,
//     branchAddr: newBranch.branchAddr,
//     branchConnectStatus: 'WCS0002', // 적절한 기본값 설정
//     branchConnectStatusNm: '연동안됨', // 적절한 기본값 설정
//   };
// }

export default function useCreateBranch({ onCloseBranchInformationModal }: UseCreateBranchParams) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBranchApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BRANCH_MANAGEMENT_LIST] });

      onCloseBranchInformationModal();
    },
  });
}
