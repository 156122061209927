import { useEffect, useMemo, useState } from 'react';
import useGetNewUrgentAlarms from 'features/alarm/hooks/react-query/useGetNewUrgentAlarms';
import useReadNewUrgentAlarms from 'features/alarm/hooks/react-query/useReadNewUrgentAlarms';

export interface urgentAlarmTableHeaderType {
  label: string;
  minWidth: string;
}

const urgentAlarmTableHeader: urgentAlarmTableHeaderType[] = [
  { label: '시간', minWidth: '98px' },
  { label: '지점', minWidth: '100px' },
  { label: '세차기', minWidth: '100px' },
  { label: '내용', minWidth: '332px' },
];

export default function useNewUrgentAlarm() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hasUrgentAlarm, setHasUrgentAlarm] = useState<boolean>(false);

  // 긴급 알림 목록 조회
  const { data: newUrgentAlarms } = useGetNewUrgentAlarms();
  // 읽음 처리 유무
  useEffect(() => {
    if (!newUrgentAlarms) return;

    setHasUrgentAlarm(newUrgentAlarms.alarmList.length > 0);
  }, [newUrgentAlarms]);
  // 긴급 알림 목록
  const tableData = useMemo(() => {
    if (!newUrgentAlarms) return [];

    return newUrgentAlarms.alarmList;
  }, [newUrgentAlarms]);

  // 긴급 알림 읽음 처리
  const { mutate: readNewUrgentAlarms } = useReadNewUrgentAlarms();

  const onCloseAlarmModal = () => {
    setIsModalOpen(false);
  };

  const onOpenAlarmModal = () => {
    setIsModalOpen((prev) => !prev);

    if (!hasUrgentAlarm) return;
    readNewUrgentAlarms(
      tableData.map((alarm) => alarm.seq),
      {
        onSuccess: () => setHasUrgentAlarm(false),
      }
    );
  };

  return { hasUrgentAlarm, isModalOpen, onCloseAlarmModal, onOpenAlarmModal, tableHeader: urgentAlarmTableHeader, tableData };
}
