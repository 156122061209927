import { Flex, Grid, useDisclosure, Input, Textarea } from '@chakra-ui/react';
import BranchInformationItem from './BranchInformationItem';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import useBranchRegionOptions from 'features/branchSearch/hooks/useBranchRegionOptions';
import useBranchTypeOptions from 'features/branchSearch/hooks/useBranchTypeOptions';
import { Controller, FieldErrors, useForm, SubmitHandler } from 'react-hook-form';
import { BranchInformationCreateSchema, branchInformationCreateSchema } from 'features/branchManagement/schema/branchInformationForm.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import useCreateBranch from 'features/branchManagement/hooks/react-query/useCreateBranch';
import AlertModal from 'components/Modals/AlertModal';
import { useCallback, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import AddressInput from 'components/AddressInput/AddressInput';
import ImageUploadButton from '../../ImageUploadButton/ImageUploadButton';
import { UploadFileItem } from 'api/dtos/uploadFile.dto';
import useUploadFile from 'hooks/useUploadFile';
import useCoordinates from 'features/branchManagement/hooks/useCoordinates';

interface Props {
  onCloseBranchInformationModal: () => void;
  onOpenUnsavedModal: () => void;
}

const BranchInformationCreate = ({ onCloseBranchInformationModal, onOpenUnsavedModal }: Props) => {
  const { isOpen: isOpenSaveRequiredModal, onOpen: onOpenSaveRequiredModal, onClose: onCloseSaveRequiredModal } = useDisclosure();
  const [saveRequiredText, setSaveRequiredText] = useState('');
  const [uploadFileData, setUploadFileData] = useState<UploadFileItem>();

  // 지점 유형 옵션
  const { branchTypeOptionsWithoutAll } = useBranchTypeOptions();
  // 지역 옵션
  const { branchRegionOptionsWithoutAll } = useBranchRegionOptions();
  // 파일 업로드
  const { mutateAsync: uploadImageAsync } = useUploadFile();
  // 지점 추가
  const { mutate: createBranch } = useCreateBranch({ onCloseBranchInformationModal });

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<BranchInformationCreateSchema>({
    resolver: zodResolver(branchInformationCreateSchema),
    defaultValues: {
      codeType: undefined,
      codeRegion: undefined,
      branchNm: '',
      branchAddr: '',
      gpsLat: 0,
      gpsLng: 0,
    },
  });

  // 파일 업로드
  const onImageUpload = async (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const uploadFileResponse = await uploadImageAsync({ formData });
      setValue('branchImgSeq', uploadFileResponse.seq, { shouldValidate: true });
      setUploadFileData(uploadFileResponse);
    }
  };

  const { getAddressCoords } = useCoordinates();
  const onChangeAddress = useCallback(
    async (address: string) => {
      // 좌표값
      const coordinate = await getAddressCoords(address);
      if (!coordinate) {
        alert('주소와 일치하는 좌표값이 존재하지 않습니다.\n지번 주소 혹은 다른 도로명 주소를 선택해주세요.');
        return;
      }

      setValue('branchAddr', address);
      setValue('gpsLat', coordinate.lat);
      setValue('gpsLng', coordinate.lng);
    },
    [getAddressCoords, setValue]
  );

  // 지점 등록
  const onSubmit: SubmitHandler<BranchInformationCreateSchema> = async (formData) => {
    const isOwnerPhoneComplete = formData.ownerPhone1 && formData.ownerPhone2 && formData.ownerPhone3;
    const isAdminPhoneComplete = formData.adminPhone1 && formData.adminPhone2 && formData.adminPhone3;

    const ownerPhone = `${formData.ownerPhone1}-${formData.ownerPhone2}-${formData.ownerPhone3}`;
    const adminPhone = `${formData.adminPhone1}-${formData.adminPhone2}-${formData.adminPhone3}`;

    createBranch({
      codeType: formData.codeType,
      codeRegion: formData.codeRegion,
      branchNm: formData.branchNm,
      branchOwnerNm: formData.branchOwnerNm || undefined,
      branchBrn: formData.branchBrn || undefined,
      branchAddr: formData.branchAddr,
      branchOwnerPhone: isOwnerPhoneComplete ? ownerPhone : undefined,
      branchAdminPhone: isAdminPhoneComplete ? adminPhone : undefined,
      branchMemo: formData.branchMemo || undefined,
      branchImgSeq: formData.branchImgSeq || undefined,
      gpsLat: formData.gpsLat,
      gpsLng: formData.gpsLng,
      dvcInstallDt: formData.dvcInstallDt || undefined,
      catId: formData.catId || undefined,
      tabletVersion: formData.tabletVersion || undefined,
      plcVersion: formData.plcVersion || undefined,
      pubIp: formData.pubIp || undefined,
      ddnsAddr: formData.ddnsAddr || undefined,
    });
  };

  // 취소 버튼
  const onClose = () => {
    reset();
    onOpenUnsavedModal();
  };

  // 필수 항목 전체 체크
  const onError = (errors: FieldErrors<BranchInformationCreateSchema>) => {
    const missingFields = Object.keys(errors)
      .map((field) => {
        switch (field) {
          case 'codeType':
            return '지점 구분';
          case 'codeRegion':
            return '지역 선택';
          case 'branchNm':
            return '지점 이름';
          case 'branchAddr':
            return '주소';
          case 'gpsLat':
            return;
          case 'gpsLng':
            return;
          default:
            return field;
        }
      })
      .filter((val) => val)
      .join(', ');

    setSaveRequiredText(missingFields);
    onOpenSaveRequiredModal();
  };

  return (
    <>
      {/* modal content */}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Flex align="flex-start" alignSelf="stretch" p="16px" gap="24px">
          <ImageUploadButton uploadFileData={uploadFileData} onImageUpload={onImageUpload} />

          <Flex justifyContent="center" gap="24px" flex={1}>
            <Grid h="full" templateRows="repeat(11, 60px)" templateColumns="repeat(1, 1fr)">
              {/* 지점 구분 */}
              <BranchInformationItem label="지점 구분 *" row={1} col={1}>
                <Controller
                  control={control}
                  name="codeType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={branchTypeOptionsWithoutAll}
                      placeholder="구분 선택"
                      cssStyle={{ width: '100%' }}
                      onChange={(option) => onChange(option?.value)}
                      value={branchTypeOptionsWithoutAll.find((option) => option.value === value) ?? null}
                    />
                  )}
                />
              </BranchInformationItem>

              {/* 지역 선택 */}
              <BranchInformationItem label="지역 선택 *" row={1} col={1}>
                <Controller
                  control={control}
                  name="codeRegion"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={branchRegionOptionsWithoutAll}
                      placeholder="지역 선택"
                      cssStyle={{ width: '100%' }}
                      onChange={(option) => onChange(option?.value)}
                      value={branchRegionOptionsWithoutAll.find((option) => option.value === value) ?? null}
                    />
                  )}
                />
              </BranchInformationItem>

              {/* 지점 이름 */}
              <BranchInformationItem label="지점 이름 *" row={1} col={1}>
                <Input type="text" variant="edit" {...register('branchNm')} onBlur={(e) => setValue('branchNm', e.target.value, { shouldValidate: true })} />
                {errors.branchNm && <ErrorMessage text="* 지점 이름은 필수 입니다." />}
              </BranchInformationItem>

              {/* 지점장 */}
              <BranchInformationItem label="지점장" row={1} col={1}>
                <Input type="text" variant="edit" {...register('branchOwnerNm')} />
              </BranchInformationItem>

              {/* 사업자 등록번호 */}
              <BranchInformationItem label={'사업자\n등록번호'} row={1} col={1}>
                <Input type="number" variant="edit" {...register('branchBrn')} />
              </BranchInformationItem>

              {/* 주소 */}
              <BranchInformationItem label="주소 *" row={1} col={1}>
                <Controller
                  //
                  control={control}
                  name="branchAddr"
                  render={() => (
                    <AddressInput
                      //
                      placeholder="예) 판교역로 166, 분당 주공, 백현동 532"
                      address={watch('branchAddr')}
                      onComplete={onChangeAddress}
                    />
                  )}
                />
              </BranchInformationItem>

              {/* 지점장 전화번호 */}
              <BranchInformationItem label={'지점장\n 전화번호'} row={1} col={1}>
                <Flex align="center" gap="6px" color="white">
                  <Input type="number" variant="edit" w="60px" {...register('ownerPhone1')} /> - <Input type="number" variant="edit" w="60px" {...register('ownerPhone2')} /> -{' '}
                  <Input type="number" variant="edit" w="60px" {...register('ownerPhone3')} />
                </Flex>
              </BranchInformationItem>

              {/* 관리자 전화번호 */}
              <BranchInformationItem label={'관리자\n 전화번호'} row={1} col={1}>
                <Flex align="center" gap="6px" color="white">
                  <Input type="number" variant="edit" w="60px" {...register('adminPhone1')} /> - <Input type="number" variant="edit" w="60px" {...register('adminPhone2')} /> -{' '}
                  <Input type="number" variant="edit" w="60px" {...register('adminPhone3')} />
                </Flex>
              </BranchInformationItem>

              {/* 메모 */}
              <BranchInformationItem label="메모" row={3} col={1}>
                <Textarea variant="edit" w="full" h="168px" {...register('branchMemo')} />
              </BranchInformationItem>
            </Grid>

            <Grid h="full" templateRows="repeat(11, 60px)" templateColumns="repeat(1, 1fr)">
              {/* 설치일 */}
              <BranchInformationItem label="설치일" row={1} col={1}>
                <Controller
                  control={control}
                  name="dvcInstallDt"
                  render={({ field: { onChange, value } }) => {
                    const selectedDate = value ? new Date(value) : null;

                    const handleDateChange = (date: Date | null) => {
                      onChange(date ? date.toISOString() : null);
                    };

                    return <CustomDatePicker modalName="설치일" placeholder="날짜 선택" selectedDate={selectedDate} onChangeDate={handleDateChange} />;
                  }}
                />
              </BranchInformationItem>

              {/* CAT ID */}
              <BranchInformationItem label="CAT ID" row={1} col={1}>
                <Input type="text" variant="edit" {...register('catId')} />
              </BranchInformationItem>

              {/* 태블릿 버전 */}
              <BranchInformationItem label="태블릿 버전" row={1} col={1}>
                <Input type="text" variant="edit" {...register('tabletVersion')} />
              </BranchInformationItem>

              {/* PLC 버전 */}
              <BranchInformationItem label="PLC 버전" row={1} col={1}>
                <Input type="text" variant="edit" {...register('plcVersion')} />
              </BranchInformationItem>

              {/* 외부 아이피 */}
              <BranchInformationItem label="외부 아이피" row={1} col={1}>
                <Input type="text" variant="edit" {...register('pubIp')} />
              </BranchInformationItem>

              {/* DDNS */}
              <BranchInformationItem label="DDNS" row={1} col={1}>
                <Input type="text" variant="edit" {...register('ddnsAddr')} />
              </BranchInformationItem>
            </Grid>
          </Flex>
        </Flex>

        {/* modal footer */}
        <Flex justify="center" align="center" gap="16px" w="full" p="24px 0">
          <Button type="submit" buttonType="fill" buttonSize={40}>
            저 장
          </Button>
          <Button type="button" buttonType="outline" buttonSize={40} onClick={onClose}>
            취 소
          </Button>
        </Flex>
      </form>
      <AlertModal title={`[ ${saveRequiredText} ]\n필수 항목입니다.`} isOpen={isOpenSaveRequiredModal} onClose={onCloseSaveRequiredModal} />
    </>
  );
};

export default BranchInformationCreate;
