import { extendTheme, theme } from '@chakra-ui/react';
import { radioTheme } from './components/radio';
import { inputTheme } from './components/input';
import { textareaTheme } from './components/textarea';

const extendedBaseTheme = extendTheme({
  fonts: {
    heading: 'NotoSansKR', // 폰트 이름을 직접 지정합니다.
    body: 'NotoSansKR',
  },
  colors: {
    ...theme.colors,
    red: { 500: '#C30D24' },
    warmGray: { 50: '#F7F3F2', 100: '#E5E0DF', 200: '#CAC5C4', 300: '#ADA8A8', 400: '#8F8B8B', 500: '#736F6F', 600: '#565151', 700: '#3C3838', 800: '#272525', 900: '#171414' },
    green: { 50: '#ECFFF9', 100: '#D1FFF0', 200: '#B7FFE7', 300: '#98EFD2', 400: '#70E1BB', 500: '#44D1A2', 600: '#0CB07A', 700: '#00714B', 800: '#005337', 900: '#003725' },
  },
  components: {
    Radio: radioTheme,
    Input: inputTheme,
    Textarea: textareaTheme,
  },
});

export default extendedBaseTheme;
