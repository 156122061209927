import { Table as TableWrapper, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import colors from '../theme/colors';
import theme from 'theme';

interface TableProps {
  tableHeader: { label: string; minWidth: string; width?: string }[];
  tableBody: { [key: string]: any }[];
  onRowClick?: (id: number) => void;
}

const Table = ({ tableHeader, tableBody, onRowClick }: TableProps) => {
  return (
    <>
      <TableWrapper style={{ flex: 1 }} variant="simple" size={'sm'} h="100%" w="100%" marginBottom={4}>
        <Thead h="40px" bg="#231F20">
          <Tr>
            {tableHeader.map((header, index) => (
              <Th key={index} minW={header.minWidth} w={header.width} color="white" fontSize={'14px'} fontWeight={700} textAlign="center" verticalAlign="middle">
                {header.label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody color={'#fff'}>
          {tableBody?.length > 0 &&
            tableBody.map((item, innerIndex) => (
              <Tr key={innerIndex} bg={colors.gray} cursor={onRowClick && 'pointer'} onClick={() => onRowClick && onRowClick(item.no)}>
                {Object.keys(item).map((key) => {
                  const isEmergency = item[key] === '긴급';
                  return (
                    <Td key={key} h="40px" p={0} m={0} paddingY="15px" textAlign="center" verticalAlign={'middle'} fontSize={'12px'} fontWeight={400} _first={{ fontWeight: 700 }} color={isEmergency ? theme.colors.red : theme.colors.white}>
                      {item[key]}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          {tableBody?.length === 0 && (
            <Tr bg={colors.grayBlack}>
              <Td colSpan={tableHeader.length} padding="288px 0" color="#fff" fontSize={16} textAlign="center">
                일치하는 검색 결과가 없습니다.
              </Td>
            </Tr>
          )}
        </Tbody>
      </TableWrapper>
    </>
  );
};

export default Table;
