import { Link } from 'react-router-dom';
import AppLayoutContainer from '../containers/AppLayoutContainer';

const IndexPage = () => {
  return (
    <AppLayoutContainer>
      <main>
        <Link to="/profile" style={{ color: 'red' }}>{`프로필 페이지로 이동`}</Link>
        <Link to="/register" style={{ color: 'red' }}>{`폼 입력 페이지로 이동`}</Link>
      </main>
    </AppLayoutContainer>
  );
};

export default IndexPage;
