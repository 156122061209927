import { z } from 'zod';

const machineTypeValue = ['MT0001', 'MT0002'] as const;
const machineModelTypeCode = ['MM0001', 'MM0002', 'MM0003', 'MM0004', 'MM0005', 'MM0006', 'MM0007'] as const;
const plcProviderTypeCode = ['PM0001', 'PM0002'] as const;
const plcModelTypeCode = ['PMN001', 'PMN002', 'PMN003', 'PMN004', 'PMN005', 'PMN006'] as const;

export const branchWashMachineInitialFormSchema = z.object({
  branchSeq: z.number().min(1),
  codeType: z.enum(machineTypeValue),
  codeModel: z.enum(machineModelTypeCode).optional(),
  plcPrivider: z.enum(plcProviderTypeCode).optional(),
  plcModel: z.enum(plcModelTypeCode).optional(),
  plcVersion: z.string().min(1),
});

export const branchWashMachineCreateFormSchema = z.object({
  branchSeq: z.number().min(1),
  codeType: z.enum(machineTypeValue),
  codeModel: z.enum(machineModelTypeCode),
  plcPrivider: z.enum(plcProviderTypeCode),
  plcModel: z.enum(plcModelTypeCode),
  plcVersion: z.string().min(1),
});

export const branchWashMachineInitialUpdateFormSchema = branchWashMachineInitialFormSchema.extend({
  washMachineKey: z.string().optional(),
});

export const branchWashMachineUpdateFormSchema = branchWashMachineCreateFormSchema.extend({
  washMachineKey: z.string().optional(),
});

export type BranchWashMachineInitialFormSchema = z.infer<typeof branchWashMachineInitialFormSchema>;
export type BranchWashMachineCreateFormSchema = z.infer<typeof branchWashMachineCreateFormSchema>;
export type BranchWashMachineInitialUpdateFormSchema = z.infer<typeof branchWashMachineInitialUpdateFormSchema>;
export type BranchWashMachineUpdateFormSchema = z.infer<typeof branchWashMachineUpdateFormSchema>;
