import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LoginWrapper = styled.div`
  background-image: url('/assets/images/login_bg2.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
`;

export const LoginSubWrapper = styled.div`
  position: relative;
  background-image: url('/assets/images/login_bg1.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
`;

export const FormWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 454px;
  padding: 24px 35px;
  /* background-color: gray; */
`;

export const loginButton = css`
  background: linear-gradient(to right, rgba(190, 24, 0, 1), rgba(142, 0, 22, 1));
  margin-top: 48px;
  width: 100%;
`;
