import { SelectOption } from "components/Pagination/hooks/usePagination";

export const branchSearchOptions = [
  {
    label: '전체',
    value: 'SKT001',
  },
  {
    label: '지점장 이름',
    value: 'SKT002',
  },
  {
    label: '지점명',
    value: 'SKT003',
  },
  {
    label: '지점장 전화번호',
    value: 'SKT004',
  },
  {
    label: '지점 주소',
    value: 'SKT005',
  },
] satisfies SelectOption[];
