import { css } from '@emotion/react';

export const errorMessage = css`
  color: #f00;
  text-align: start;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
