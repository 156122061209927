import { Flex, Text } from '@chakra-ui/react';
import ModalLayout from 'components/Modals/ModalLayout';
import Button from 'components/Button/Button';

interface Props {
  isOpen: boolean;
  confirmText?: string;
  closeText?: string;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  description?: string;
}

const ConfirmModal = ({ isOpen, confirmText = '확 인', closeText = '취 소', onConfirm, onClose, title, description }: Props) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column" w="498px" h="100%" bg="#393536">
        {/* modal header */}
        <Flex justifyContent="center" alignItems="center" alignSelf="stretch" h="40px" p="6px 16px">
          <Text fontSize="16px" fontWeight="700" color="white">
            알림
          </Text>
        </Flex>

        {/* modal content */}
        <Flex flexDirection="column" alignItems="center" p="16px" gap={description ? '24px' : '0px'} color="white" minH="56px">
          <Text fontSize="20px" fontWeight="700" textAlign="center" whiteSpace="pre-wrap">
            {title}
          </Text>

          {description && (
            <Text fontSize="14px" fontWeight="400" textAlign="center" whiteSpace="pre-wrap">
              {description}
            </Text>
          )}
        </Flex>

        {/* modal footer */}
        <Flex justifyContent="center" gap="16px" p="24px 0">
          <Button buttonType="fill" buttonSize={40} onClick={onConfirm}>
            {confirmText}
          </Button>
          <Button buttonType="outline" buttonSize={40} onClick={onClose}>
            {closeText}
          </Button>
        </Flex>
      </Flex>
    </ModalLayout>
  );
};

export default ConfirmModal;
