import { api } from 'api/api';
import type { GetBranchListRequsetDto, GetBranchListForMainResponseDto } from './dtos/getBranchListForMain.dto';
import type { BaseResponse } from 'api/api.types';
import qs from 'qs';
import type { GetBranchStatusListRequestDto, GetBranchStatusListResponseDto } from './dtos/getBranchStatusList.dto';
import type { GetBranchStatusDetailListResponseDto } from './dtos/getBranchStatusDetailList.dto';

// 메인 페이지 지점 목록 조회
export async function getBranchListForMainApi(params: GetBranchListRequsetDto) {
  const { data } = await api.get<BaseResponse<GetBranchListForMainResponseDto>>('/branch/getBranchs/Main', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data.data;
}

// 지점 현황 목록 조회
export async function getBranchStatusListApi(params: GetBranchStatusListRequestDto) {
  const { data } = await api.get<BaseResponse<GetBranchStatusListResponseDto>>('/branch/getBranchs/DashBoard', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data.data;
}

// 지점 현황 상세 목록 조회
export async function getBranchStatusDetailListApi(params: GetBranchStatusListRequestDto) {
  const { data } = await api.get<BaseResponse<GetBranchStatusDetailListResponseDto>>('/branch/getBranchs/DashBoardDetail', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data.data;
}
