import { ReactNode, useState, type PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import theme from '../theme';
import extendedBaseTheme from '../theme/chakra';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';
import GlobalEffect from './GlobalEffect';

interface ProvidersProps {
  children?: ReactNode;
}

export default function Providers({ children }: PropsWithChildren<ProvidersProps>) {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ChakraProvider theme={extendedBaseTheme}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ThemeProvider theme={theme}>
                <GlobalEffect />

                {children}
              </ThemeProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </ChakraProvider>
      </RecoilRoot>

      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
    </QueryClientProvider>
  );
}
