import { useQuery } from '@tanstack/react-query';
import type { BranchType } from 'features/branchSearch/api/dtos/getBranchTypeList.dto';
import { getCommonCodeApi } from 'features/common/api/common';

const BRANCH_TYPE_LIST = 'BRANCH_TYPE_LIST';

const sortOrder = ['직영', 'HD', 'E1', '가맹'];

export default function useGetBranchTypeList() {
  return useQuery({
    queryKey: [BRANCH_TYPE_LIST],
    queryFn: () => getCommonCodeApi<BranchType>('branchType'),
    select: (data) => {
      return data.sort((a, b) => {
        return sortOrder.indexOf(a.codeValue) - sortOrder.indexOf(b.codeValue);
      });
    },
  });
}
