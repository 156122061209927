import { PropsWithChildren } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import HeaderContainer from './HeaderContainer/HeaderContainer';
import Sidebar from '../components/Sidebar/Sidebar';

const AppLayoutContainer = ({ children }: PropsWithChildren) => {
  return (
    <Stack gap="0" w="100vw" h="100vh">
      <HeaderContainer />

      <Flex flex="1" mt="54px" overflow={'auto'}>
        <Sidebar />

        <Stack flex="1" bg="#6C696A" overflowY={'auto'}>
          {children}
        </Stack>
      </Flex>
    </Stack>
  );
};

export default AppLayoutContainer;
