import { useQuery } from '@tanstack/react-query';
import { MachineModelType } from 'features/branchManagement/api/dtos/getMachineModelTypeList.dto';
import { MachineTypeLabelEnglish, MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import { getCommonCodeApi } from 'features/common/api/common';
import { CommonCodeCategory } from 'features/common/api/dtos/getCommonCodeCategory.dto';

const MACHINE_MODEL_TYPE_LIST = 'MACHINE_MODEL_TYPE_LIST';

const machineTypeMapping: Record<MachineTypeValue, MachineTypeLabelEnglish> = {
  MT0001: 'normal',
  MT0002: 'tunnel',
};

export default function useGetMachineModelTypeList(modelType: MachineTypeValue) {
  const modelTypeEnglish = machineTypeMapping[modelType];

  return useQuery({
    queryKey: [MACHINE_MODEL_TYPE_LIST, modelTypeEnglish],
    queryFn: () => getCommonCodeApi<MachineModelType>(`machine_model_${modelTypeEnglish}` as CommonCodeCategory),
  });
}
