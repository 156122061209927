import { Flex, Text } from '@chakra-ui/react';
import ModalHeader from 'components/Modals/ModalHeader';
import ModalLayout from 'components/Modals/ModalLayout';
import { useEffect, useState } from 'react';
import WashMachineCreateForm from './WashMachineCreateForm';
import WashMachineEditForm from './WashMachineEditForm';
import WashMachineViewForm from './WashMachineView';
import useGetBranchWashMachineList from 'features/branchManagement/hooks/react-query/useGetBranchWashMachineList';

type ModalMode = 'view' | 'create' | 'edit';

interface Props {
  branchSequence: number;
  branchName: string;
  isOpen: boolean;
  onClose: () => void;
}

const WashMachineManagementModal = ({ branchSequence, branchName, isOpen, onClose }: Props) => {
  const [modalMode, setModalMode] = useState<ModalMode>('view');

  // 지점 별 세차기 목록 조회
  const { data: branchWashMachineList, isSuccess } = useGetBranchWashMachineList(branchSequence);

  // 세차기 수정하기
  const onClickEditMode = () => {
    setModalMode('edit');
  };

  // 세차기 보기
  const onClickViewMode = () => {
    setModalMode('view');
  };

  // 세차기 목록이 존재하면 view 모드로 설정
  useEffect(() => {
    if (!branchWashMachineList) return;

    setModalMode((prev) => {
      // 편집모드에서 삭제 후 편집모드에 남아있도록 설정
      if (prev === 'edit') return 'edit';

      return branchWashMachineList.length > 0 ? 'view' : 'create';
    });
  }, [branchWashMachineList]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column" w="860px" h="820px">
        {/* modal header */}
        <ModalHeader title="지점 세차기 관리" />

        <Flex flexDirection="column" alignItems="center" p="0 16px" w="full" flex={1}>
          <Flex alignItems="center" gap="8px" p="8px 0px" borderBottom="4px solid #C30D24" w="full">
            <img src="/assets/images/title_logo.png" alt="title logo" width="40px" height="40px" />
            <Text fontSize={14} fontWeight={700} color="white">
              {branchName}
            </Text>
          </Flex>

          {/* 세차기 등록 */}
          {isSuccess && modalMode === 'create' && <WashMachineCreateForm branchSequence={branchSequence} onClose={onClose} />}
          {/* 세차기 수정 */}
          {isSuccess && modalMode === 'edit' && branchWashMachineList && branchWashMachineList.length > 0 && (
            <WashMachineEditForm
              //
              branchSequence={branchSequence}
              branchWashMachineList={branchWashMachineList}
              onClickViewMode={onClickViewMode}
            />
          )}
          {/* 세차기 보기 */}
          {isSuccess && modalMode === 'view' && branchWashMachineList && branchWashMachineList.length > 0 && (
            <WashMachineViewForm
              //
              branchWashMachineList={branchWashMachineList}
              onClickEditMode={onClickEditMode}
              onClose={onClose}
            />
          )}
        </Flex>
      </Flex>
    </ModalLayout>
  );
};

export default WashMachineManagementModal;
