import colors from './colors';
import media from './media';
import typography from './typography';

const theme = {
  colors,
  media,
  typography,
};

export { default as colors } from './colors';
export { default as media } from './media';
export { default as typography } from './typography';

export default theme;
