import { tableTd, tableTh, tableTr } from './BranchManagementListTable.styles';
import { Table, Thead, Tbody, Tr, Th, Td, Flex, useDisclosure } from '@chakra-ui/react';
import { BranchManagementColDefsData } from '../../data/branchManagementColDefs.data';
import dayjs from 'dayjs';
import WashMachineLinkModal from '../Modal/WashMachineLinkModal/WashMachineLinkModal';
import BranchInformationModal from '../Modal/BranchInformationModal/BranchInformationModal';
import { BranchManagementListItem } from 'features/branchManagement/api/dtos/getBranchManagementList.dto';
import WashMachineManagementModal from '../Modal/WashMachineManagementModal/WashMachineManagementModal';
import useGetMe from 'features/userProfile/hooks/useGetMe';
import { useState } from 'react';
import useBranchRegionMap from 'features/branchSearch/hooks/useBranchRegionMap';
import Button from 'components/Button/Button';
import { colors } from 'theme';

interface TableProps {
  colDefs: BranchManagementColDefsData[];
  tableData: BranchManagementListItem[];
  topIndex: number;
}

const BranchManagementListTable = ({ colDefs, tableData, topIndex }: TableProps) => {
  const { isOpen: isOpenBranchInformationModal, onOpen: onOpenBranchInformationModal, onClose: onCloseBranchInformationModal } = useDisclosure();
  const { isOpen: isOpenWashMachineManagementModal, onOpen: onOpenWashMachineManagementModal, onClose: onCloseWashMachineManagementModal } = useDisclosure();
  const { data: userMe } = useGetMe();
  const [selectedBranchSequence, setSelectedBranchSequence] = useState<number | null>(null);
  const [selectedBranchName, setSelectedBranchName] = useState<string | null>(null);
  const { branchRegionMap } = useBranchRegionMap();

  // 지점별 지점 정보 관리 모달
  const handleOpenBranchInformationModal = (branchSequence: number) => {
    setSelectedBranchSequence(branchSequence);
    onOpenBranchInformationModal();
  };

  // 지점별 세차기 관리 모달
  const handleOpenWashMachineManagementModal = (e: React.MouseEvent, branchSequence: number, branchName: string) => {
    e.stopPropagation();
    setSelectedBranchSequence(branchSequence);
    setSelectedBranchName(branchName);
    onOpenWashMachineManagementModal();
  };

  return (
      <>
        <Table>
          <Thead>
            <Tr>
              {colDefs.map((header, index) => (
                  <Th key={index} minW={header.minWidth} css={tableTh}>
                    {header.label}
                  </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((row, index) => {
              return (
                  <Tr key={index} css={tableTr} cursor={'pointer'} onClick={() => handleOpenBranchInformationModal(row.branchSeq)}>
                    <Td css={tableTd}>{topIndex - index}</Td>
                    <Td css={tableTd}>{branchRegionMap[row.codeRegion]}</Td>
                    <Td css={tableTd}>{row.branchNm}</Td>
                    <Td css={tableTd}>{row.branchOwnerNm}</Td>
                    <Td css={tableTd}>{row.dvcInstallDt ? dayjs(row.dvcInstallDt).format('YYYY-MM-DD') : ''}</Td>
                    <Td css={tableTd}>{row.branchOwnerPhone}</Td>
                    <Td css={[tableTd, { textAlign: 'left' }]}>{row.branchAddr}</Td>
                    <Td css={tableTd}>
                      <Flex justifyContent="center" gap="2px">
                        {userMe?.codeUserAuthority === 'GT1003' && (
                            <Button buttonType="fill" buttonSize={28} onClick={(e) => handleOpenWashMachineManagementModal(e, row.branchSeq, row.branchNm)}>
                              세차기 관리
                            </Button>
                        )}
                        {userMe?.codeUserAuthority === 'GT1003' && <WashMachineLinkModal isLinking={row.branchConnectStatus} branchSeq={row.branchSeq} />}
                      </Flex>
                    </Td>
                  </Tr>
              );
            })}
            {tableData.length === 0 && (
                <Tr bg={colors.grayBlack}>
                  <Td colSpan={colDefs.length} padding="288px 0" color="#fff" fontSize={16} textAlign="center">
                    일치하는 검색 결과가 없습니다.
                  </Td>
                </Tr>
            )}
          </Tbody>
        </Table>
        {/* 지점 정보 관리 모달 */}
        <BranchInformationModal modalMode="view" selectedBranchSequence={selectedBranchSequence ?? 0} isOpenBranchInformationModal={isOpenBranchInformationModal} onCloseBranchInformationModal={onCloseBranchInformationModal} />
        {/* 세차기 관리 모달 */}
        <WashMachineManagementModal branchSequence={selectedBranchSequence ?? 0} branchName={selectedBranchName ?? ''} isOpen={isOpenWashMachineManagementModal} onClose={onCloseWashMachineManagementModal} />
      </>
  );
};

export default BranchManagementListTable;
