import { Flex, Text } from '@chakra-ui/react';
import ModalLayout from 'components/Modals/ModalLayout';
import Button from 'components/Button/Button';

interface Props {
  isOpen: boolean;
  onExit: () => void;
  onCloseExitConfirmationModal: () => void;
}

const ExitConfirmationModal = ({ onExit, isOpen, onCloseExitConfirmationModal }: Props) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onCloseExitConfirmationModal}>
      <Flex flexDirection="column" w="498px" h="262px" bg="#393536">
        {/* modal header */}
        <Flex justifyContent="center" alignItems="center" alignSelf="stretch" h="40px" p="6px 16px">
          <Text fontSize="16px" fontWeight="700" color="white">
            알림
          </Text>
        </Flex>

        {/* modal content */}
        <Flex flexDirection="column" alignItems="center" p="16px" gap="24px" color="white">
          <Text fontSize="20px" fontWeight="700" textAlign="center">
            작성중인 내용이 있습니다.
            <br />
            나가시겠습니까?
          </Text>

          <Text fontSize="14px" fontWeight="400">
            저장하지 않고 페이지를 벗어날 경우, 지금까지 작성한 내용이 사라집니다.
          </Text>
        </Flex>

        {/* modal footer */}
        <Flex justifyContent="center" gap="16px" p="24px 0">
          <Button buttonType="fill" buttonSize={40} onClick={onExit}>
            나가기
          </Button>

          <Button buttonType="outline" buttonSize={40} onClick={onCloseExitConfirmationModal}>
            취 소
          </Button>
        </Flex>
      </Flex>
    </ModalLayout>
  );
};

export default ExitConfirmationModal;
