import { BranchInformationListType, BranchWashCountListType, MachineMonitoring, RemoteControlButtonType } from '../types';

export const branchInformationKeys: Record<string, string> = {
  address: '주소',
  installationDate: '설치일',
  branchManager: '지점장',
  branchManagerPhoneNumber: '지점장 전화번호',
  administratorPhoneNumber: '관리자 전화번호',
  businessRegistrationNumber: '사업자 등록번호',
  catId: 'CAT Id',
  test: 'test',
  test2: 'test2',
  test3: 'test3',
};

export const branchInformationList: BranchInformationListType[] = [
  { name: '주소', value: 'branchAddr' },
  { name: '설치일', value: 'dvcInstallDt' },
  { name: '지점장', value: 'branchOwnerNm' },
  { name: '지점장\n전화번호', value: 'branchOwnerPhone' },
  { name: '관리자\n전화번호', value: 'branchAdminPhone' },
  { name: '사업자\n등록번호', value: 'branchBrn' },
  { name: 'CAT ID', value: 'catId' },
  { name: '태블릿버전', value: 'tabletVersion' },
  { name: 'PLC버전', value: 'plcVersion' },
  { name: '외부아이피', value: 'pubIp' },
  { name: 'DDNS', value: 'ddnsAddr' },
  { name: '지점 KEY', value: 'brancSeq' },
  { name: '메모', value: 'branchMemo' },
];

export const branchWashCountList: BranchWashCountListType[] = [
  { name: '어제', value: 'yesterdayCnt' },
  { name: '이번 주', value: 'weeklyCnt' },
  { name: '이번 달 ', value: 'monthlyCnt' },
  { name: '누적', value: 'totalCnt' },
];

export const branchCumulativeKeys: Record<string, string> = {
  yesterdayData: '어제',
  thisWeekData: '이번주',
  thisMonthData: '이번달',
  cumulativeData: '누적',
};

export const branchOperationData: MachineMonitoring[] = [
  {
    id: 1,
    machine: '세차기 모델명 1',
    status: '운영중',
    counts: {
      today: 10,
      yesterday: 8,
      thisWeek: 50,
      thisMonth: 200,
      total: 1000,
    },
  },
  {
    id: 2,
    machine: '세차기 모델명 2',
    status: '운영중',
    counts: {
      today: 12,
      yesterday: 9,
      thisWeek: 45,
      thisMonth: 210,
      total: 950,
    },
  },
  {
    id: 3,
    machine: '세차기 모델명 3',
    status: '고장',
    counts: {
      today: 0,
      yesterday: 5,
      thisWeek: 30,
      thisMonth: 150,
      total: 800,
    },
  },
  {
    id: 4,
    machine: '세차기 모델명 4',
    status: '운영중',
    counts: {
      today: 15,
      yesterday: 14,
      thisWeek: 60,
      thisMonth: 240,
      total: 1100,
    },
  },
];

export const remoteControlButtons: RemoteControlButtonType[] = [
  {
    name: '뒷문 열기',
    onOff: 'ON',
    apiType: 'exit',
  },
  {
    name: '뒷문 닫기',
    onOff: 'OFF',
    apiType: 'exit',
  },
  {
    name: '앞문 열기',
    onOff: 'ON',
    apiType: 'entrance',
  },
  {
    name: '앞문 닫기',
    onOff: 'OFF',
    apiType: 'entrance',
  },
  {
    name: '리셋',
    apiType: 'reset',
  },
  {
    name: '세차코스 1',
    apiType: 'course',
    course: [1],
  },
  {
    name: '세차코스 2',
    apiType: 'course',
    course: [2],
  },
  {
    name: '세차코스 3',
    apiType: 'course',
    course: [3],
  },
  {
    name: '세차코스 4',
    apiType: 'course',
    course: [4],
  },
  {
    name: '자동모드',
    apiType: 'auto',
  },
];
