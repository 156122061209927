import { InputGroup, Input, InputRightElement, Box, Image } from '@chakra-ui/react';
import { ChangeEvent, useCallback } from 'react';

interface SearchFormProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onClickSearch: () => void;
}

const BranchSearchForm = ({ value, placeholder, onChange, onClickSearch }: SearchFormProps) => {
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onClickSearch();
    },
    [onClickSearch]
  );

  return (
    <form style={{ width: '100%' }} onSubmit={onSubmit}>
      <InputGroup h="40px">
        <Input
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
          placeholder={placeholder}
          bg="white"
          color="black"
          borderRadius={'full'}
          border="none"
          _placeholder={{ color: '#ccc', fontWeight: 700, fontSize: '14px' }}
        />

        <InputRightElement>
          <Box as="button" w="2rem" h="2rem">
            <Image src="/assets/images/search_box_icon.png" alt="Search" w="100%" h="100%" objectFit="cover" cursor="pointer" borderRadius="full" />
          </Box>
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

export default BranchSearchForm;
