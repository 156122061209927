import { useCallback, useEffect, useState } from 'react';
import { Button as ChakraButton, Box, Flex, Radio, RadioGroup, Text, Grid, useDisclosure } from '@chakra-ui/react';
import Button from 'components/Button/Button';
import { BranchWashMachineListItem } from 'features/branchManagement/api/dtos/getBranchWashMachineList.dto';
import useMachineModelTypeOptions from 'features/branchManagement/hooks/useMachineModelTypeOptions';
import usePlcProviderTypeOptions from 'features/branchManagement/hooks/usePlcProviderTypeOptions';
import useMachineTypeOptions from 'features/branchSearch/hooks/useMachineTypeOptions';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import WashMachineItem from './WashMachineItem';
import ConfirmModal from 'components/Modals/ConfirmModal';
import AlertModal from 'components/Modals/AlertModal';
import useRemoveWashMachine from 'features/branchManagement/hooks/useRemoveWashMachine';
import useChangeMachineModelType from 'features/branchManagement/hooks/useChangeMachineModelType';
import useWashMachineEdit from 'features/branchManagement/hooks/useWashMachineEdit';
import type { MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import type { WashMachineUpdateFormValues } from 'features/branchManagement/types/WashMachineEdit.types';

interface Props {
  branchSequence: number;
  branchWashMachineList: BranchWashMachineListItem[];
  onClickViewMode: () => void;
}

const WashMachineEditForm = ({ branchSequence, branchWashMachineList, onClickViewMode: onChangeViewMode }: Props) => {
  const { isOpen: isOpenRequiredFieldsModal, onOpen: onOpenRequiredFieldsModal, onClose: onCloseRequiredFieldsModal } = useDisclosure();
  // Form
  const methods = useForm<WashMachineUpdateFormValues>();
  // 지점 타입
  const [machineModelType, setMachineModelType] = useState<MachineTypeValue>(branchWashMachineList[0].codeType);

  // 항목 추가/ 항목 제거 (삭제 API X)
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'branchWashMachineList',
  });

  // dynamic 필드 초기값 설정
  useEffect(() => {
    if (!branchWashMachineList) return;

    methods.reset({
      branchWashMachineList: branchWashMachineList.map((item) => ({
        branchSeq: item.branchSeq,
        washMachineKey: item.washMachineKey,
        codeType: item.codeType,
        plcPrivider: item.codePlcPrivider,
        codeModel: item.codeModel,
        plcModel: item.plcModel,
        plcVersion: item.plcVersion,
      })),
    });
  }, [branchWashMachineList, methods]);

  // 항목 추가
  const addWashMachine = useCallback(() => {
    append({
      branchSeq: branchSequence,
      washMachineKey: undefined,
      codeType: machineModelType,
      plcPrivider: undefined,
      codeModel: undefined,
      plcModel: undefined,
      plcVersion: '',
    });
  }, [append, branchSequence, machineModelType]);

  // 지점 타입 변경
  const {
    //
    isOpenCodeTypeChangeModal,
    onChangeRadio,
    onConfirmCodeTypeChange,
    onCancelCodeTypeChange,
  } = useChangeMachineModelType({
    fields,
    reset: methods.reset,
    setMachineModelType,
  });

  // 세차기 모델 타입 조회 (일반형 - MT0001 / 터널형 - MT0002 )
  const { machineTypeOptions } = useMachineTypeOptions();
  // 세차기 모델 옵션 조회
  const { machineModelTypeOptions } = useMachineModelTypeOptions(machineModelType);
  // PLC 제조사 옵션 조회
  const { plcProviderTypeOptions } = usePlcProviderTypeOptions();

  // 수정
  const { onSubmit } = useWashMachineEdit({
    machineModelType,
    onOpenRequiredFieldsModal,
    onChangeViewMode,
  });

  // 세차기 삭제 (항목 추가로 추가한 것인지에 따라 삭제를 분기 처리함)
  const {
    //
    isOpenDeleteMachineCheckModal,
    onCloseDeleteMachineCheckModal,
    deleteIndex,
    removeWashMachineCheck,
    removeWashMachine,
  } = useRemoveWashMachine({ remove });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Box m="16px 0" w="full">
          <Flex alignItems="center" w="full" h="40px" borderLeft="3px solid #C30D24">
            <Text fontSize={14} fontWeight={700} color="white" w="160px" p="0 8px">
              지점 타입
            </Text>
            <RadioGroup
              //
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap="35px"
              value={machineModelType}
              onChange={onChangeRadio}
            >
              {machineTypeOptions.map((option) => (
                <Radio key={option.value} value={option.value} flex={1} textAlign="center">
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </Flex>
        </Box>

        <Grid templateColumns="repeat(2, 1fr)" gap="16px" w="full" h="560px">
          {fields.map((field, index) => (
            <WashMachineItem
              //
              key={field.id}
              index={index}
              removeWashMachineCheck={removeWashMachineCheck}
              machineModelTypeOptions={machineModelTypeOptions}
              plcProviderTypeOptions={plcProviderTypeOptions}
            />
          ))}
          {fields.length < 4 && (
            <Flex justifyContent="center" align="center" borderRadius={10} bg="#393536" color="white" w="full" h="272px">
              <ChakraButton type="button" flexDirection="column" gap="8px" w="104px" h="80px" p="17px 24.5px" bg="#6C696A" color="white" onClick={addWashMachine}>
                <img src="/assets/icons/plus.png" alt="plus icons" width={18} height={18} />
                항목 추가
              </ChakraButton>
            </Flex>
          )}
        </Grid>

        <Flex justifyContent="center" gap="16px" p="24px 0">
          <Button type="submit" buttonType="fill" buttonSize={40}>
            저 장
          </Button>
          <Button type="button" buttonType="outline" buttonSize={40} onClick={onChangeViewMode}>
            취 소
          </Button>
        </Flex>
      </form>

      {/* 지점 타입 변경 전 확인 모달 */}
      <ConfirmModal
        isOpen={isOpenCodeTypeChangeModal}
        title={'지점 타입을 변경하시겠습니까?'}
        description="지점 타입을 변경하면 이전에 저장된 내용이 초기화됩니다."
        confirmText="확인"
        closeText="취소"
        onConfirm={onConfirmCodeTypeChange}
        onClose={onCancelCodeTypeChange}
      />
      {/* 세차기 삭제 전 확인 모달 */}
      <ConfirmModal
        isOpen={isOpenDeleteMachineCheckModal}
        title={`[ 세차기 ${deleteIndex! + 1} ] 를 삭제하시겠습니까?`}
        description={'삭제된 정보는 복구할 수 없습니다.\n삭제하시겠습니까?'}
        confirmText="확인"
        closeText="취소"
        onConfirm={typeof deleteIndex === 'number' && fields[deleteIndex]?.washMachineKey ? removeWashMachine(fields[deleteIndex].washMachineKey) : () => {}}
        onClose={onCloseDeleteMachineCheckModal}
      />
      {/* 세차기 미입력 안내 */}
      <AlertModal title="세차기 정보를 입력해주세요." isOpen={isOpenRequiredFieldsModal} onClose={onCloseRequiredFieldsModal} />
    </FormProvider>
  );
};

export default WashMachineEditForm;
