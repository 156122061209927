/** @jsxImportSource @emotion/react */

import { useCallback } from 'react';
import { inputStyle, inputWrapper, searchIconWrapper } from './AddressInput.styles';
import { useDaumPostcodePopup, type Address } from 'react-daum-postcode';

const postCodeScriptUrl = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

interface AddressInputProps {
  placeholder?: string;
  disabled?: boolean;
  onComplete: (address: string) => void;
  address: string | undefined;
}

const AddressInput = ({ placeholder, disabled, address, onComplete }: AddressInputProps) => {
  const openDaumPostcodePopup = useDaumPostcodePopup(postCodeScriptUrl);

  const handleComplete = useCallback(
    (data: Address) => {
      let address = data.address;
      let extraAddress = '';

      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        address += extraAddress !== '' ? ` (${extraAddress})` : '';
      }

      onComplete(address); // Update form state with the complete address
    },
    [onComplete]
  );

  const onClickFindPostCode = useCallback(() => {
    if (!disabled) {
      openDaumPostcodePopup({
        onComplete: handleComplete,
        popupTitle: '주소검색',
        width: 500,
        height: 500,
        top: (window.outerHeight - 500) / 2,
        left: (window.innerWidth - 500) / 2,
      });
    }
  }, [openDaumPostcodePopup, handleComplete, disabled]);

  return (
    <div css={[inputWrapper, disabled && { cursor: 'not-allowed' }]} onClick={onClickFindPostCode}>
      <input type="text" css={inputStyle} readOnly placeholder={placeholder} value={address} disabled={disabled} />
      <div css={searchIconWrapper}>
        <img src="/assets/icons/search_icon.png" alt="search icon" width={30} height={30} />
      </div>
    </div>
  );
};

export default AddressInput;
