import { useDisclosure } from '@chakra-ui/react';
import type { MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import { useCallback, useState } from 'react';
import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import type { BranchWashMachineInitialUpdateFormSchema } from '../schema/branchWashMachineForm.schema';

interface FormValues {
  branchWashMachineList: BranchWashMachineInitialUpdateFormSchema[];
}

interface UseChangeMachineModelTypeParams {
  fields: UseFieldArrayReturn<FormValues, 'branchWashMachineList', 'id'>['fields'];
  reset: UseFormReturn<FormValues>['reset'];
  setMachineModelType: (machineModelType: MachineTypeValue) => void;
}

export default function useChangeMachineModelType({ fields, reset, setMachineModelType }: UseChangeMachineModelTypeParams) {
  const [pendingMachineModelType, setPendingMachineModelType] = useState<MachineTypeValue | null>(null);

  const { isOpen: isOpenCodeTypeChangeModal, onOpen: onOpenCodeTypeChangeModal, onClose: onCloseCodeTypeChangeModal } = useDisclosure();

  // 지점 타입 변경
  const onChangeRadio = useCallback(
    (nextValue: string) => {
      setPendingMachineModelType(nextValue as MachineTypeValue);
      onOpenCodeTypeChangeModal();
    },
    [onOpenCodeTypeChangeModal]
  );

  // 지점 타입 변경 시 필드 초기화
  const clearValues = useCallback(() => {
    const clearedValues = fields.map((field) => ({
      ...field,
      codeModel: undefined,
      plcPrivider: undefined,
      plcModel: undefined,
      plcVersion: '',
    }));

    reset({ branchWashMachineList: clearedValues });
  }, [fields, reset]);

  // 지점 타입 변경 확인
  const onConfirmCodeTypeChange = useCallback(() => {
    if (pendingMachineModelType) {
      setMachineModelType(pendingMachineModelType);
    }
    clearValues();
    onCloseCodeTypeChangeModal();
  }, [clearValues, onCloseCodeTypeChangeModal, pendingMachineModelType, setMachineModelType]);

  // 지점 타입 변경 취소3
  const onCancelCodeTypeChange = useCallback(() => {
    setPendingMachineModelType(null);
    onCloseCodeTypeChangeModal();
  }, [onCloseCodeTypeChangeModal]);

  return {
    isOpenCodeTypeChangeModal,
    onChangeRadio,
    onConfirmCodeTypeChange,
    onCancelCodeTypeChange,
  };
}
