import { GroupBase, OptionProps, components } from 'react-select';
// import * as FeatherIcons from 'react-feather';
import { SelectOption } from '../types/select.types';

export default function CustomSelectOption<Option extends SelectOption, IsMulti extends boolean, Group extends GroupBase<Option>>(props: OptionProps<Option, IsMulti, Group>) {
  return (
    <components.Option {...props}>
      {props.label}
      {/* {props.isSelected ? <FeatherIcons.Check width={20} height={20} /> : null} */}
    </components.Option>
  );
}
