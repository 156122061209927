import { PlcProviderTypeCode, PlcProviderTypeLabel } from '../api/dtos/getPlcProviderTypeList.dto';
import useGetPlcProViderTypeList from './react-query/useGetPlcProviderTypeList';
import { useMemo } from 'react';

export interface PlcProviderTypeOption {
  label: PlcProviderTypeLabel;
  value: PlcProviderTypeCode;
}

export default function usePlcProviderTypeOptions() {
  const { data } = useGetPlcProViderTypeList();

  const plcProviderTypeOptions = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }));
  }, [data]);

  return { plcProviderTypeOptions };
}
