import { useMemo } from 'react';
import useGetBranchRegionList from './react-query/useGetBranchRegionList';
import type { BranchRegionLabel, BranchRegionValue } from '../api/dtos/getBranchRegionList.dto';

type BranchRegionMap = Record<BranchRegionValue, BranchRegionLabel>;

export default function useBranchRegionMap() {
  const { data } = useGetBranchRegionList();

  const branchRegionMap = useMemo(() => {
    if (!data) return {} as BranchRegionMap;

    return data.reduce((acc, item) => {
      acc[item.code] = item.codeValue;
      return acc;
    }, {} as BranchRegionMap);
  }, [data]);

  return { branchRegionMap };
}
