import { useQuery } from '@tanstack/react-query';
import { getWashMachineListApi } from 'features/branchManagement/api/washMachine';

const WASH_MACHINE_LIST = 'WASH_MACHINE_LIST';

export default function useGetWashMachineList(branchSeq: number) {
  return useQuery({
    queryKey: [WASH_MACHINE_LIST, { branchSeq }],
    queryFn: () => getWashMachineListApi(branchSeq),
    enabled: !!branchSeq,
  });
}
