import { css } from '@emotion/react';

export const containerCss = {
  datepickerContainer: css`
    right: 0;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    border: 2px solid var(--red-normal--, #c30d24);
    background: var(--grey-400, #393536);
  `,

  datepickerTitle: css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Padding-8, 8px);
    margin: 0 64.5px 8px;
    padding: 8px 24px;

    border-bottom: 2px solid var(--red-normal--, #c30d24);

    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
};

export const InputCss = {
  inputWrapper: css`
    position: relative;
    width: 100%;
    height: 40px;
    padding-right: 30px;
    border-radius: 5px;
    cursor: pointer;

    overflow: hidden;
  `,

  inputStyle: css`
    pointer-events: none;
    width: 100%;
    height: 100%;
    padding: 0px var(--Padding-4, 4px);

    border: none;
    background: #fff;

    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    ::placeholder {
      color: #989898;
      font-weight: 400;
    }

    &:disabled {
      color: #989898;
      font-weight: 400;
    }
  `,

  imageWrapper: css`
    position: absolute;
    right: 0px;
    top: 0;
    background: #393536;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  `,

  imageStyle: css`
    width: 25px;
    height: 25px;
  `,
};

export const headerCss = css`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  background: var(--grey-300, #6c696a);

  color: #fff;
  text-align: center;
  font-family: 'Noto Sans KR';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  button {
    display: flex;
    width: 42px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
`;

export const datepickerCss = css`
  width: 100%;
  height: 40px;

  /* 데이트 피커 팝업 위치 설정 */
  .react-datepicker-popper {
    transform: translateX(calc(-100% + 40px)); /* 오른쪽 정렬에 맞춰 위치 조정 */
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  /* 달력에서 현재 월 외의 날짜 투명도 설정 */
  .react-datepicker__day--outside-month {
    opacity: 0;
  }

  .react-datepicker__header {
    padding: 0;
  }

  /* 달력 삼각형 표시 숨기기 */
  .react-datepicker__triangle {
    display: none;
  }

  /* 달력 라운드 */
  .react-datepicker__month-container {
    border-radius: 8px;
    overflow: hidden;
  }

  /* 달력 월 배경 색상 및 마진, 패딩 설정 */
  .react-datepicker__month {
    background: #231f20;
    margin: 0;
    padding: 0.4rem;
  }

  /* 현재 월 텍스트 색상 및 패딩 설정 */
  .react-datepicker__current-month {
    color: #fff;
    padding-bottom: 8.5px;
  }

  /* 요일 이름 배경 색상 설정 */
  .react-datepicker__day-names {
    background: #231f20;
  }

  /* 요일 이름 텍스트 색상 설정 */
  .react-datepicker__day-name {
    color: #fff;
  }

  /* 날짜 텍스트 색상 설정 */
  .react-datepicker__day {
    color: #fff;
  }

  /* 날짜 호버 시 테두리 반경 설정 */
  .react-datepicker__day:hover {
    border-radius: 50px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: #c30d24; /* 선택된 날짜 배경 색상 */
    color: #ffffff; /* 선택된 날짜 텍스트 색상 */
    border-radius: 50px;
  }

  .react-datepicker__day:hover {
    background: #ffdddd; /* 날짜 호버 배경 색상 */
  }

  /* 오늘 날짜 포커스 상태 스타일링 */
  .react-datepicker__day--today:focus {
    outline: none; /* 기본 포커스 아웃라인 제거 */
  }
`;
