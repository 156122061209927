import { z } from 'zod';

const branchTypeValues = ['BT01', 'BT04', 'BT03', 'BT02'] as const;
const branchRegionValues = ['KR00011', 'KR00031', 'KR00023', 'KR00032', 'KR00033', 'KR00034', 'KR00025', 'KR00029', 'KR00024', 'KR00035', 'KR00036', 'KR00037', 'KR00038', 'KR00022', 'KR00021', 'KR00026', 'KR00039'] as const;

export const branchInformationUpdateSchema = z.object({
  branchNm: z.string().min(1),
  branchOwnerNm: z.string().optional(),
  branchBrn: z.string().optional(),
  ownerPhone1: z.string().max(3).optional(),
  ownerPhone2: z.string().max(4).optional(),
  ownerPhone3: z.string().max(4).optional(),
  adminPhone1: z.string().max(3).optional(),
  adminPhone2: z.string().max(4).optional(),
  adminPhone3: z.string().max(4).optional(),
  branchMemo: z.string().optional(),
  branchImgSeq: z.number().optional(),
  dvcInstallDt: z.string().optional(),
  catId: z.string().optional(),
  tabletVersion: z.string().optional(),
  plcVersion: z.string().optional(),
  pubIp: z.string().optional(),
  ddnsAddr: z.string().optional(),
  branchAddr: z.string().min(1), // 필수값
  gpsLat: z.number(), //  필수값
  gpsLng: z.number(), //  필수값
});

export const branchInformationCreateSchema = branchInformationUpdateSchema.extend({
  codeType: z.enum(branchTypeValues), // 필수값
  codeRegion: z.enum(branchRegionValues), // 필수값
  branchAddr: z.string().min(1), // 필수값
  gpsLat: z.number(), //  필수값
  gpsLng: z.number(), //  필수값
});

export type BranchInformationCreateSchema = z.infer<typeof branchInformationCreateSchema>;
export type BranchInformationUpdateSchema = z.infer<typeof branchInformationUpdateSchema>;
