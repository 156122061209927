/** @jsxImportSource @emotion/react */
import type { Interpolation, Theme } from '@emotion/react';
import { useMemo } from 'react';
import { buttonCss } from './Button.styles';

export type ButtonType = 'fill' | 'outline';
export type ButtonSize = 28 | 36 | 40 | 51 | 56;
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  buttonSize: ButtonSize;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  cssStyle?: Interpolation<Theme>;
  capsule?: boolean;
}

export default function Button({ buttonType, buttonSize = 36, leftIcon, rightIcon, children, cssStyle, capsule = false, ...restProps }: ButtonProps) {
  const buttonStyle = useMemo(() => buttonCss.button(buttonType, capsule, buttonSize), [buttonType, capsule, buttonSize]);

  return (
    <button {...restProps} css={[buttonStyle, cssStyle]}>
      {leftIcon}
      {children}
      {rightIcon}
    </button>
  );
}
