import { useCallback } from 'react';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import useTranslateBranchStatus from '../hooks/useTranslateBranchStatus';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import { css } from '@emotion/react';
import type { BranchListItemForMain } from '../api/dtos/getBranchListForMain.dto';
import { STORAGE_URL } from 'common/constants';
import useSelectBranch from 'hooks/useSelectBranch';
import { useRecoilValue } from 'recoil';
import { selectedBranchSeqState } from 'atoms/googleMap';

interface BranchListProps {
  branchList: BranchListItemForMain[];
}

const BranchList = ({ branchList }: BranchListProps) => {
  const { translateBranchStatusLabel, translateBranchStatusColor } = useTranslateBranchStatus();
  const { handleBranchClick } = useSelectBranch();
  const selectedBranchSeq = useRecoilValue(selectedBranchSeqState);

  const onBranchClick = useCallback(
    (branch: BranchListItemForMain) => () => {
      handleBranchClick({
        branchSeq: branch.branchSeq,
        isScroll: false,
        name: branch.branchNm,
        position: {
          lat: branch.gpsLat,
          lng: branch.gpsLng,
        },
      });
    },
    [handleBranchClick]
  );

  return (
    <Box flex={1} overflowY={'auto'}>
      <Scrollbar
        id="branchList-scroll-container"
        css={css`
          height: 100%;
          overflow-y: auto;
          position: relative;
        `}
      >
        {branchList.map((office) => (
          <Flex
            id={`branch-${office.branchSeq}`}
            onClick={onBranchClick(office)}
            key={office.branchSeq}
            h="86px"
            margin={'8px 16px 0 0'}
            borderBottom={'2px solid lightgray'}
            cursor={'pointer'}
            css={css`
              ${selectedBranchSeq === office.branchSeq
                ? `
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0.00) 100%);
                border-left: 8px solid #C30D24;
                border-bottom: 2px solid #C30D24;
              `
                : ''}
            `}
          >
            <Stack p={'4px 8px'} flex={1} color={'white'} gap={'36px'}>
              <Flex justifyContent={'space-between'}>
                <Text fontSize={16} fontWeight={700}>
                  {office.branchNm}
                </Text>
                <Flex alignItems={'center'} fontSize={14} fontWeight={700} gap="4px">
                  <Box w="10px" h="10px" borderRadius={'full'} bg={translateBranchStatusColor(office.branchStatus)} />
                  <Text color={translateBranchStatusColor(office.branchStatus)} fontSize={'10px'}>
                    {translateBranchStatusLabel(office.branchStatus)}
                  </Text>
                </Flex>
              </Flex>

              <Flex justifyContent={'space-between'} fontSize={12} fontWeight={700}>
                <Text>금일 가동 횟수 집계</Text>
                <Text>{office.branchTodayCnt.toLocaleString()}</Text>
              </Flex>
            </Stack>

            {office.branchImgPath && <Image src={`${STORAGE_URL}${office.branchImgPath}`} alt="office image" width="84px" height="84px" />}
            {!office.branchImgPath && (
              <Stack padding={'8px'}>
                {/*<Image src="/assets/images/default_img.png" alt="office image" width="68px" height="68px" />*/}
                <Image src="/assets/images/logo-img-red.png" alt="office image" width="68px" height="68px" />
              </Stack>
            )}
          </Flex>
        ))}
      </Scrollbar>
    </Box>
  );
};

export default BranchList;
