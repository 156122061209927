import { useMemo } from 'react';
import useGetMachineTypeList from './react-query/useGetMachineTypeList';
import type { MachineTypeLabel, MachineTypeValue } from '../api/dtos/getMachineTypeList.dto';

type MachineTypeMap = Record<MachineTypeValue, MachineTypeLabel>;

export default function useMachineTypeMap() {
  const { data } = useGetMachineTypeList();

  const machineTypeMap = useMemo(() => {
    if (!data) return {} as MachineTypeMap;

    return data.reduce((acc, item) => {
      acc[item.code] = item.codeValue;
      return acc;
    }, {} as MachineTypeMap);
  }, [data]);

  return { machineTypeMap };
}
