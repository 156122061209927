import { api } from 'api/api';
import { WashMachineControllerRequestParameterType, WashMachineUpdateRequestParameterType } from '../types';
import type { BaseResponse } from 'api/api.types';
import { GetWashSensorListResponseDto } from './dtos/getWashSensorList.dto';
import type { GetWashMachineInfoResponseDto } from './dtos/getWashMachineInfo.dto';

// 세차기 리스트 조회
export const getWashMachineList = async (branchSeq: string) => {
  try {
    const response = await api.get(`/mac/GetWashMachineList/${branchSeq}`);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
// 세차기 정보 조회
export const getWashMachine = async (washMachineKey: string) => {
  const { data } = await api.get<BaseResponse<GetWashMachineInfoResponseDto>>(`/mac/GetWashMachineInfo/${washMachineKey}`);
  return data.data;
};

// 센서데이터 리스트 조회
export const getWashSensorListApi = async (washMachineKey: string) => {
  try {
    const response = await api.get<BaseResponse<GetWashSensorListResponseDto>>(`/mac/GetWashSensorList/${washMachineKey}`);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

// 세차기 제어
export const updateWashMachineController = async (data: WashMachineControllerRequestParameterType) => {
  try {
    const response = await api.post('/mac/WashMachineController', data);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

// 세차기 섬네일 수정
export const updateWashMachine = async (data: WashMachineUpdateRequestParameterType) => {
  try {
    const response = await api.put('/mac/UpdWashMachine', data);
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
