import { Flex, Text, Image, useDisclosure } from '@chakra-ui/react';
import { colors } from 'theme';
import Title from 'components/Title/Title';
import AppLayoutContainer from 'containers/AppLayoutContainer';
import BranchManagementListTable from 'features/branchManagement/components/BranchManagementListTable/BranchManagementListTable';
import { branchManagementColDefsData } from 'features/branchManagement/data/branchManagementColDefs.data';
import useGetBranchManagementList from 'features/branchManagement/hooks/react-query/useGetBranchManagementList';
import BranchManagementSearchForm from 'features/branchManagement/components/BranchManagementSearchForm';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { BranchManagementOptionValue } from 'features/branchManagement/api/dtos/getBranchManagementList.dto';
import BranchInformationModal from 'features/branchManagement/components/Modal/BranchInformationModal/BranchInformationModal';
import { useMemo } from 'react';
import useSearchBranchManagement from 'features/branchManagement/hooks/useSearchBranchManagement';
import Button from 'components/Button/Button';
import { branchSearchOptions } from 'features/branchManagement/data/BranchSearchOptions.data';
import Select from 'components/Select/Select';
import CustomPagination from 'components/Pagination/CustomPagination';
import useCustomPagination from 'components/Pagination/hooks/useCustomPagination';

const BranchManagementPage = () => {
  const [page] = useQueryParam('page', withDefault(NumberParam, 1));
  const { isOpen: isOpenBranchInformationModal, onOpen: onOPenBranchInformationModal, onClose: onCloseBranchInformationModal } = useDisclosure();

  // 검색
  const { searchType, onChangeSearchType, keyword, onChangeKeyword, onSearch, keywordType, searchVal } = useSearchBranchManagement();

  // 지점 관리 목록 조회
  const { data: branchManagementListResponse } = useGetBranchManagementList({
    keywordType: (keywordType as BranchManagementOptionValue) || branchSearchOptions[0].value,
    searchVal,
    page,
  });
  const totalCount = useMemo(() => branchManagementListResponse?.totalCount ?? 0, [branchManagementListResponse?.totalCount]);
  const branchManagementList = useMemo(() => branchManagementListResponse?.resData ?? [], [branchManagementListResponse]);

  const { onPreviousPage, onNextPage, onClickPage } = useCustomPagination({ totalPages: branchManagementListResponse?.totalPages ?? 0 });


  return (
      <AppLayoutContainer>
        <Flex flexDirection="column" gap="24px" bg="#6C696A" w="full" h="full" p="16px" overflowY={'auto'}>
          <Title title={'지점 관리'}/>

          <Flex flexDirection="column" alignContent="center" gap="24px" flex={1} maxW={'1200px'} margin={'0 auto'}>
            {/* search */}
            <Flex flexDirection="column" gap="16px">
              <Flex alignContent="center" gap="16px" w="full" h="40px">
                <Text fontSize={14} fontWeight={700} color={colors.white} alignContent="center" whiteSpace="nowrap">
                  검색 내용
                </Text>
                <Select
                    //
                    options={branchSearchOptions}
                    placeholder="전체 항목"
                    value={searchType}
                    onChange={onChangeSearchType}
                    cssStyle={{width: '200px'}}
                />
                <BranchManagementSearchForm placeholder="검색할 내용을 입력해주세요" value={keyword} onChange={onChangeKeyword}
                                            onClickSearch={onSearch}/>
              </Flex>

              {searchVal.length > 0 && (
                  <Flex justifyContent="center" alignItems="center" bg="#231F20" gap="8px" h="48px"
                        borderBottom="3px solid #C30D24" fontSize={18} fontWeight={700} color="white">
                    <Text>“</Text>
                    <Text>{searchVal}</Text>
                    <Text>“</Text>
                    <Text>검색결과</Text>
                  </Flex>
              )}
            </Flex>

            {/* table */}
            <Flex flexDirection="column" alignContent="center" gap="8px" paddingBottom="16px" flex={1}>
              <Flex justifyContent="flex-end">
                <Button buttonType="fill" buttonSize={36} capsule onClick={onOPenBranchInformationModal}
                        cssStyle={{width: '123px', fontWeight: '700'}}
                        leftIcon={<Image src="/assets/icons/write.png" alt="write_icon" boxSize="28px"/>}>
                  지점 등록
                </Button>

                <BranchInformationModal selectedBranchSequence={0} modalMode="create"
                                        isOpenBranchInformationModal={isOpenBranchInformationModal}
                                        onCloseBranchInformationModal={onCloseBranchInformationModal}/>
              </Flex>

              <BranchManagementListTable
                  colDefs={branchManagementColDefsData}
                  tableData={branchManagementList}
                  topIndex={totalCount - (page - 1) * 15}
              />
              {branchManagementListResponse && (
                  <CustomPagination
                      //
                      totalPages={branchManagementListResponse.totalPages}
                      currentPage={page}
                      onPreviousPage={onPreviousPage}
                      onNextPage={onNextPage}
                      onClickPage={onClickPage}
                      cssStyle={{width: 'fit-content', margin: '0 auto', marginTop: 24}}
                  />
              )}
            </Flex>
          </Flex>
        </Flex>
      </AppLayoutContainer>
  );
};

export default BranchManagementPage;
