import { Box, FormControl, FormLabel, Input, Flex, Text, Image } from '@chakra-ui/react';
import { LoginWrapper, LoginSubWrapper, FormWrapper, loginButton } from './LoginForm.styles';
import { useForm } from 'react-hook-form';
import { LoginFormValues } from '../../types';
import Button from 'components/Button/Button';

interface LoginFormProps {
  onSubmit: (data: LoginFormValues) => void;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { register, handleSubmit } = useForm<LoginFormValues>();

  return (
    <LoginWrapper>
      <LoginSubWrapper>
        <FormWrapper>
          <Box mb="96px" color={'#fff'}>
            <Image src="/assets/images/logo.png" alt="logo" maxW={'430px'} w="85%" m="0 auto" borderRadius="md" boxShadow="md" />
          </Box>
          <Box w={'340px'}>
            <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onSubmit={handleSubmit(onSubmit)}>
              <FormControl w="100%" marginBottom={'16px'}>
                <Flex direction="row" align="center" justifyContent={'center'}>
                  <FormLabel color={'#fff'} w={'60px'} fontWeight={700} textAlign={'center'} margin={'0'}>
                    ID
                  </FormLabel>
                  <Input id="userId" flex={1} h="40px" borderRadius="full" bg="white" {...register('userId', { required: 'ID is required' })} />
                </Flex>
              </FormControl>

              <FormControl w="100%">
                <Flex direction="row" align="center" justifyContent={'center'}>
                  <FormLabel color={'#fff'} w={'60px'} fontWeight={700} textAlign={'center'} margin={'0'}>
                    PW
                  </FormLabel>
                  <Input id="userPassword" type="password" flex={1} h="40px" borderRadius="full" bg="white" {...register('userPassword', { required: 'Password is required' })} />
                </Flex>
              </FormControl>
              <Button buttonSize={51} buttonType="fill" cssStyle={loginButton}>
                L O G I N
              </Button>
              <Text mt={'8px'} fontSize="xs" color={'white'}>
                Copyright© Granco. All Rights Reserved
              </Text>
            </form>
          </Box>
        </FormWrapper>
      </LoginSubWrapper>
    </LoginWrapper>
  );
};

export default LoginForm;
