import { useMemo } from 'react';
import useGetAlarmTypeList from './react-query/useGetAlarmTypeList';
import type { AlarmTypeLabel, AlarmTypeValue } from '../api/dtos/getAlarmTypeList.dto';

export interface AlarmTypeOption {
  label: AlarmTypeLabel;
  value: AlarmTypeValue;
}

export default function useGetAlarmTypeOptions() {
  const { data } = useGetAlarmTypeList();

  const alarmTypeOptions = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }) as AlarmTypeOption);
  }, [data]);

  return { alarmTypeOptions };
}
