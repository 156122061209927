import { useMemo } from 'react';
import useGetMachineModelTypeList from './react-query/useGetMachineModelTypeList';
import { MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';
import { MAchineModelTypeLabel, MachineModelTypeCode } from '../api/dtos/getMachineModelTypeList.dto';

type MachineModelTypeMap = Record<MachineModelTypeCode, MAchineModelTypeLabel>;

export default function useMachineModelTypeMap(modelType: MachineTypeValue) {
  const { data } = useGetMachineModelTypeList(modelType);

  const machineModelTypeMap = useMemo(() => {
    if (!data) return {} as MachineModelTypeMap;

    return data.reduce((acc, item) => {
      acc[item.code] = item.codeValue;
      return acc;
    }, {} as MachineModelTypeMap);
  }, [data]);

  return { machineModelTypeMap };
}
