import { useMemo } from 'react';
import useGetMachineTypeList from './react-query/useGetMachineTypeList';
import type { MachineTypeLabel, MachineTypeValue } from '../api/dtos/getMachineTypeList.dto';

export interface MachineTypeOption {
  label: MachineTypeLabel;
  value: MachineTypeValue;
}

export default function useMachineTypeOptions() {
  const { data } = useGetMachineTypeList();

  const machineTypeOptions = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }) as MachineTypeOption);
  }, [data]);

  return { machineTypeOptions };
}
