import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getBranchManagementListApi } from 'features/branchManagement/api/branchManagement';
import { BranchManagementSearchParams } from 'features/branchManagement/types/BranchManagementSearch.types';

export const BRANCH_MANAGEMENT_LIST = 'BRANCH_MANAGEMENT_LIST';
const BRANCH_PAGE_SIZE = 15;

export default function useGetBranchManagementList({ keywordType, searchVal, page }: BranchManagementSearchParams) {
  return useQuery({
    queryKey: [BRANCH_MANAGEMENT_LIST, { page }, { keywordType }, { searchVal }],
    queryFn: () =>
      getBranchManagementListApi({
        keywordType,
        searchVal: searchVal ? searchVal : undefined,
        page,
        size: BRANCH_PAGE_SIZE,
      }),
    enabled: !!keywordType,
    placeholderData: keepPreviousData,
  });
}
