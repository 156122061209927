import { useQuery } from '@tanstack/react-query';
import { getBranchWashMachineListApi } from 'features/branchManagement/api/washMachine';

export const BRANCH_WASH_MACHINE_LIST = 'BRANCH_WASH_MACHINE_LIST';

export default function useGetBranchWashMachineList(branchSeq: number) {
  return useQuery({
    queryKey: [BRANCH_WASH_MACHINE_LIST, { branchSeq }],
    queryFn: () => getBranchWashMachineListApi(branchSeq),
    enabled: !!branchSeq,
  });
}
