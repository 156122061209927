import { useMemo } from 'react';
import useGetBranchTypeList from './react-query/useGetBranchTypeList';
import { BranchTypeLabel, BranchTypeValue } from '../api/dtos/getBranchTypeList.dto';

export type BranchTypeOptionLabel = '전체' | BranchTypeLabel;
export type BranchTypeOptionValue = 'all' | BranchTypeValue;
export interface BranchTypeOption {
  label: BranchTypeOptionLabel;
  value: BranchTypeOptionValue;
}

export default function useBranchTypeOptions() {
  // 지점 조회
  const { data } = useGetBranchTypeList();

  const branchTypeOptionsWithoutAll = useMemo(() => {
    if (!data) return [];

    return data.map((item) => ({ label: item.codeValue, value: item.code }) as BranchTypeOption);
  }, [data]);

  const branchTypeOptions = useMemo(() => {
    if (!data) return [];

    const allOption = { label: '전체', value: 'all' } as BranchTypeOption;
    const options = data.map((item) => ({ label: item.codeValue, value: item.code }) as BranchTypeOption);

    return [allOption, ...options];
  }, [data]);

  return { branchTypeOptionsWithoutAll, branchTypeOptions };
}
