import { useMemo } from 'react';
import { PlcModelTypeCode, PlcModelTypeLabel } from '../api/dtos/getPlcModelTypeList.dto';
import useGetPlcModelTypeList from './react-query/useGetPlcModelTypeList';

type PlcModelTypeMap = Record<PlcModelTypeCode, PlcModelTypeLabel>;

export default function usePlcModelTypeMap() {
  const simenseData = useGetPlcModelTypeList('simense').data;
  const lsData = useGetPlcModelTypeList('ls').data;

  const plcModelTypeMap = useMemo(() => {
    const combinedData = [...(simenseData || []), ...(lsData || [])];

    if (!combinedData) return {} as PlcModelTypeMap;

    return combinedData.reduce((acc, item) => {
      acc[item.code] = item.codeValue;
      return acc;
    }, {} as PlcModelTypeMap);
  }, [simenseData, lsData]);

  return { plcModelTypeMap };
}
