/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { buttonStyle } from './LinkButton.styles';
import { BranchConnectStatusValue } from 'features/branchManagement/api/dtos/getWashMachineList.dto';

interface Props {
  children: ReactNode;
  isLinking: BranchConnectStatusValue;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LinkButton = ({ children, isLinking, onClick }: Props) => {
  return (
    <button css={[buttonStyle, { background: isLinking === 'WCS0001' ? '#72000e' : '#231F20' }]} onClick={onClick}>
      {children}
    </button>
  );
};

export default LinkButton;
