import { css } from '@emotion/react';

export const tableTh = css`
  align-content: center;
  text-align: center;
  height: 40px;
  border-right: 0.3px solid var(--g-100, #f9f9f9);
  background: #231f20;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;

  &:last-child {
    border-right: none;
  }
`;

export const tableTr = css`
  border-bottom: 0.3px solid white;
  background: #393536;

  &:hover {
    background: #4d494a;
  }
`;

export const tableTd = css`
  height: 40px;
  margin: 0;
  padding: 0 16px;
  border: 0;
  border-right: 0.3px solid white;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;

  &:last-child {
    border-right: none;
  }
`;
