import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateWashMachineConnectApi } from 'features/branchManagement/api/washMachine';
import { BRANCH_MANAGEMENT_LIST } from './useGetBranchManagementList';
import type { GetBranchManagementListResponseDto } from 'features/branchManagement/api/dtos/getBranchManagementList.dto';
import { produce } from 'immer';

interface UseUpdateWashMachineConnectParams {
  branchSeq: number;
  onOpenLinkSuccessModal: () => void;
  onOpenLinkFailedModal: () => void;
}

export default function useUpdateWashMachineConnect({ branchSeq, onOpenLinkSuccessModal, onOpenLinkFailedModal }: UseUpdateWashMachineConnectParams) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateWashMachineConnectApi,
    onSuccess: () => {
      queryClient.setQueriesData<GetBranchManagementListResponseDto>({ queryKey: [BRANCH_MANAGEMENT_LIST] }, (oldData) =>
        produce(oldData, (draft) => {
          if (!draft) return draft;

          const targetBranch = draft.resData.find((branch) => branch.branchSeq === branchSeq);
          if (!targetBranch) return draft;

          // WCS0001: 연동중, WCS0002: 연동안됨
          targetBranch.branchConnectStatus = 'WCS0001';
          targetBranch.branchConnectStatusNm = '연동중';
        })
      );

      // 성공 모달 열기
      onOpenLinkSuccessModal();
    },
    onError: () => {
      // 실패 모달 열기
      onOpenLinkFailedModal();
    },
  });
}
