/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { scrollbarStyles } from './Scrollbar.styles';

interface ScrollbarProps {
  children: ReactNode;
  css?: SerializedStyles;
  [key: string]: any;
}

const Scrollbar = ({ children, css: restStylesProps, ...rest }: ScrollbarProps) => {
  return (
    <div
      css={css`
        ${scrollbarStyles}
        ${restStylesProps}
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Scrollbar;
