import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { colors } from 'theme';
import { branchListLoadingCss } from '../styles/branchListLoading.styles';
import { scrollbarStyles } from 'components/Scrollbar/Scrollbar.styles';
import type { TransformedBranch } from '../hooks/useInfiniteBranchStatusDetailList';

interface BranchStatusDetailTableProps {
  tableHeader: { label: string; minWidth: string }[];
  tableBody: TransformedBranch[];
  onRowClick?: (id: number) => void;
  isFetching: boolean;
  hasNextPage: boolean;
  observerRef: (node?: Element | null) => void;
}

const BranchStatusDetailTable = ({ tableHeader, tableBody, onRowClick, isFetching, hasNextPage, observerRef }: BranchStatusDetailTableProps) => {
  return (
    <TableContainer bg={colors.grayBlack} css={scrollbarStyles} h={'100%'} paddingBottom={'16px'} overflowY={'auto'}>
      <Table variant="simple" size={'sm'} w="100%" overflowY={'auto'} style={{ borderCollapse: 'collapse' }}>
        <Thead h="36px">
          <Tr>
            {tableHeader.map((header, index) => (
              <Th key={index} minW={header.minWidth} color="white" fontSize={'14px'} fontWeight={700} lineHeight={'20.27px'} textAlign="center" verticalAlign="middle" borderRight={`1px solid #F9F9F9`} _last={{ borderRight: 'none' }}>
                {header.label}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody color={'#fff'} overflowY={'auto'}>
          {tableBody?.length > 0 &&
            tableBody.map((item, innerIndex) => (
              <Tr key={innerIndex} bg={colors.gray} cursor={onRowClick && 'pointer'} onClick={() => onRowClick && onRowClick(item.brancSeq)} height={'40px'} _hover={{ bg: '#4D494A' }}>
                {Object.entries(item).map(([key, value]) => {
                  return (
                    <Td key={key} padding={'0'} textAlign="center" fontSize={'12px'} fontWeight={700} lineHeight={'17.38px'} verticalAlign={'middle'} borderRight={`1px solid #F9F9F9`} _last={{ borderRight: 'none' }}>
                      {key === 'brancSeq' ? innerIndex + 1 : value}
                    </Td>
                  );
                })}
              </Tr>
            ))}

          {tableBody?.length === 0 && (
            <Tr bg={colors.grayBlack}>
              <Td colSpan={tableHeader.length} padding="288px 0" color="#fff" fontSize={16} textAlign="center">
                일치하는 검색 결과가 없습니다.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {isFetching ? <LoadingSpinner cssStyle={branchListLoadingCss.block} /> : hasNextPage && <Box h={'114px'} />}
      <Box w={'0px'} h={'0px'} opacity={0} ref={hasNextPage ? observerRef : null} />
    </TableContainer>
  );
};

export default BranchStatusDetailTable;
