// 단일 파일 업로드

import { api } from 'api/api';
import { UploadFileResponseType } from '../types/file.type';

export const createFile = async (data: FormData): Promise<UploadFileResponseType> => {
  try {
    const response = await api({
      method: 'post',
      url: `/file/fileupload`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
