import React, { useEffect } from 'react';
import LoginContainer from '../features/auth/containers/LoginContainer';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('user');
    if (token) {
      navigate('/home');
    }
  }, [navigate]);
  return (
    <>
      <LoginContainer />
    </>
  );
};

export default LoginPage;
