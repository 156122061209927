import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: defineStyle({
    border: `3px solid`,
    borderColor: '#6C696A',
    background: '#fff',
    width: '21px',
    height: '21px',

    _checked: {
      borderColor: '#A2A2A2',
      background: '#fff',

      _before: {
        width: '9px',
        height: '9px',
        background: '#6C696A',
      },
      _hover: {
        borderColor: '#A2A2A2',
        background: '#fff',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  }),
  label: defineStyle({
    color: '#fff',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginLeft: '2px',

    _checked: {},
  }),
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
});
