import { Box, Flex, Input, Text, Link } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Control, Controller, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { useEffect, useState } from 'react';

interface BranchInformationItemProps {
    isEditMode: boolean;
    name: string;
    value: string;
    controllerName: string;
    control: Control<any>;
    setValue: UseFormSetValue<FieldValues>;
    watch?: UseFormWatch<any>;
}

const datepickerStyles = css`
  width: 100%;
  padding: 8px 0 16px;
  input {
    padding: 0 16px;
    font-size: 16px;
  }
`;

export default function BranchInformationItem({ isEditMode, controllerName, name, value, setValue, control, watch }: BranchInformationItemProps) {
    const [installDate, setInstallDate] = useState<Date | null>(new Date(value));

    const isDisabledEdit = controllerName === 'brancSeq' || controllerName === 'branchAddr';
    const isDatePicker = controllerName === 'dvcInstallDt';
    const isDDNS = controllerName === 'ddnsAddr';
    const isOwnerPhone = controllerName === 'branchOwnerPhone';
    const isAdminPhone = controllerName === 'branchAdminPhone';

    // 전화번호 초기값이 유효한 문자열인지 확인하고 처리
    const phoneValueParts = typeof value === 'string' ? value.split('-') : ['', '', ''];

// watch가 undefined인 경우 기본값을 반환하도록 처리
    const ownerPhone1 = watch ? watch('ownerPhone1', phoneValueParts[0]) : phoneValueParts[0];
    const ownerPhone2 = watch ? watch('ownerPhone2', phoneValueParts[1]) : phoneValueParts[1];
    const ownerPhone3 = watch ? watch('ownerPhone3', phoneValueParts[2]) : phoneValueParts[2];

    const adminPhone1 = watch ? watch('adminPhone1', phoneValueParts[0]) : phoneValueParts[0];
    const adminPhone2 = watch ? watch('adminPhone2', phoneValueParts[1]) : phoneValueParts[1];
    const adminPhone3 = watch ? watch('adminPhone3', phoneValueParts[2]) : phoneValueParts[2];


    // 전화번호 입력 시 값 합치기
    useEffect(() => {
        if (isOwnerPhone) {
            setValue('branchOwnerPhone', `${ownerPhone1}-${ownerPhone2}-${ownerPhone3}`);
        } else if (isAdminPhone) {
            setValue('branchAdminPhone', `${adminPhone1}-${adminPhone2}-${adminPhone3}`);
        }
    }, [ownerPhone1, ownerPhone2, ownerPhone3, adminPhone1, adminPhone2, adminPhone3, isOwnerPhone, isAdminPhone, setValue]);

    const handleChangeInstallDate = (date: Date | null) => {
        setValue('dvcInstallDt', date);
        setInstallDate(date);
    };

    useEffect(() => {
        if (!isEditMode) {
            setInstallDate(new Date(value));
        }
    }, [isEditMode, value]);

    return (
        <Flex display={'flex'} alignItems="center" h="40px" minH="40px" color="white" p={'0 24px'}>
            <Box flex="none" w="3px" h="100%" bg="red.500" />
            <Text minW="92px" pl={3} fontWeight={700} fontSize={'14px'} whiteSpace={'pre-wrap'}>
                {name}
            </Text>

            {/* 읽기 전용 모드 */}
            {(!isEditMode || isDisabledEdit || isDDNS) && (
                <Text minW="200px" pl={3} fontWeight={400} fontSize={'14px'}>
                    {isDDNS && isEditMode ? (
                        value
                    ) : isDDNS ? (
                        <Link href={`http://${value}`} isExternal color="blue.400">
                            {value}
                        </Link>
                    ) : (
                        value
                    )}
                </Text>
            )}

            {/* 전화번호 입력 모드 */}
            {isEditMode && !isDisabledEdit && (isOwnerPhone || isAdminPhone) && (
                <Flex align="center" gap="6px">
                    <Input
                        type="number"
                        variant="edit"
                        w="60px"
                        value={isOwnerPhone ? ownerPhone1 : adminPhone1}
                        onChange={(e) => setValue(isOwnerPhone ? 'ownerPhone1' : 'adminPhone1', e.target.value)}
                    />
                    -
                    <Input
                        type="number"
                        variant="edit"
                        w="60px"
                        value={isOwnerPhone ? ownerPhone2 : adminPhone2}
                        onChange={(e) => setValue(isOwnerPhone ? 'ownerPhone2' : 'adminPhone2', e.target.value)}
                    />
                    -
                    <Input
                        type="number"
                        variant="edit"
                        w="60px"
                        value={isOwnerPhone ? ownerPhone3 : adminPhone3}
                        onChange={(e) => setValue(isOwnerPhone ? 'ownerPhone3' : 'adminPhone3', e.target.value)}
                    />
                </Flex>
            )}

            {/* 일반 필드 입력 처리 */}
            {isEditMode && !isDisabledEdit && !isDatePicker && !isDDNS && !isOwnerPhone && !isAdminPhone && (
                <Controller
                    name={controllerName}
                    control={control}
                    defaultValue={value}
                    render={({ field }) => <Input bg="#fff" color="#222" {...field} />}
                />
            )}

            {/* 설치일 입력 처리 */}
            {isEditMode && isDatePicker && (
                <CustomDatePicker
                    customStyles={datepickerStyles}
                    modalName="설치일"
                    placeholder="---설치일---"
                    selectedDate={installDate}
                    onChangeDate={handleChangeInstallDate}
                />
            )}
        </Flex>
    );
}


//

//20241001 지점장 및 관리자 번호 input 테스트 적용전 코드
/*import { Box, Flex, Input, Text, Link } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { useEffect, useState } from 'react';

interface BranchInformationItemProps {
  isEditMode: boolean;
  name: string;
  value: string;
  controllerName: string;
  control: Control<any>;
  setValue: UseFormSetValue<FieldValues>;
}

const datepickerStyles = css`
  width: 100%;
  padding: 8px 0 16px;
  input {
    padding: 0 16px;
    font-size: 16px;
  }
`;

export default function BranchInformationItem({ isEditMode, controllerName, name, value, setValue, control }: BranchInformationItemProps) {
  const [installDate, setInstallDate] = useState<Date | null>(new Date(value));

  const isDisabledEdit = controllerName === 'brancSeq' || controllerName === 'branchAddr';
  const isDatePicker = controllerName === 'dvcInstallDt';
  const isDDNS = controllerName === 'ddnsAddr'; // DDNS 필드 감지

  const handleChangeInstallDate = (date: Date | null) => {
    setValue('dvcInstallDt', date);
    setInstallDate(date);
  };

  useEffect(() => {
    if (!isEditMode) {
      setInstallDate(new Date(value));
    }
  }, [isEditMode, value]);

  return (
      <Flex display={'flex'} alignItems="center" h="40px" minH="40px" color="white" p={'0 24px'}>
        <Box flex="none" w="3px" h="100%" bg="red.500" />
        <Text minW="92px" pl={3} fontWeight={700} fontSize={'14px'} whiteSpace={'pre-wrap'}>
          {name}
        </Text>
        {(!isEditMode || isDisabledEdit) && (
            <Text minW="200px" pl={3} fontWeight={400} fontSize={'14px'}>
              {/!* DDNS 항목인 경우 a 태그로 감싸서 링크로 보여주기 *!/}
              {isDDNS ? (
                  <Link href={`http://${value}`} isExternal color="blue.400">
                    {value}
                  </Link>
              ) : (
                  value
              )}
            </Text>
        )}
        {isEditMode && !isDisabledEdit && !isDatePicker && !isDDNS && (
            <Controller name={controllerName} control={control} defaultValue={value} render={({ field }) => <Input bg="#fff" color="#222" {...field} />} />
        )}

        {/!* 설치일 일경우 *!/}
        {isEditMode && isDatePicker && <CustomDatePicker customStyles={datepickerStyles} modalName="설치일" placeholder="---설치일---" selectedDate={installDate} onChangeDate={handleChangeInstallDate} />}
      </Flex>
  );
}*/

//

//기존 코드 20240930 DDNS값 A링크로 변경 전
/*
import { Box, Flex, Input, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
interface BranchInformationItemProps {
  isEditMode: boolean;
  name: string;
  value: string;
  controllerName: string;
  control: Control<any>;
  setValue: UseFormSetValue<FieldValues>;
}
const datepickerStyles = css`
  width: 100%;
  padding: 8px 0 16px;
  input {
    padding: 0 16px;
    font-size: 16px;
  }
`;

export default function BranchInformationItem({ isEditMode, controllerName, name, value, setValue, control }: BranchInformationItemProps) {
  const [installDate, setInstallDate] = useState<Date | null>(new Date(value));

  const isDisabledEdit = controllerName === 'brancSeq' || controllerName === 'branchAddr';
  const isDatePicker = controllerName === 'dvcInstallDt';

  const handleChangeInstallDate = (date: Date | null) => {
    setValue('dvcInstallDt', date);
    setInstallDate(date);
  };

  useEffect(() => {
    if (!isEditMode) {
      setInstallDate(new Date(value));
    }
  }, [isEditMode, value]);

  return (
      <Flex display={'flex'} alignItems="center" h="40px" minH="40px" color="white" p={'0 24px'}>
        <Box flex="none" w="3px" h="100%" bg="red.500" />
        <Text minW="92px" pl={3} fontWeight={700} fontSize={'14px'} whiteSpace={'pre-wrap'}>
          {name}
        </Text>
        {(!isEditMode || isDisabledEdit) && (
            <Text minW="200px" pl={3} fontWeight={400} fontSize={'14px'}>
              {value}
            </Text>
        )}
        {isEditMode && !isDisabledEdit && !isDatePicker && <Controller name={controllerName} control={control} defaultValue={value} render={({ field }) => <Input bg="#fff" color="#222" {...field} />} />}

        {/!* 설치일 일경우 *!/}
        {isEditMode && isDatePicker && <CustomDatePicker customStyles={datepickerStyles} modalName="설치일" placeholder="---설치일---" selectedDate={installDate} onChangeDate={handleChangeInstallDate} />}
      </Flex>
  );
}
*/
