import DashboardContainer from '../features/dashboard/containers/DashboardContainer';
import { Box } from '@chakra-ui/react';

const DashboardPage = () => {
  return (
    <Box w="100%" h="100vh" bg={'#231f20'}>
      <DashboardContainer />
    </Box>
  );
};

export default DashboardPage;
