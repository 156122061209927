import { Grid, Text } from '@chakra-ui/react';
import { modalColDefsData } from 'features/branchManagement/data/WashMachineLinkModalColDefs.data';

const WashMachineListHeader = () => {
  return (
    <Grid templateColumns="repeat(4, 1fr)" alignItems="center" w="full" h="64px" p="12px 0">
      {modalColDefsData.map((item) => (
        <Text key={item.id} fontSize={14} fontWeight={700} color="white" p="10px" textAlign="center">
          {item.label}
        </Text>
      ))}
    </Grid>
  );
};

export default WashMachineListHeader;
