import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import Button from 'components/Button/Button';
import { BranchWashMachineListItem } from 'features/branchManagement/api/dtos/getBranchWashMachineList.dto';
import useMachineModelTypeMap from 'features/branchManagement/hooks/useMachineModelTypeMap';
import usePlcModelTypeMap from 'features/branchManagement/hooks/usePlcModelTypeMap';
import usePlcProviderTypeMap from 'features/branchManagement/hooks/usePlcProviderTypeMap';
import useMachineTypeMap from 'features/branchSearch/hooks/useMachineTypeMap';

interface Props {
  branchWashMachineList: BranchWashMachineListItem[];
  onClickEditMode: () => void;
  onClose: () => void;
}

const WashMachineView = ({ branchWashMachineList, onClickEditMode, onClose }: Props) => {
  // 세차기 타입 변환
  const { machineTypeMap } = useMachineTypeMap();
  // 세차기 모델 타입 변환
  const { machineModelTypeMap } = useMachineModelTypeMap(branchWashMachineList[0].codeType);
  // PLC 제조사 타입 변환
  const { plcProviderTypeMap } = usePlcProviderTypeMap();
  // PLC 모델 타입 변환
  const { plcModelTypeMap } = usePlcModelTypeMap();

  return (
    <>
      <Box m="16px 0" w="full">
        <Flex alignItems="center" w="full" h="40px" borderLeft="3px solid #C30D24">
          <Text fontSize={14} fontWeight={700} color="white" w="160px" p="0 8px">
            지점 타입
          </Text>

          <Text fontSize={14} fontWeight={400} color="white" w="160px" p="0 8px">
            {machineTypeMap[branchWashMachineList[0].codeType]}
          </Text>
        </Flex>
      </Box>

      {/* 세차기 리스트 */}
      <Grid templateColumns="repeat(2, 1fr)" gap="16px" w="100%" h="560px" color="white">
        {branchWashMachineList.slice(0, 4).map((machine, index) => (
          <Flex key={index} flexDirection="column" h="272px" borderRadius={10} bg="#393536">
            <Flex justifyContent="center" align="center" alignSelf="stretch" p="18px" borderBottom="2px solid #C30D24">
              <Text fontSize="14px" fontWeight={700}>
                세차기 {index + 1}
              </Text>
            </Flex>

            <Stack spacing="8px" p="16px">
              <Flex justifyContent="flex-start" align="center" p="10px 0">
                <Text fontSize="14px" fontWeight={700} w="96px">
                  세차기 모델
                </Text>
                <Text fontSize="14px" fontWeight={400}>
                  {machineModelTypeMap[machine.codeModel]}
                </Text>
              </Flex>

              <Flex justifyContent="flex-start" align="center" p="10px 0">
                <Text fontSize="14px" fontWeight={700} w="96px">
                  PLC 제조사
                </Text>
                <Text fontSize="14px" fontWeight={400}>
                  {plcProviderTypeMap[machine.codePlcPrivider]}
                </Text>
              </Flex>

              <Flex justifyContent="flex-start" align="center" p="10px 0">
                <Text fontSize="14px" fontWeight={700} w="96px">
                  PLC 모델명
                </Text>
                <Text fontSize="14px" fontWeight={400}>
                  {plcModelTypeMap[machine.plcModel]}
                </Text>
              </Flex>

              <Flex justifyContent="flex-start" align="center" p="10px 0">
                <Text fontSize="14px" fontWeight={700} w="96px">
                  PLC 버전 정보
                </Text>
                <Text fontSize="14px" fontWeight={400}>
                  {machine.plcVersion}
                </Text>
              </Flex>
            </Stack>
          </Flex>
        ))}
      </Grid>

      {/* modal footer */}
      <Flex justifyContent="center" gap="16px" p="24px 0">
        <Button buttonType="fill" buttonSize={40} onClick={onClickEditMode}>
          수 정
        </Button>
        <Button buttonType="outline" buttonSize={40} onClick={onClose}>
          취 소
        </Button>
      </Flex>
    </>
  );
};

export default WashMachineView;
