import { Flex, useDisclosure } from '@chakra-ui/react';
import ModalLayout from 'components/Modals/ModalLayout';
import LinkButton from '../../LinkButton/LinkButton';
import { BranchConnectStatusValue } from 'features/branchManagement/api/dtos/getWashMachineList.dto';
import WashMachineLinkView from './WashMachineLinkView';
import WashMachineLinkEditForm from './WashMachineLinkEditForm';
import { useCallback, useState } from 'react';

interface Props {
  isLinking: BranchConnectStatusValue;
  branchSeq: number;
}

const WashMachineLinkModal = ({ isLinking, branchSeq }: Props) => {
  const { isOpen, onOpen, onClose: close } = useDisclosure();
  const [isEditMode, setIsEditMode] = useState(false);

  const onLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLinking === 'WCS0002') {
      setIsEditMode(true);
    }

    e.stopPropagation();
    onOpen();
  };

  const onModify = () => {
    setIsEditMode(true);
  };

  const onClose = useCallback(() => {
    close();
    setIsEditMode(false);
  }, [close]);

  return (
    <>
      <LinkButton isLinking={isLinking} onClick={(e) => onLink(e)}>
        {isLinking === 'WCS0001' ? '연동중' : '연동하기'}
      </LinkButton>

      <ModalLayout isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection="column" w="608px" h="508px">
          {/* 세차기 리스트 보기 */}
          {isLinking === 'WCS0001' && !isEditMode && <WashMachineLinkView branchSeq={branchSeq} onClose={onClose} onModify={onModify} />}

          {/* 세차기 연동 수정 */}
          {(isLinking === 'WCS0002' || isEditMode) && <WashMachineLinkEditForm branchSeq={branchSeq} onClose={onClose} />}
        </Flex>
      </ModalLayout>
    </>
  );
};

export default WashMachineLinkModal;
