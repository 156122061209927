import { Box, Flex, Text } from '@chakra-ui/react';
// import useSensorTypeMap from '../hooks/useSensorTypeMap';
import { SensorStatus } from '../api/dtos/getWashSensorList.dto';

interface Props {
  SensorStatus: SensorStatus;
}

const SensorItem = ({ SensorStatus }: Props) => {
  // 센서 코드 변환(나중에 값이 바뀔지 모르니 코드만 유지)
  // const { sensorTypeMap } = useSensorTypeMap();

  return (
    <Flex justifyContent={'flex-start'} alignItems={'center'} gap={'16px'} h={'48px'} p={'8px 16px'} bg={'#231F20'} border={`2px solid #c30d24`} borderRadius={'5px'}>
      {/* 0: 운영 중 아님 */}
      {SensorStatus.val === 0 && <Box flexShrink={0} w="12px" h="12px" bg="#D1D1D1" borderRadius="50%" boxShadow="0 -0.464516px 0.929032px rgba(0, 0, 0, 0.5)" />}
      {/* 1: 운영중 */}
      {SensorStatus.val === 1 && <Box flexShrink={0} w="12px" h="12px" bg="#00FF00" borderRadius="50%" boxShadow="0 -0.464516px 0.929032px rgba(0, 0, 0, 0.5)" />}

      <Text fontSize={'12px'} fontWeight={700} color={'white'}>
        {SensorStatus.kor}
      </Text>
    </Flex>
  );
};

export default SensorItem;
