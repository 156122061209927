import { api } from 'api/api';
import type { BaseResponse } from 'api/api.types';
import type { GetNewUrgentAlarmResponseDto } from './dtos/getNewUrgentAlarm.dto';
import type { GetDashboardAlarmStatusResponseDto, RegionCode } from './dtos/getDashboardAlarmStatus.dto';
import { isAxiosError } from 'axios';

export async function getNewUrgentAlarmsApi() {
  try {
    const { data } = await api.get<BaseResponse<GetNewUrgentAlarmResponseDto>>('/alarm/search/newUrgent');
    return data.data;
  } catch (err) {
    if (isAxiosError(err)) {
      if (err.response?.status === 404) {
        return { alarmList: [] };
      }
    }
  }
}

export async function readNewUrgentAlarmsApi(seqList: number[]) {
  const { data } = await api.put<BaseResponse<void>>('/alarm/urgent', { seqList });
  return data;
}

// 대시보드 각 현황판 알림여부 조회
export async function getDashboardAlarmStatusApi(region: RegionCode) {
  const { data } = await api.get<BaseResponse<GetDashboardAlarmStatusResponseDto>>(`/alarm/dashboard/${region}`);
  return data.data;
}
