import { Flex, Grid, useDisclosure, Input, Textarea } from '@chakra-ui/react';
import BranchInformationItem from './BranchInformationItem';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import AlertModal from 'components/Modals/AlertModal';
import { useCallback, useEffect, useState } from 'react';
import useBranchTypeOptions from 'features/branchSearch/hooks/useBranchTypeOptions';
import useBranchRegionOptions from 'features/branchSearch/hooks/useBranchRegionOptions';
import { Controller, FieldErrors, useForm, type SubmitHandler } from 'react-hook-form';
import { branchInformationUpdateSchema, type BranchInformationUpdateSchema } from 'features/branchManagement/schema/branchInformationForm.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateBranch } from 'features/branchManagement/hooks/react-query/useUpdateBranch';
import useGetBranchManagementInformation from 'features/branchManagement/hooks/react-query/useGetBranchManagementInformation';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import AddressInput from 'components/AddressInput/AddressInput';

import useUploadFile from 'hooks/useUploadFile';
import { UploadFileItem } from 'api/dtos/uploadFile.dto';
import ImageUploadButton from '../../ImageUploadButton/ImageUploadButton';
import { STORAGE_URL } from 'common/constants';
import { UpdateBranchInformationDto } from 'features/branchManagement/api/dtos/updateBranchInformations.dto';
import useCoordinates from 'features/branchManagement/hooks/useCoordinates';

interface Props {
  selectedBranchSequence: number;
  onCloseBranchInformationModal: () => void;
  onOpenUnsavedModal: () => void;
}

const BranchInformationEdit = ({ selectedBranchSequence, onCloseBranchInformationModal, onOpenUnsavedModal }: Props) => {
  const { isOpen: isOpenSaveRequiredModal, onOpen: onOpenSaveRequiredModal, onClose: onCloseSaveRequiredModal } = useDisclosure();
  const [saveRequiredText, setSaveRequiredText] = useState('');
  const [uploadFileData, setUploadFileData] = useState<UploadFileItem>();

  // 지점 유형 옵션
  const { branchTypeOptionsWithoutAll } = useBranchTypeOptions();
  // 지역 옵션
  const { branchRegionOptionsWithoutAll } = useBranchRegionOptions();
  // 지점 관리 상세페이지 조회
  const { data: branchInformation } = useGetBranchManagementInformation(selectedBranchSequence);
  // 파일 업로드
  const { mutateAsync: uploadImageAsync } = useUploadFile();

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { isDirty, errors },
  } = useForm<BranchInformationUpdateSchema>({
    resolver: zodResolver(branchInformationUpdateSchema),
    defaultValues: {
      gpsLat: 0,
      gpsLng: 0,
      branchAddr: branchInformation?.branchAddr ?? ''
    },
  });


  // 초기화
  useEffect(() => {
    if (!branchInformation) return;

    const { branchOwnerPhone, branchAdminPhone, branchNm, branchOwnerNm, branchBrn, branchMemo, dvcInstallDt, catId, tabletVersion, plcVersion, pubIp, ddnsAddr } = branchInformation;

    const { part1: ownerPhone1 = '', part2: ownerPhone2 = '', part3: ownerPhone3 = '' } = branchOwnerPhone ? splitPhoneNumber(branchOwnerPhone) : {};
    const { part1: adminPhone1 = '', part2: adminPhone2 = '', part3: adminPhone3 = '' } = branchAdminPhone ? splitPhoneNumber(branchAdminPhone) : {};

    reset({
      branchNm: branchNm,
      branchOwnerNm: branchOwnerNm ?? '',
      branchBrn: branchBrn ?? '',
      ownerPhone1: ownerPhone1 ?? '',
      ownerPhone2: ownerPhone2 ?? '',
      ownerPhone3: ownerPhone3 ?? '',
      adminPhone1: adminPhone1 ?? '',
      adminPhone2: adminPhone2 ?? '',
      adminPhone3: adminPhone3 ?? '',
      branchMemo: branchMemo ?? '',
      branchImgSeq: undefined,
      dvcInstallDt: dvcInstallDt || undefined,
      catId: catId ?? '',
      tabletVersion: tabletVersion ?? '',
      plcVersion: plcVersion ?? '',
      pubIp: pubIp ?? '',
      ddnsAddr: ddnsAddr ?? '',
    });
  }, [branchInformation, reset]);

  // 파일 업로드
  const onImageUpload = async (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const uploadFileResponse = await uploadImageAsync({ formData });
      setValue('branchImgSeq', uploadFileResponse.seq, { shouldValidate: true });
      setUploadFileData(uploadFileResponse);
    }
  };

  const { getAddressCoords } = useCoordinates();
  const onChangeAddress = useCallback(
    async (address: string) => {
      // 좌표값
      const coordinate = await getAddressCoords(address);
      if (!coordinate) {
        alert('주소와 일치하는 좌표값이 존재하지 않습니다.\n지번 주소 혹은 다른 도로명 주소를 선택해주세요.');
        return;
      }

      setValue('branchAddr', address);
      setValue('gpsLat', coordinate.lat);
      setValue('gpsLng', coordinate.lng);
    },
    [getAddressCoords, setValue]
  );
  

  const { mutate: updateBranch } = useUpdateBranch({ onCloseBranchInformationModal });
  // 지점 수정
  const onSubmit: SubmitHandler<BranchInformationUpdateSchema> = useCallback(
    (values) => {
      if (!branchInformation) return;

      const isOwnerPhoneComplete = values.ownerPhone1 && values.ownerPhone2 && values.ownerPhone3;
      const isAdminPhoneComplete = values.adminPhone1 && values.adminPhone2 && values.adminPhone3;

      const ownerPhone = `${values.ownerPhone1}-${values.ownerPhone2}-${values.ownerPhone3}`;
      const adminPhone = `${values.adminPhone1}-${values.adminPhone2}-${values.adminPhone3}`;

      updateBranch({
        branchSeq: branchInformation.brancSeq,
        branchNm: values.branchNm, // 필수 필드
        branchOwnerNm: values.branchOwnerNm || null!,
        branchBrn: values.branchBrn || undefined,
        branchAddr: values.branchAddr,
        gpsLat: values.gpsLat,
        gpsLng: values.gpsLng,
        branchOwnerPhone: isOwnerPhoneComplete
          ? //
            ownerPhone
          : !values.ownerPhone1 && !values.ownerPhone2 && !values.ownerPhone3
            ? null
            : undefined,
        branchAdminPhone: isAdminPhoneComplete
          ? //
            adminPhone
          : !values.adminPhone1 && !values.adminPhone2 && !values.adminPhone3
            ? null
            : undefined,
        branchMemo: values.branchMemo || undefined,
        branchImgSeq: values.branchImgSeq || undefined,
        dvcInstallDt: values.dvcInstallDt || undefined,
        catId: values.catId || undefined,
        tabletVersion: values.tabletVersion || undefined,
        plcVersion: values.plcVersion || undefined,
        pubIp: values.pubIp || undefined,
        ddnsAddr: values.ddnsAddr || undefined,
      } satisfies UpdateBranchInformationDto);
    },
    [branchInformation, updateBranch]
  );

  // 필수 항목 전체 필드 체크
  const onError = (errors: FieldErrors<BranchInformationUpdateSchema>) => {
    const missingFields = Object.keys(errors)
      .map((field) => {
        switch (field) {
          case 'branchNm':
            return '지점 이름';
          default:
            return field;
        }
      })
      .join(', ');

    setSaveRequiredText(missingFields);
    onOpenSaveRequiredModal();
  };

  const splitPhoneNumber = (phoneNumber: string) => {
    const parts = phoneNumber.split('-');
    return {
      part1: parts[0] || '',
      part2: parts[1] || '',
      part3: parts[2] || '',
    };
  };

  return (
    <>
      {/* modal content */}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Flex align="flex-start" alignSelf="stretch" p="16px" gap="24px">
          <ImageUploadButton
            //
            initialImage={branchInformation?.branchImgPath ? `${STORAGE_URL}${branchInformation?.branchImgPath}` : undefined}
            uploadFileData={uploadFileData}
            onImageUpload={onImageUpload}
          />

          <Flex justifyContent="center" gap="24px" flex={1}>
            <Grid h="full" templateRows="repeat(11, 60px)" templateColumns="repeat(1, 1fr)">
              {/* 지점 구분 */}
              <BranchInformationItem label="지점 구분 *" row={1} col={1}>
                <Select
                  //
                  options={branchTypeOptionsWithoutAll}
                  placeholder="구분 선택"
                  cssStyle={{ width: '100%' }}
                  value={branchTypeOptionsWithoutAll.find((option) => option.value === branchInformation?.codeType)}
                  isDisabled
                />
              </BranchInformationItem>

              {/* 지역 선택 */}
              <BranchInformationItem label="지역 선택 *" row={1} col={1}>
                <Select
                  //
                  options={branchRegionOptionsWithoutAll}
                  placeholder="지역 선택"
                  cssStyle={{ width: '100%' }}
                  value={branchRegionOptionsWithoutAll.find((option) => option.value === branchInformation?.codeRegion)}
                  isDisabled
                />
              </BranchInformationItem>

              {/* 지점 이름 */}
              <BranchInformationItem label="지점 이름 *" row={1} col={1}>
                <Input type="text" variant="edit" {...register('branchNm')} onBlur={(e) => setValue('branchNm', e.target.value, { shouldValidate: true })} />
                {errors.branchNm && <ErrorMessage text="* 지점 이름은 필수 입니다." />}
              </BranchInformationItem>

              {/* 지점장 */}
              <BranchInformationItem label="지점장" row={1} col={1}>
                <Input type="text" variant="edit" {...register('branchOwnerNm')} />
              </BranchInformationItem>

              {/* 사업자 등록번호 */}
              <BranchInformationItem label={'사업자\n등록번호'} row={1} col={1}>
                <Input type="number" variant="edit" {...register('branchBrn')} />
              </BranchInformationItem>

              {/* 주소 */}
              <BranchInformationItem label="주소 *" row={1} col={1}>
                <Controller
                    //
                    control={control}
                    name="branchAddr"
                    render={() => (
                      <AddressInput
                        //
                        placeholder="예) 판교역로 166, 분당 주공, 백현동 532"
                        address={watch('branchAddr')}
                        onComplete={onChangeAddress}
                      />
                    )}
                  />
                {/* <AddressInput
                  //
                  placeholder="예) 판교역로 166, 분당 주공, 백현동 532"
                  onComplete={() => {onChangeAddress}}
                  address={branchInformation?.branchAddr ?? ''}
                  
                /> */}
              </BranchInformationItem>

              {/* 지점장 전화번호 */}
              <BranchInformationItem label={'지점장\n 전화번호'} row={1} col={1}>
                <Flex align="center" gap="6px" color="white">
                  <Input type="number" variant="edit" w="60px" {...register('ownerPhone1')} /> - <Input type="number" variant="edit" w="60px" {...register('ownerPhone2')} /> -{' '}
                  <Input type="number" variant="edit" w="60px" {...register('ownerPhone3')} />
                </Flex>
              </BranchInformationItem>

              {/* 관리자 전화번호 */}
              <BranchInformationItem label={'관리자\n 전화번호'} row={1} col={1}>
                <Flex align="center" gap="6px" color="white">
                  <Input type="number" variant="edit" w="60px" {...register('adminPhone1')} /> - <Input type="number" variant="edit" w="60px" {...register('adminPhone2')} /> -{' '}
                  <Input type="number" variant="edit" w="60px" {...register('adminPhone3')} />
                </Flex>
              </BranchInformationItem>

              {/* 메모 */}
              <BranchInformationItem label="메모" row={3} col={1}>
                <Textarea variant="edit" w="full" h="168px" {...register('branchMemo')} />
              </BranchInformationItem>
            </Grid>

            <Grid h="full" templateRows="repeat(11, 60px)" templateColumns="repeat(1, 1fr)">
              {/* 설치일 */}
              <BranchInformationItem label="설치일" row={1} col={1}>
                <Controller
                  //
                  control={control}
                  name="dvcInstallDt"
                  render={({ field: { onChange, value } }) => (
                    <CustomDatePicker
                      //
                      modalName="설치일"
                      placeholder="날짜 선택"
                      selectedDate={value ? new Date(value) : null}
                      onChangeDate={(date) => onChange(date ? date.toISOString() : null)}
                    />
                  )}
                />
              </BranchInformationItem>

              {/* CAT ID */}
              <BranchInformationItem label="CAT ID" row={1} col={1}>
                <Input type="text" variant="edit" {...register('catId')} />
              </BranchInformationItem>

              {/* 태블릿 버전 */}
              <BranchInformationItem label="태블릿 버전" row={1} col={1}>
                <Input type="text" variant="edit" {...register('tabletVersion')} />
              </BranchInformationItem>

              {/* PLC 버전 */}
              <BranchInformationItem label="PLC 버전" row={1} col={1}>
                <Input type="text" variant="edit" {...register('plcVersion')} />
              </BranchInformationItem>

              {/* 외부 아이피 */}
              <BranchInformationItem label="외부 아이피" row={1} col={1}>
                <Input type="text" variant="edit" {...register('pubIp')} />
              </BranchInformationItem>

              {/* DDNS */}
              <BranchInformationItem label="DDNS" row={1} col={1}>
                <Input type="text" variant="edit" {...register('ddnsAddr')} />
              </BranchInformationItem>
            </Grid>
          </Flex>
        </Flex>

        {/* modal footer */}
        <Flex justify="center" align="center" gap="16px" w="full" p="24px 0">
          <Button type="submit" buttonType="fill" buttonSize={40}>
            저 장
          </Button>
          <Button type="button" buttonType="outline" buttonSize={40} onClick={isDirty ? onOpenUnsavedModal : onCloseBranchInformationModal}>
            취 소
          </Button>
        </Flex>
      </form>

      <AlertModal title={`[ ${saveRequiredText} ]\n필수 항목입니다.`} isOpen={isOpenSaveRequiredModal} onClose={onCloseSaveRequiredModal} />
    </>
  );
};
export default BranchInformationEdit;
