import { useQueryClient } from '@tanstack/react-query';
import { getMeQueryOptions } from 'features/userProfile/hooks/useGetMe';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import authStorage from 'storages/authStorage';

export function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    queryClient.resetQueries({ queryKey: getMeQueryOptions.queryKey, stale: false });
    authStorage.clear();
    navigate('/login');
  }, [navigate, queryClient]);

  return { logout };
}
