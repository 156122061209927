import { Flex, Box, Text, type FlexProps } from '@chakra-ui/react';

interface MachineMonitoringSensorTagProps extends FlexProps {
  isActive: boolean;
  label: string;
}

export default function MachineMonitoringSensorTag({ isActive, label, ...restProps }: MachineMonitoringSensorTagProps) {
  return (
    <Flex {...restProps} position={'absolute'} borderRadius={'50px'} border={'2px solid #fff'} width={'fit-content'} height={'24px'} padding={'0 8px'} gap={'6px'} alignItems={'center'} background={'rgba(35, 31, 32, 0.50)'}>
      {isActive ? (
        <Box flexShrink={0} w="8px" h="8px" bg="#00FF00" borderRadius="50%" boxShadow="0 -0.464516px 0.929032px rgba(0, 0, 0, 0.5)" />
      ) : (
        <Box flexShrink={0} w="8px" h="8px" bg="#D1D1D1" borderRadius="50%" boxShadow="0 -0.464516px 0.929032px rgba(0, 0, 0, 0.5)" />
      )}

      <Text fontSize={'10px'} fontWeight={700}>
        {label}
      </Text>
    </Flex>
  );
}
