import { useQuery } from '@tanstack/react-query';
import { PlcProviderTypeList } from 'features/branchManagement/api/dtos/getPlcProviderTypeList.dto';
import { getCommonCodeApi } from 'features/common/api/common';

const PLC_PROVIDER_TYPE_LIST = 'PLC_PROVIDER_TYPE_LIST';

export default function useGetPlcProViderTypeList() {
  return useQuery({
    queryKey: [PLC_PROVIDER_TYPE_LIST],
    queryFn: () => getCommonCodeApi<PlcProviderTypeList>('plc_privider'),
  });
}
