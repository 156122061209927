/** @jsxImportSource @emotion/react */

import { errorMessage } from './ErrorMessage.style';

interface Props {
  text: string;
}

const ErrorMessage = ({ text }: Props) => {
  return <p css={errorMessage}>{text}</p>;
};

export default ErrorMessage;
