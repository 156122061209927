import { Button, Text, Image } from '@chakra-ui/react';
import { RemoteControlButtonType } from '../types';

interface RemoteControlProps {
  controlButton: RemoteControlButtonType;
  onOpenModal: () => void;
  selectedButton: boolean;
}

const RemoteControl = ({ controlButton, onOpenModal, selectedButton }: RemoteControlProps) => {
  return (
    <Button display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'9px'} h={'full'} p={'8px 16px'} bg={selectedButton ? '#72000e' : 'red.500'} borderRadius={'5px'} cursor={'pointer'} onClick={onOpenModal} _hover={{ bg: '#72000e' }}>
      <Image src={'/assets/images/control_icon.png'} w={'28px'} height={'28px'} />
      <Text fontSize={'12px'} fontWeight={700} color={'white'}>
        {controlButton.name}
      </Text>
    </Button>
  );
};

export default RemoteControl;
