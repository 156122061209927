import { useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import { api } from 'api/api';
import { useLogout } from './useLogout';
import authStorage from 'storages/authStorage';
import { getExpirationDate } from 'utils/getExpirationDate';
import dayjs from 'dayjs';

export const useInterceptEffect = () => {
  const isDevelopment = useMemo(() => process.env.NODE_ENV === 'development', []);
  const { logout } = useLogout();

  useEffect(() => {
    api.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        if (!error.config || !error.response) {
          return Promise.reject(error);
        }

        if (isDevelopment) {
          //  ERROR[GET] /users/me
          console.log(`ERROR[${error.config.method?.toUpperCase()}] ${error.config.url}`);
        }

        const isUnauthorized = error.response.status === 401;

        // 액세스 토큰 만료 시 로그아웃
        if (isUnauthorized) {
          const accessToken = authStorage.getAccessToken();
          if (!accessToken) return Promise.reject(error);

          const expirationDate = getExpirationDate(accessToken);
          const isExpired = dayjs(expirationDate).isBefore(dayjs());
          if (!isExpired) return Promise.reject(error);

          logout();
        }

        return Promise.reject(error);
      }
    );
  }, [isDevelopment, logout]);
};
