/** @jsxImportSource @emotion/react */
import { Link, useLocation } from 'react-router-dom';
import { defaultMenuList } from 'containers/menuList.data';
import { activeStyle, sidebarContainer, sidebarItem, sidebarLabel, sidebarList } from './Sidebar.styles';

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const menuList = defaultMenuList.map((menu) => ({
    ...menu,
    isActive: menu.path === currentPath,
  }));

  return (
    <div css={sidebarContainer}>
      <ul css={sidebarList}>
        {menuList.map((menu) => (
          <li key={menu.name} css={[sidebarItem, menu.isActive && activeStyle]}>
            <img src={`/assets/icons/sidebar/${menu.icon}.png`} alt={`${menu.name} icon`} width="28px" height="28px" />
            {menu.icon === 'cam' && (
              <Link to={menu.path} target="_blank">
                <div css={sidebarLabel}>
                  {menu.name}
                  <img src="/assets/icons/link.png" alt="link icon" width={14} height={14} />
                </div>
              </Link>
            )}
            {menu.icon !== 'cam' && (
              <Link to={menu.path}>
                <div css={sidebarLabel}>{menu.name}</div>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
