import { Button, Flex, Text, Box } from '@chakra-ui/react';
import ModalLayout from 'components/Modals/ModalLayout';
import theme from 'theme';

interface ModalProps {
  isOpen: boolean;
  isPending: boolean;
  onClose: () => void;
  remoteName: string | null;
  onConfirm: (remoteButton: string | null) => void;
}

function ControllerAlertModal({ isOpen, isPending, onClose, remoteName, onConfirm }: ModalProps) {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column" justify="center" align="center" w={'498px'} background={theme.colors.gray} p="8px 8px 24px">
        <Text fontSize={'15px'} fontWeight={'700'} color={theme.colors.white}>
          알림
        </Text>
        <Box color={theme.colors.white} fontWeight={700} fontSize={'20px'} p="15px" textAlign={'center'}>
          {remoteName}
          <br />
          동작하시겠습니까?
        </Box>
        <Flex alignItems={'center'} gap={'16px'} justifyContent={'center'} mt="24px">
          <Button disabled={isPending} colorScheme={isPending ? 'gray' : 'red'} mr={5} size="md" onClick={() => !isPending && onConfirm(remoteName)}>
            확인
          </Button>
          <Button colorScheme={'black'} border="2px solid red" size="md" onClick={onClose}>
            취소
          </Button>
        </Flex>
      </Flex>
    </ModalLayout>
  );
}

export default ControllerAlertModal;
