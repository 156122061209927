/** @jsxImportSource @emotion/react */
import type { Interpolation, Theme } from '@emotion/react';
import { loadingSpinnerCss } from './LoadingSpinner.styles';

export interface LoadingSpinnerProps {
  cssStyle?: Interpolation<Theme>;
}

export default function LoadingSpinner({ cssStyle }: LoadingSpinnerProps) {
  return (
    <div css={[loadingSpinnerCss.loader, cssStyle]}>
      {[...Array(12)].map((_, i) => (
        <div key={i} />
      ))}
    </div>
  );
}
