import {colors} from '../../theme';
import {Box, Flex, Text, Image, Heading} from '@chakra-ui/react';

interface HeaderProps {
    hasUrgentAlarm: boolean;
    isAlarmModalOpen: boolean;
    onClickUrgentAlarm: () => void;
    onLogout: () => void;
}

export default function Header({hasUrgentAlarm, isAlarmModalOpen, onClickUrgentAlarm, onLogout}: HeaderProps) {
    return (
        <Box bg={colors.grayBlack} w="100%" h="54px" p="0 8px" zIndex={20} boxShadow="md" color="white" position="fixed"
             top={0}>
            <Flex justify="space-between" align="center">
                <Image src="/assets/images/small_logo.png" alt="logo" h="54px"></Image>

                {/* header title */}
                <Heading as="h1" textAlign="center" flex="1" fontSize={'20px'} fontWeight={700} lineHeight={'28.96px'}>
                    Come in wash Integrated control system
                </Heading>

                {/* icon menu */}
                <Flex align="center" gap="24px">
                    {/* alert */}
                    {/*추가된 코드 20240909*/}
                    <Box
                        cursor={'pointer'}
                        onClick={onClickUrgentAlarm}
                        borderRadius={isAlarmModalOpen ? '100px' : 'none'}
                        background={isAlarmModalOpen ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.30) 100%)' : 'none'}
                    >
                        {hasUrgentAlarm ?
                            <Image src="/assets/icons/alert-siren.gif" alt="bell notification icon" w="88px"
                                   h="32px"/> : null}
                    </Box>
                    {/**/}
                    <Box
                        cursor={'pointer'}
                        onClick={onClickUrgentAlarm}
                        borderRadius={isAlarmModalOpen ? '100px' : 'none'}
                        background={isAlarmModalOpen ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.30) 100%)' : 'none'}
                    >
                        {hasUrgentAlarm ?
                            <Image src="/assets/icons/bell_notification_icon.png" alt="bell notification icon" w="32px"
                                   h="32px"/> :
                            <Image src="/assets/icons/bell_icon.png" alt="bell icon" w="32px" h="32px"/>}
                    </Box>

                    {/* admin */}
                    <Flex alignItems={'center'} cursor={'pointer'} onClick={onLogout} gap="8px">
                        <Image src="/assets/images/admin_icon.png" alt="user_icon" h="30px"/>
                        <Text fontSize={'16px'} fontWeight={400} w="80px" h="100%">
                            Admin
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
}
