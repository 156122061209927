interface SmoothZoomParams {
  map: google.maps.Map;
  targetZoom: number;
  currentZoom: number;
}

export function smoothZoom({ map, targetZoom, currentZoom }: SmoothZoomParams) {
  if (currentZoom === targetZoom) {
    return;
  } else {
    const step = targetZoom > currentZoom ? 1 : -1;
    const z = google.maps.event.addListener(map, 'zoom_changed', function () {
      google.maps.event.removeListener(z);
      smoothZoom({ map, targetZoom, currentZoom: currentZoom + step });
    });
    setTimeout(() => map.setZoom(currentZoom), 100);
  }
}
