/** @jsxImportSource @emotion/react */
import { components, DropdownIndicatorProps } from 'react-select';
import { dropdownIndicatorOpenStyle, dropdownIndicatorStyle } from './DropdownIndicator.styles';
import * as FeatherIcons from 'react-feather';
import { SelectOption } from '../types/select.types';

export default function DropdownIndicator<Option extends SelectOption>(props: DropdownIndicatorProps<Option>) {
  return (
    <components.DropdownIndicator {...props}>
      <FeatherIcons.ChevronDown color={'#A2A9B0'} css={[dropdownIndicatorStyle, props.selectProps.menuIsOpen && dropdownIndicatorOpenStyle]} />
    </components.DropdownIndicator>
  );
}
