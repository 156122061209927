import { useLoadScript } from '@react-google-maps/api';

// Google Maps API에서 제공하는 라이브러리들을 enum으로 정의
enum Libraries {
  places = 'places',
  // 다른 라이브러리들도 필요에 따라 추가할 수 있습니다.
}

const libraries: Libraries[] = [Libraries.places];

export default function useLoadGoogleMap() {
  const googleMapApiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
    libraries,
  });

  return { googleMapApiKey, isLoaded, loadError };
}
