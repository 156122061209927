import React from 'react';
import RegisterForm from '../components/RegisterForm';
import AppLayoutContainer from '../containers/AppLayoutContainer';

export const RegisterPage: React.FC = () => {
  return (
    <AppLayoutContainer>
      <h1>Register</h1>
      <RegisterForm />
    </AppLayoutContainer>
  );
};
