import { css } from '@emotion/react';

export const titleContainer = css`
  display: flex;
  height: 80px;
  border-radius: 0 50px 50px 0;
  background: #c30d24;
  gap: 40px;
  max-width: 496px;
  justify-content: space-between;
`;

export const logoImage = css`
  width: 80px;
  height: 80px;
`;

export const titleText = css`
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: right;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: flex-end;
  padding-bottom: 12px;
  padding-right: 32px;
  text-align: right;
  justify-content: end;
`;
