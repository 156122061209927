import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  hasCloseIcon?: boolean;
}

const ModalLayout = ({ onClose, isOpen, children }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay backgroundColor={'#0000007e'} w="100vw" h="100vh" />
      <ModalContent width="fit-content" maxW="fit-content" borderRadius="16px" background="#231F20" border="3px solid #C30D24" position={'relative'} overflow="hidden">
        {children}
      </ModalContent>
    </Modal>
  );
};

export default ModalLayout;
