import { useEffect, useMemo, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import ModalLayout from 'components/Modals/ModalLayout';
import ModalHeader from 'components/Modals/ModalHeader';
import BranchInformationView from './BranchInformationView';
import BranchInformationEdit from './BranchInformationEdit';
import BranchInformationCreate from './BranchInformationCreate';
import ConfirmModal from 'components/Modals/ConfirmModal';

type ModalMode = 'view' | 'create' | 'edit';

interface Props {
  modalMode: ModalMode;
  selectedBranchSequence: number;
  isOpenBranchInformationModal: boolean;
  onCloseBranchInformationModal: () => void;
}

const BranchInformationModal = ({ modalMode: initialModalMode, selectedBranchSequence, isOpenBranchInformationModal, onCloseBranchInformationModal }: Props) => {
  const { isOpen: isOpenUnsavedModal, onOpen: onOpenUnsavedModal, onClose: onCloseUnsavedModal } = useDisclosure();
  const [modalMode, setModalMode] = useState<ModalMode>(initialModalMode);

  const title = useMemo(() => {
    switch (modalMode) {
      case 'view':
        return '지점 정보 보기';
      case 'create':
        return '지점 등록';
      case 'edit':
        return '지점 정보 수정';
      default:
        return '';
    }
  }, [modalMode]);

  // 수정하기
  const handleEditClick = () => {
    setModalMode('edit');
  };

  // 작성 중 모달 나가겠습니까?
  const exitModalOnWriting = () => {
    onCloseUnsavedModal();
    onCloseBranchInformationModal();
  };

  const handleModalClose = () => {
    if (modalMode === 'create' || modalMode === 'edit') {
      onOpenUnsavedModal();
    } else {
      onCloseBranchInformationModal();
    }
  };

  // 모달 열리면 모드 초기화
  useEffect(() => {
    if (isOpenBranchInformationModal) {
      setModalMode(initialModalMode);
    }
  }, [isOpenBranchInformationModal, initialModalMode]);

  return (
    <ModalLayout isOpen={isOpenBranchInformationModal} onClose={handleModalClose}>
      <Flex flexDirection="column" w="1142px" h="838px">
        {/* modal header */}
        <ModalHeader title={title} />
        {/* 지점 생성 */}
        {modalMode === 'create' && <BranchInformationCreate onCloseBranchInformationModal={onCloseBranchInformationModal} onOpenUnsavedModal={onOpenUnsavedModal} />}
        {/* 지점 수정 */}
        {modalMode === 'edit' && <BranchInformationEdit selectedBranchSequence={selectedBranchSequence} onCloseBranchInformationModal={onCloseBranchInformationModal} onOpenUnsavedModal={onOpenUnsavedModal} />}
        {/* 지점 보기 */}
        {modalMode === 'view' && <BranchInformationView selectedBranchSequence={selectedBranchSequence} onEditClick={handleEditClick} onCloseBranchInformationModal={onCloseBranchInformationModal} />}
      </Flex>
      <ConfirmModal
        isOpen={isOpenUnsavedModal}
        confirmText="나가기"
        onConfirm={exitModalOnWriting}
        onClose={onCloseUnsavedModal}
        title={'작성중인 내용이 있습니다.\n 나가시겠습니까?'}
        description="저장하지 않고 페이지를 벗어날 경우, 지금까지 작성한 내용이 사라집니다."
      />
    </ModalLayout>
  );
};

export default BranchInformationModal;
