import { Flex, Grid, Stack, Text } from '@chakra-ui/react';
import useGetWashMachineList from 'features/branchManagement/hooks/react-query/useGetWashMachineList';
import Button from 'components/Button/Button';
import ModalHeader from 'components/Modals/ModalHeader';
import WashMachineLinkHeader from './WashMachineLinkHeader';
import WashMachineListHeader from './WashMachineListHeader';

interface Props {
  branchSeq: number;
  onModify: () => void;
  onClose: () => void;
}

const WashMachineLinkView = ({ branchSeq, onClose, onModify }: Props) => {
  const { data } = useGetWashMachineList(branchSeq);

  const branchName = data?.branchNm ?? '';
  const washMachineList = data?.connectedInfo ?? [];

  return (
    <Stack w="full" h="full">
      {/* modal header */}
      <ModalHeader title="지점 연동 관리" />

      {/* modal content */}
      <Flex flexDirection="column" alignItems="center" p="0 16px" w="full" flex={1}>
        <WashMachineLinkHeader title={branchName} />

        <WashMachineListHeader />

        <Flex flexDirection="column" w="full" flex={1}>
          {washMachineList.map((machine) => (
            <Grid key={machine.washMachineKey} templateColumns="repeat(4, 1fr)" alignItems="center" w="full" h="64px" p="12px 0" color="white" gap="12px">
              {/* 세차기 구분 */}
              <Text p="10px 8px" fontSize="14px" fontWeight="700" borderLeft="3px solid #C30D24" flex={1}>
                {machine.washMachineNm}
              </Text>

              {/* 모델명 */}
              <Text p="10px 8px" fontSize="14px" fontWeight="700" flex={1}>
                {machine.washMachineModel}
              </Text>

              {/* 세차기 고유번호 */}
              <Text p="10px 8px" fontSize="14px" fontWeight="700" flex={1}>
                {machine.washMachineKey}
              </Text>

              {/* PLC 포트 */}
              <Text p="10px 8px" fontSize="14px" fontWeight="700" flex={1}>
                {machine.plcSvcPort}
              </Text>
            </Grid>
          ))}
        </Flex>

        {/* modal footer */}
        <Flex justifyContent="center" gap="16px" p="24px 0">
          <Button buttonType="fill" buttonSize={40} onClick={onModify}>
            수 정
          </Button>

          <Button buttonType="outline" buttonSize={40} onClick={onClose}>
            취 소
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default WashMachineLinkView;
