import { Box, Flex, Stack } from '@chakra-ui/react';
import { StatusValue } from 'features/branchSearch/api/dtos/getBranchStatusList.dto';
import useTranslateBranchStatus from 'features/branchSearch/hooks/useTranslateBranchStatus';
import { colors } from 'theme';

interface WashMachineStatusProps {
  status: StatusValue;
  name: string;
  operatingFrequency: number;
}

export default function WashMachineStatus({ name, status, operatingFrequency }: WashMachineStatusProps) {
  const { translateBranchStatusColor } = useTranslateBranchStatus();

  return (
    <Stack spacing="2px">
      <Flex w="152px" h="8" align="center" justify="center" gap="6px" bg={colors.black} borderRadius={100} pr="38px">
        <Flex w="8" h="full" align="center" justify="center">
          <Box w="10px" h="10px" borderRadius={'full'} bg={translateBranchStatusColor(status)} />
        </Flex>
        <Box fontSize="14" fontWeight={700} color={colors.white} flex="1" textAlign="center">
          {name}
        </Box>
      </Flex>

      <Flex h="10" align="center" justify="center" fontSize="16" fontWeight={700} color={colors.white}>
        {operatingFrequency.toLocaleString()}회
      </Flex>
    </Stack>
  );
}
