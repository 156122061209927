import { useState } from 'react';
import AppLayoutContainer from '../containers/AppLayoutContainer';
import { Flex } from '@chakra-ui/react';
import BranchDetailContainer from '../features/branchDetail/containers/BranchDetailContainer';
import MachineDetailContainer from '../features/branchDetail/containers/MachineDetailContainer';

const BranchDetailPage = () => {
  const [noData, setNoData] = useState<boolean>(true);
  // 세차기 모델
  const [activeMachineKey, setActiveMachineKey] = useState<string>('');
  const [activeMachineIndex, setActiveMachineIndex] = useState<number>(0);

  const onChangeNoData = (is: boolean) => {
    setNoData(is);
  };

  // 세차기 상세정보 업데이트
  const onClickDetailMachineButton = (key: string, index: number) => {
    setActiveMachineKey(key);
    setActiveMachineIndex(index);
  };

  return (
    <AppLayoutContainer>
      <Flex flex={1} overflowY={'auto'} p={'16px'} gap="24px">
        <BranchDetailContainer activeMachineKey={activeMachineKey} onClickDetailMachineButton={onClickDetailMachineButton} onChangeNoData={onChangeNoData} />
        <MachineDetailContainer noData={noData} activeMachineIndex={activeMachineIndex} activeMachineKey={activeMachineKey} />
      </Flex>
    </AppLayoutContainer>
  );
};

export default BranchDetailPage;
