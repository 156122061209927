import { useCallback } from 'react';
import type { StatusValue } from '../api/dtos/getBranchStatusList.dto';

export default function useTranslateBranchStatus() {
  const translateBranchStatusLabel = useCallback((branchStatus: StatusValue) => {
    switch (branchStatus) {
      case 'W001':
        return '운영중';
      case 'W002':
        return '대기중';
      case 'W003':
        return '확인필요';
      case 'W004':
        return '연결끊김';
      case 'W005':
        return '등록필요';
      default:
        return '';
    }
  }, []);

  const translateBranchStatusColor = useCallback((branchStatus: StatusValue) => {
    switch (branchStatus) {
      case 'W001':
        return '#00FF00';
      case 'W002':
        return '#FFB800';
      case 'W003':
        return '#FF0000';
      case 'W004':
        return '#BBBABA';
      case 'W005':
        return '#BBBABA';
      default:
        return '';
    }
  }, []);

  return {
    translateBranchStatusLabel,
    translateBranchStatusColor,
  };
}
