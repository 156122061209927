import { Image } from '@chakra-ui/react';
import type { Marker } from '@googlemaps/markerclusterer';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useCallback, useMemo } from 'react';
import type { BranchListItemForMain } from 'features/branchSearch/api/dtos/getBranchListForMain.dto';
import MapMarkerPopUp from './MapMarkerPopUp';
import type { HandleBranchClickParams } from 'hooks/useSelectBranch';

export type TreeMarkerProps = {
  branchListItem: BranchListItemForMain;
  onClick: (tree: HandleBranchClickParams) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
  isSelected: boolean;
  onClose: () => void;
};

export const MapMarker = ({ branchListItem, onClick, setMarkerRef, isSelected, onClose }: TreeMarkerProps) => {
  const location = useMemo(() => {
    return {
      name: branchListItem.branchNm,
      position: { lat: branchListItem.gpsLat, lng: branchListItem.gpsLng },
    };
  }, [branchListItem.branchNm, branchListItem.gpsLat, branchListItem.gpsLng]);

  const handleClick = useCallback(() => {
    onClick({ branchSeq: branchListItem.branchSeq, isScroll: true, ...location });
  }, [onClick, branchListItem.branchSeq, location]);

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => {
      setMarkerRef(marker, location.name);
    },
    [setMarkerRef, location.name]
  );

  return (
    <AdvancedMarker
      //
      position={location.position}
      ref={ref}
      onClick={handleClick}
      zIndex={isSelected ? 1000005 : 0}
    >
      <Image src={'/assets/images/marker_icon.png'} width="28px" height="38px" />

      {isSelected && <MapMarkerPopUp branchSeq={branchListItem.branchSeq} onClose={onClose} />}
    </AdvancedMarker>
  );
};

export default MapMarker;
