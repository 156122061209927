import { queryOptions, useQuery } from '@tanstack/react-query';
import { getNewUrgentAlarmsApi } from 'features/alarm/api/alarm';

const NEW_URGENT_ALARM_LIST = 'NEW_URGENT_ALARM_LIST';

export const newUrgentAlarmsQueryOptions = queryOptions({
  queryKey: [NEW_URGENT_ALARM_LIST],
  queryFn: getNewUrgentAlarmsApi,
  refetchInterval: 1000 * 10,
});

export default function useGetNewUrgentAlarms() {
  return useQuery(newUrgentAlarmsQueryOptions);
}
