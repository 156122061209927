// 20240930 센서리스트 순서 세차기 1234 ->  제일 앞으로 옮기도록 코드 수정
import { Flex, Stack } from '@chakra-ui/react';
import MachineMonitoring from '../components/MachineMonitoring';
import { getWashMachine, getWashSensorListApi, updateWashMachineController } from '../api/wachMachineApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import MachineDetail from '../components/MachineDetail';
import { RemoteControlButtonType, WashMachineControllerRequestParameterType } from '../types';
import { useCallback, useMemo, useState } from 'react';
import { remoteControlButtons } from '../data/branchDetail';
import ControllerAlertModal from '../components/ControllerAlertModal';
import ControllerErrorAlertModal from '../components/ControllerErrorAlertModal';
import type { WashSensorListItem } from '../api/dtos/getWashSensorList.dto';


interface MachineDetailProps {
  noData: boolean;
  activeMachineKey: string;
  activeMachineIndex: number;
}

const MachineDetailContainer = ({ noData, activeMachineKey, activeMachineIndex }: MachineDetailProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [selectedRemoteButton, setSelectedRemoteButton] = useState<RemoteControlButtonType | null>(null);

  // 세차기 상세 조회
  const { data: washMachineData, refetch: refetchWashMachine } = useQuery({
    queryKey: ['GetWashMachine', activeMachineKey],
    queryFn: () => getWashMachine(activeMachineKey || ''),
    enabled: !!activeMachineKey || !noData,
    select: (data) => data.washMachineInfoList[0],
  });

  // 세차기 센서 조회
  const { data: sensorList } = useQuery({
    queryKey: ['getWashSensorCode', activeMachineKey],
    queryFn: () => getWashSensorListApi(activeMachineKey || ''),
    enabled: !!activeMachineKey || !noData,
    select: (data) => {
      const { washMachineKey: _washMachineKey, ...restSensors } = data.sensorDTOList[0];
      return restSensors;
    },
    // refetchInterval: 1000 * 10,
    refetchInterval: 1000 * 2,
  });


  const detailSensorList = useMemo(() => Object.entries(sensorList || {}), [sensorList]);

  const onOpenModal = (index: number) => {
    const button = remoteControlButtons[index];
    setIsModalOpen(true);
    setSelectedRemoteButton(button);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRemoteButton(null);
  };

  const onCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessChangeController = () => {
    setIsModalOpen(false);
    setSelectedRemoteButton(null);
  };

  const handleErrorChangeController = () => {
    setIsModalOpen(false);
    setIsErrorModalOpen(true);
    setSelectedRemoteButton(null);
  };

  // 원격 제어
  const { mutate: remoteControl, isPending } = useMutation({
    mutationKey: ['updateWashMachineController'],
    mutationFn: updateWashMachineController,
    onSuccess: handleSuccessChangeController,
    onError: handleErrorChangeController,
  });

  const onConfirmModal = useCallback(() => {
    if (!selectedRemoteButton) return;

    const params: WashMachineControllerRequestParameterType = { washMachineKey: activeMachineKey, apiType: selectedRemoteButton.apiType };
    if (selectedRemoteButton?.course) params.course = selectedRemoteButton?.course;
    if (selectedRemoteButton?.onOff) params.state = selectedRemoteButton?.onOff;
    remoteControl(params);
  }, [activeMachineKey, remoteControl, selectedRemoteButton]);

  return (
    !noData && (
      <Flex direction={'column'} w={'100%'} h={'full'}>
        <Stack gap="24px" flex={1}>
          {washMachineData && (
            <MachineMonitoring
              //
              activeMachineIndex={activeMachineIndex}
              key={washMachineData?.washMachineKey}
              data={washMachineData}
              handleClickRefresh={refetchWashMachine}
              sensors={sensorList as WashSensorListItem}
            />
          )}
          <MachineDetail sensorList={detailSensorList} selectedRemoteButton={selectedRemoteButton} onOpenModal={onOpenModal} />
        </Stack>
        {/* modal */}
        <ControllerAlertModal isPending={isPending} isOpen={isModalOpen} onClose={onCloseModal} remoteName={selectedRemoteButton?.name || ''} onConfirm={onConfirmModal} />
        <ControllerErrorAlertModal isOpen={isErrorModalOpen} onClose={onCloseErrorModal} remoteName={selectedRemoteButton?.name || ''} />
      </Flex>
    )
  );
};

export default MachineDetailContainer;


