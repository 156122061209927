import { api } from 'api/api';
import { CodeRegionGroupResponseType, CodeRegionGroupType, DashBoardDetailRequestType, DashBoardDetailResponseType } from '../types';
import queryString from 'query-string';

export const fetchRegion = async (codeRegionGroup: CodeRegionGroupType): Promise<CodeRegionGroupResponseType> => {
  try {
    const response = await api.get(`/region/dashboard/regionCategory/${codeRegionGroup}/`);
    return response.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const fetchDashBoardDetail = async (data: DashBoardDetailRequestType): Promise<DashBoardDetailResponseType> => {
  try {
    const query = queryString.stringify(
      {
        codeRegion: data.codeRegion,
        page: data.page,
        size: data.size,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    const response = await api.get(`branch/getBranchs/DashBoardDetail?${query}&${data.codeType}`);
    return response.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
