import { useEffect } from 'react';
import { Flex, Grid, Input, Stack, Text, useDisclosure } from '@chakra-ui/react';
import useGetWashMachineList from 'features/branchManagement/hooks/react-query/useGetWashMachineList';
import ExitConfirmationModal from '../ExitConfirmationModal/ExitConfirmationModal';
import Button from 'components/Button/Button';
import ModalHeader from 'components/Modals/ModalHeader';
import WashMachineLinkHeader from './WashMachineLinkHeader';
import WashMachineListHeader from './WashMachineListHeader';
import useUpdateWashMachineConnect from 'features/branchManagement/hooks/react-query/useUpdateWashMachineConnect';
import { useFieldArray, useForm } from 'react-hook-form';
import AlertModal from 'components/Modals/AlertModal';

interface FormValues {
  plcSvcPortList: { plcSvcPort: string }[];
}

interface Props {
  branchSeq: number;
  onClose: () => void;
}

const WashMachineLinkEditForm = ({ branchSeq, onClose }: Props) => {
  const { isOpen: isOpenExitConfirmationModal, onOpen: onOpenExitConfirmationModal, onClose: onCloseExitConfirmationModal } = useDisclosure();
  const { isOpen: isOpenLinkSuccessModal, onOpen: onOpenLinkSuccessModal } = useDisclosure();
  const { isOpen: isOpenLinkFailedModal, onOpen: onOpenLinkFailedModal, onClose: onCloseLinkFailedModal } = useDisclosure();

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { isValid, isDirty },
  } = useForm<FormValues>();
  useFieldArray({ control, name: 'plcSvcPortList' });

  // 세차기 목록 조회
  const { data } = useGetWashMachineList(branchSeq);

  // dynamic 필드 초기값 설정 (isDirty는 초기값을 기준으로 판단하기 때문에 reset을 이용하여 초기값 설정)
  useEffect(() => {
    if (!data) return;

    reset({ plcSvcPortList: data.connectedInfo.map(() => ({ plcSvcPort: '' })) });
  }, [data, reset]);

  const branchName = data?.branchNm ?? '';
  const washMachineList = data?.connectedInfo ?? [];

  // 세차기 연동
  const { mutate: updateWashMachineConnect } = useUpdateWashMachineConnect({ branchSeq, onOpenLinkSuccessModal, onOpenLinkFailedModal });

  const onSubmit = (values: FormValues) => {
    const washMachineConnectList = washMachineList.map((machine, index) => ({
      washMachineKey: machine.washMachineKey,
      plcSvcPort: +values.plcSvcPortList[index].plcSvcPort,
    }));

    updateWashMachineConnect(washMachineConnectList);
  };

  const onExit = () => {
    onCloseExitConfirmationModal();
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack w="full" h="full">
        {/* modal header */}
        <ModalHeader title="지점 연동 관리" />

        {/* modal content */}
        <Flex flexDirection="column" alignItems="center" p="0 16px" w="full" flex={1}>
          <WashMachineLinkHeader title={branchName} />

          <WashMachineListHeader />

          <Flex flexDirection="column" w="full" flex={1}>
            {washMachineList.map((machine, index) => (
              <Grid key={machine.washMachineKey} templateColumns="repeat(4, 1fr)" alignItems="center" w="full" h="64px" p="12px 0" color="white" gap="12px">
                {/* 세차기 구분 */}
                <Text p="10px 8px" fontSize="14px" fontWeight="700" borderLeft="3px solid #C30D24" flex={1}>
                  {machine.washMachineNm}
                </Text>

                {/* 모델명 */}
                <Text p="10px 8px" fontSize="14px" fontWeight="700" flex={1}>
                  {machine.washMachineModel}
                </Text>

                {/* 세차기 고유번호 */}
                <Text p="10px 8px" fontSize="14px" fontWeight="700" flex={1}>
                  {machine.washMachineKey}
                </Text>

                {/* PLC 포트 */}
                <Input type="number" {...register(`plcSvcPortList.${index}.plcSvcPort`, { required: true })} bg="#fff" borderRadius="4px" p="4px" w="128px" color="#000" />
              </Grid>
            ))}
          </Flex>

          {/* modal footer */}
          <Flex justifyContent="center" gap="16px" p="24px 0">
            <Button type="submit" buttonType="fill" buttonSize={40} disabled={!isValid}>
              연 동
            </Button>
            <Button type="button" buttonType="outline" buttonSize={40} onClick={isDirty ? onOpenExitConfirmationModal : onClose}>
              취 소
            </Button>
          </Flex>
        </Flex>
      </Stack>

      <AlertModal title="연동이 완료되었습니다." isOpen={isOpenLinkSuccessModal} onClose={onClose} />
      <AlertModal title="연동에 실패하였습니다." description="PLC 포트 셋팅을 확인하여 다시 시도하여 주십시오." isOpen={isOpenLinkFailedModal} onClose={onCloseLinkFailedModal} />
      <ExitConfirmationModal isOpen={isOpenExitConfirmationModal} onExit={onExit} onCloseExitConfirmationModal={onCloseExitConfirmationModal} />
    </form>
  );
};

export default WashMachineLinkEditForm;
