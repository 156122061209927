import { useQueryParam, type QueryParamConfig } from 'use-query-params';
import { useCallback, useMemo } from 'react';
import type { BranchRegionOption, BranchRegionOptionValue } from './useBranchRegionOptions';

interface UseBranchRegionQueryParams {
  branchRegionOptions: BranchRegionOption[];
}

export default function useBranchRegionQuery({ branchRegionOptions }: UseBranchRegionQueryParams) {
  const BranchRegionArrayParam: QueryParamConfig<string | (string | null)[] | null | undefined, BranchRegionOptionValue[]> = useMemo(
    () => ({
      encode: (branchRegions: string | (string | null)[] | null | undefined) => branchRegions,
      decode: (values: string | (string | null)[] | null | undefined) =>
        (values
          ? Array.isArray(values)
            ? //
              values.filter((v) => branchRegionOptions.find((region) => region.value === v))
            : [values]
          : ['all']) as BranchRegionOptionValue[],
    }),
    [branchRegionOptions]
  );
  const [codeRegion, setCodeRegion] = useQueryParam<BranchRegionOptionValue[]>('codeRegion', BranchRegionArrayParam);

  const onSelectBranchRegion = useCallback(
    (selectedRegion: BranchRegionOptionValue) => () => {
      setCodeRegion((prevCodeRegion) => {
        if (!prevCodeRegion) return [];

        const addedRegion = [...prevCodeRegion, selectedRegion];

        // 'all'이 이미 선택된 경우, 'all'을 제외한 나머지를 선택
        if (prevCodeRegion.includes('all')) {
          return addedRegion.filter((region) => region !== 'all');
        }

        // 선택된 지역이 이미 포함된 경우, 해당 지역을 제거
        if (prevCodeRegion.includes(selectedRegion)) {
          return prevCodeRegion.filter((branch) => branch !== selectedRegion);
        }

        // all'이 선택된 경우, 'all'만 선택
        // 모든 지역이 선택된 경우, 'all'만 선택
        if (selectedRegion === 'all' || addedRegion.length === branchRegionOptions.length - 1) {
          return ['all'];
        }

        // 선택된 지역을 추가
        return addedRegion;
      });
    },
    [branchRegionOptions.length, setCodeRegion]
  );

  return {
    codeRegion,
    onSelectBranchRegion,
  };
}
