const colors = {
  grayBlack: '#231f20',
  gray: '#393536',
  gray1: '#626474',
  gray2: '#9395a6',
  gray3: '#b7b9c9',
  gray4: '#e4e6f0',
  gray5: '#f3f4fa',
  gray6: '#f8f8fc',
  blue: '#6B77F8',
  teal: '#60C8DE',
  green: '#00ff00',
  pink: '#E18BFF',
  red: '#C30D24',
  darkRed: '#B32828',
  orange: '#FF9255',
  yellow: '#FAC746',
  white: '#fff',
  black: '#231f20',
};

export default colors;
