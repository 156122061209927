import { type SubmitHandler } from 'react-hook-form';
import type { WashMachineUpdateFormValues } from '../types/WashMachineEdit.types';
import { useCallback } from 'react';
import useUpdateBranchWashMachine from './react-query/useUpdateBranchWashMachineList';
import type { UpdateBranchWashMachineDto } from '../api/dtos/updateBranchWashMachineList.dto';
import { branchWashMachineUpdateFormSchema } from '../schema/branchWashMachineForm.schema';
import type { MachineTypeValue } from 'features/branchSearch/api/dtos/getMachineTypeList.dto';

interface UseWashMachineEditParams {
  machineModelType: MachineTypeValue;
  onChangeViewMode: () => void;
  onOpenRequiredFieldsModal: () => void;
}

export default function useWashMachineEdit({
  //
  machineModelType,
  onChangeViewMode,
  onOpenRequiredFieldsModal,
}: UseWashMachineEditParams) {
  // 세차기 수정
  const { mutate: updateBranchWashMachine } = useUpdateBranchWashMachine({ onClose: onChangeViewMode });
  const onSubmit: SubmitHandler<WashMachineUpdateFormValues> = useCallback(
    (data) => {
      const validationResults = data.branchWashMachineList.map((item) => branchWashMachineUpdateFormSchema.safeParse(item));
      const hasInvalid = validationResults.some((result) => !result.success);

      if (hasInvalid) return onOpenRequiredFieldsModal();

      const transformedData: UpdateBranchWashMachineDto[] = data.branchWashMachineList.map((item) => ({
        branchSeq: item.branchSeq,
        washMachineKey: item.washMachineKey,
        codeType: machineModelType,
        codeModel: item.codeModel!,
        plcPrivider: item.plcPrivider!,
        plcModel: item.plcModel!,
        plcVersion: item.plcVersion,
      }));

      updateBranchWashMachine(transformedData);
    },
    [machineModelType, onOpenRequiredFieldsModal, updateBranchWashMachine]
  );

  return { onSubmit };
}
