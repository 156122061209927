import { Badge, Grid, Td, Text } from '@chakra-ui/react';
interface TableProps {
  status: {
    color: string;
    text: string;
  };
  value: string | number;
}

export default function DashboardTableItem({ status, value }: TableProps) {
  return (
    <Td h="36px" paddingY="15px" textAlign="center" verticalAlign={'middle'} borderX={`1px solid #f9f9f9`} _last={{ borderX: 'none' }} _first={{ borderX: 'none' }}>
      {status && (
        <Grid display="flex" alignItems="center">
          <Badge mr="5px" w="8px" h="8px" borderRadius="50%" style={{ background: status?.color }}></Badge>
          <Text style={{ color: status?.color }} fontSize={10}>
            {status?.text}
          </Text>
        </Grid>
      )}
      {!status && !value && '-'}
      {value && !status && value}
    </Td>
  );
}
