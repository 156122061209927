import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getBranchStatusDetailListApi } from 'features/branchSearch/api/branchSearch';
import type { BranchSearchParams } from 'features/branchSearch/types/BranchSearch.types';

const BRANCH_STATUS_DETAIL_LIST = 'BRANCH_STATUS_DETAIL_LIST';
const BRACN_PAGE_SIZE = 24;

export default function useGetBranchStatusDetailList({ codeRegion, codeType, searchVal }: BranchSearchParams) {
  return useInfiniteQuery({
    queryKey: [BRANCH_STATUS_DETAIL_LIST, { codeRegion }, { codeType }, { searchVal }],
    queryFn: ({ pageParam: page }) =>
      getBranchStatusDetailListApi({
        codeRegion,
        codeType,
        searchVal: searchVal ? searchVal : undefined,
        page,
        size: BRACN_PAGE_SIZE,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage.hasNextPage) {
        return null;
      }
      return lastPage.page + 1;
    },
    enabled: !!codeRegion && !!codeType,
    placeholderData: keepPreviousData,
    refetchInterval: 1000 * 10,
  });
}
