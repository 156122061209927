import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MapMarker from './MapMarker';
import type { BranchListItemForMain } from 'features/branchSearch/api/dtos/getBranchListForMain.dto';
import { useSetRecoilState } from 'recoil';
import { googleMapState, selectedBranchSeqState, selectedMarkerKeyState } from 'atoms/googleMap';
import useSelectBranch from 'hooks/useSelectBranch';
import { MAP_DEFAULT_CENTER } from 'common/constants';
import { useLocation } from 'react-router-dom';
import { smoothZoom } from '../utils/smoothZoom';
import useBranchScrollTo from 'features/branchSearch/hooks/useBranchScrollTo';

interface GoogleMapProps {
  branchList: BranchListItemForMain[];
}

const GoogleMap = ({ branchList }: GoogleMapProps) => {
  const location = useLocation();
  const map = useMap();
  const setGoogleMap = useSetRecoilState(googleMapState);
  const setSelectedMarkerKey = useSetRecoilState(selectedMarkerKeyState);
  const setSelectedBranchSeq = useSetRecoilState(selectedBranchSeqState);

  const { scrollToTop } = useBranchScrollTo();

  // 지점 리스트에서 사용하기 위해 전역 상태로 관리
  useEffect(() => {
    if (!map) return;

    setGoogleMap(map);
  }, [map, setGoogleMap]);

  // Home버튼을 눌렀을 때, default 상태로 초기화
  useEffect(() => {
    if (!map) return;

    if (location.pathname === '/home' && !location.search) {
      setSelectedMarkerKey(null);
      setSelectedBranchSeq(null);
      map.panTo(MAP_DEFAULT_CENTER); // set map center to marker position
      smoothZoom({ map, targetZoom: 7, currentZoom: map.getZoom() ?? 0 });

      // 지점 리스트 스크롤 상단으로 위치
      scrollToTop();
    }
  }, [location, map, setSelectedBranchSeq, setSelectedMarkerKey, scrollToTop]);

  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const { selectedMarkerKey, handleBranchClick, handleInfoWindowClose } = useSelectBranch();

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      onClusterClick: (e) => {
        if (!e.latLng?.toJSON) return;

        map.panTo(e.latLng.toJSON());
        smoothZoom({ map, targetZoom: 16, currentZoom: map.getZoom() ?? 0 });
      },
      renderer: {
        render: ({ count, position }) => {
          const div = document.createElement('div');
          div.style.background = 'radial-gradient(circle, #c30d24, rgba(195, 13, 36, 0))'; // 그라데이션 배경색
          div.style.borderRadius = '50%';
          div.style.width = '50px';
          div.style.height = '50px';
          div.style.display = 'flex';
          div.style.justifyContent = 'center';
          div.style.alignItems = 'center';
          div.style.color = '#FFFFFF';
          div.style.fontSize = '14px';
          div.textContent = String(count);

          return new google.maps.marker.AdvancedMarkerElement({
            position,
            content: div,
          });
        },
      },
    });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <Map mapId="dc870e1490f255e0" defaultCenter={MAP_DEFAULT_CENTER} defaultZoom={8} disableDefaultUI={true} gestureHandling={'greedy'}>
      {branchList.map((branchListItem) => (
        <MapMarker
          //
          key={branchListItem.branchSeq}
          branchListItem={branchListItem}
          onClick={handleBranchClick}
          setMarkerRef={setMarkerRef}
          isSelected={branchListItem.branchNm === selectedMarkerKey}
          onClose={handleInfoWindowClose}
        />
      ))}
    </Map>
  );
};

export default GoogleMap;
