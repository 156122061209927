import Scrollbar from 'components/Scrollbar/Scrollbar';
import SensorItem from './SensorItem';
import RemoteControl from './RemoteControl';
import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { colors } from 'theme';
import { remoteControlButtons } from '../data/branchDetail';
import { RemoteControlButtonType } from '../types';
import { css } from '@emotion/react';
import { SensorStatus } from '../api/dtos/getWashSensorList.dto';

interface MachineDetailProps {
  sensorList: [string, SensorStatus][];
  selectedRemoteButton: RemoteControlButtonType | null;
  onOpenModal: (index: number) => void;
}

export default function MachineDetail({ sensorList, selectedRemoteButton, onOpenModal }: MachineDetailProps) {
  return (
    <Stack spacing={'0'} h={'calc(100vh - 642px)'} minH={'300px'} bg={colors.black} borderRadius={'10px'} paddingBottom={'16px'}>
      {/* header */}
      <Flex justify={'center'} align={'center'} w={'full'} h={'36px'}>
        <Text fontSize={'14px'} fontWeight={700} color="white">
          센서 목록
        </Text>
      </Flex>

      {/* body */}
      <Box h={'calc(100vh - 642px - 36px - 16px)'} p={'8px'} bg={'#393536'}>
        <Scrollbar
          css={css`
            width: 100%;
            height: 100%;
            overflow-y: auto;
          `}
        >
          {/* 원격 제어 버튼 */}
          <Flex flexDirection={'column'} gap={'12px'} pr={'8px'}>
            <Text fontSize={'14px'} fontWeight={700} color={'white'} p={'8px'} borderBottom={'1px solid #fff'}>
              원격 제어 버튼
            </Text>

            <Grid templateColumns="repeat(5, 1fr)" w="100%" gap={'12px 16px'}>
              {remoteControlButtons.map((controlButton, index) => {
                const selectedButton = controlButton.name === selectedRemoteButton?.name;

                return <RemoteControl key={controlButton.name} controlButton={controlButton} onOpenModal={() => onOpenModal(index)} selectedButton={selectedButton} />;
              })}
            </Grid>
          </Flex>

          {/* 센서 목록 */}
          <Flex flexDirection={'column'} gap={'12px'}>
            <Text fontSize={'14px'} fontWeight={700} color={'white'} p={'8px'} borderBottom={'1px solid #fff'}>
              센서 목록
            </Text>

            <Grid templateColumns="repeat(5,1fr)" w="100%" gap={'12px 16px'}>
              {sensorList &&
                sensorList?.map(([sensorKey, SensorStatus]) => {
                  return <SensorItem key={sensorKey} SensorStatus={SensorStatus} />;
                })}
            </Grid>
          </Flex>
        </Scrollbar>
      </Box>
    </Stack>
  );
}
