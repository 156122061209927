/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import FeatherIcons from 'theme/featherIcons';
import { customPaginationCss } from './CustomPagination.styles';
import type { Interpolation, Theme } from '@emotion/react';

/**
 * 1 2 3 4 5 6 7 8 9 10
 * 11 12 13 14 15 16 17 18 19 20
 * 21 22 23 24 25 26 27 28 29 30
 * ...
 */
const PAGE_RANGE_SIZE = 10;

interface CustomPaginationProps {
  totalPages: number;
  currentPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onClickPage: (page: number) => () => void;
  cssStyle?: Interpolation<Theme>;
}

export default function CustomPagination({ totalPages, currentPage, onPreviousPage, onNextPage, onClickPage, cssStyle }: CustomPaginationProps) {
  const [pageGroup, setPageGroup] = useState(0);

  useEffect(() => {
    const selectedGroup = Math.floor((currentPage - 1) / PAGE_RANGE_SIZE);
    if (selectedGroup !== pageGroup) {
      setPageGroup(selectedGroup);
    }
  }, [currentPage, pageGroup]);

  return (
    <div css={[customPaginationCss.container, cssStyle]}>
      <button onClick={onPreviousPage} css={customPaginationCss.button}>
        <FeatherIcons.ChevronLeft size={20} />
      </button>

      <ul css={customPaginationCss.list}>
        {[...Array(totalPages).keys()].slice(pageGroup * PAGE_RANGE_SIZE, (pageGroup + 1) * PAGE_RANGE_SIZE).map((item, index) => {
          const page = pageGroup * PAGE_RANGE_SIZE + (index + 1);

          return (
            <li key={index} onClick={onClickPage(page)}>
              <button
                css={[
                  //
                  customPaginationCss.button,
                  customPaginationCss.page,
                  currentPage === page && customPaginationCss.active,
                ]}
              >
                {item + 1}
              </button>
            </li>
          );
        })}
      </ul>

      <button onClick={onNextPage} css={customPaginationCss.button}>
        <FeatherIcons.ChevronRight size={20} />
      </button>
    </div>
  );
}
