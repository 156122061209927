import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Image, Stack, Grid } from '@chakra-ui/react';
import Title from '../../../components/Title/Title';
import BranchInformation from '../components/BranchInformation';
import BranchMachine from '../components/BranchMachine';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getBranchDetail, getBranchWashCount, updateBranch } from '../api/branchDetailApi';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { WashMachineType } from '../types/washmachine.type';
import { getWashMachineList, updateWashMachine } from '../api/wachMachineApi';
import { UploadFileResponseType } from '../types/file.type';
import ConfirmModal from 'components/Modals/ConfirmModal';

interface BranchDetailProps {
  activeMachineKey: string;
  onClickDetailMachineButton: (key: string, index: number) => void;
  onChangeNoData: (is: boolean) => void;
}

const BranchDetailContainer = ({ activeMachineKey, onClickDetailMachineButton, onChangeNoData }: BranchDetailProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenEditCancel, setIsOpenEditCancel] = useState(false);

  const handleSuccessUpdateBranch = () => {
    refetchBranchDetail();
    setIsEditMode(false);
  };
  const handleErrorUpdateBranch = () => {
    setIsEditMode(false);
  };

  // 지점 상세 정보 조회
  const { data: branchInfoData, refetch: refetchBranchDetail } = useQuery({
    queryKey: ['getBranchDetail', id],
    queryFn: () => getBranchDetail(id || ''),
    enabled: !!id,
    select: (data) => ({ ...data, dvcInstallDt: format(new Date(data.dvcInstallDt), 'yyyy-MM-dd') }),
    refetchInterval: 1000 * 10,
  });

  // 지점 세차기 횟수 조회
  const { data: branchWashCountData } = useQuery({
    queryKey: ['getBranchWashCount', id],
    queryFn: () => getBranchWashCount(id || ''),
    enabled: !!id,
  });

  // 지점 세차기 목록 조회
  const { data: washMachineList, refetch: refetchWashMachineList } = useQuery({
    queryKey: ['getWashMachineList', id],
    queryFn: () => getWashMachineList(id || ''),
    enabled: !!id,
    select: (data) => data.washMachineList,
  });

  // 지점 상세 정보 수정
  const { mutate: mutateUpdateBranch } = useMutation({
    mutationKey: ['updateBranch'],
    mutationFn: updateBranch,
    onSuccess: handleSuccessUpdateBranch,
    onError: handleErrorUpdateBranch,
  });

  // 세차기 썸네일 정보 수정
  const { mutate: mutateWashMachine } = useMutation({
    mutationKey: ['updateWashMachine'],
    mutationFn: updateWashMachine,
    onSuccess: refetchWashMachineList,
  });

  const onPreviousButton = () => {
    navigate('/branch');
  };

  const onChangeIsEditMode = (is: boolean) => {
    setIsEditMode(is);
  };

  const onClickEditCancel = () => {
    setIsOpenEditCancel(true);
  };

  const onCloseEditCancelModal = () => {
    setIsOpenEditCancel(false);
  };
  const onConfirmEditCancelModal = () => {
    setIsOpenEditCancel(false);
    setIsEditMode(false);
  };

  const onSubmit = (data: any) => {
    mutateUpdateBranch({ branchSeq: Number(id), ...data });
  };

  const onChangeThumbnail = (washMachineKey: string, res: UploadFileResponseType) => {
    mutateWashMachine({
      washMachineKey,
      thumbImgSeq: res.seq,
    });
  };

  // 세차기 목록 조회 후 첫번째 세차기 정보조회
  useEffect(() => {
    if (washMachineList && !activeMachineKey) {
      onClickDetailMachineButton(washMachineList[0]?.washMachineKey, 0);
    }
  }, [activeMachineKey, onClickDetailMachineButton, washMachineList]);

  useEffect(() => {
    onChangeNoData(!branchInfoData);
  }, [branchInfoData, onChangeNoData]);

  return (
    <Stack spacing={'16px'}>
      <Flex h="80px" gap={'16px'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap="6px" w="72px" bg="red.500" color="white" onClick={onPreviousButton} cursor={'pointer'}>
          <Image src="/assets/icons/arrowLeft.png" alt="이전" w={'45px'} height={'45px'} />
          <Text>이전</Text>
        </Box>
        <Box w="80%" color="white">
          <Title title={branchInfoData ? branchInfoData.branchNm : '-'} />
        </Box>
      </Flex>
      {branchInfoData ? (
        <Flex h={'calc(100% - 96px)'} gap="24px">
          {/* 지점 정보 */}
          <Flex flex={1} flexDirection="column" minH={'764px'}>
            {branchInfoData && branchWashCountData && (
              <BranchInformation isEditMode={isEditMode} branchInfoData={branchInfoData} branchWashCountData={branchWashCountData} onClickEditCancel={onClickEditCancel} onChangeIsEditMode={onChangeIsEditMode} onSubmit={onSubmit} />
            )}
          </Flex>

          {/* 세차기 리스트 */}
          <Grid templateRows="repeat(4, 1fr)" gridAutoFlow="column" w={'409px'} gap={'24px'}>
            {washMachineList?.map((machine: WashMachineType, index: number) => (
              <BranchMachine key={machine.washMachineKey} index={index} machine={machine} activeMachineKey={activeMachineKey} onClickDetailMachineButton={onClickDetailMachineButton} onChangeThumbnail={onChangeThumbnail} />
            ))}
          </Grid>
        </Flex>
      ) : (
        <Flex h="90%" alignItems="center" color="#fff" fontWeight={700}>
          <Text>조회 결과가 없습니다.</Text>
        </Flex>
      )}
      <ConfirmModal
        isOpen={isOpenEditCancel}
        confirmText="나가기"
        onConfirm={onConfirmEditCancelModal}
        onClose={onCloseEditCancelModal}
        title={'작성중인 내용이 있습니다.\n 나가시겠습니까?'}
        description="저장하지 않고 페이지를 벗어날 경우, 지금까지 작성한 내용이 사라집니다."
      />
    </Stack>
  );
};

export default BranchDetailContainer;
