import { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export default function useSearchBranch() {
  const [keyword, setKeyword] = useState('');
  const [searchVal, setSearchVal] = useQueryParam<string>('searchVal', withDefault(StringParam, ''));

  // 초기화
  useEffect(() => {
    setKeyword(searchVal);
  }, [searchVal]);

  const onChangeKeyword = useCallback((value: string) => {
    setKeyword(value);
  }, []);

  const onSearch = useCallback(() => {
    setSearchVal(keyword);
  }, [keyword, setSearchVal]);

  return {
    keyword,
    onChangeKeyword,
    onSearch,
    searchVal,
  };
}
