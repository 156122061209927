interface NotificationTableHeader {
  label: string;
  minWidth: string;
  width: string;
}

export const notificationLogTableHeaders: NotificationTableHeader[] = [
  { label: '유형', minWidth: '80px', width: '80px' },
  { label: '발생시간', minWidth: '168px', width: '168px' },
  { label: '지역', minWidth: '120px', width: '120px' },
  { label: '지점이름', minWidth: '240px', width: '240px' },
  { label: '세차기', minWidth: '200px', width: '200px' },
  { label: '이슈내용', minWidth: '392px', width: '392px' },
];
