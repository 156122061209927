import { useInterceptEffect } from 'features/auth/hooks/useInterceptEffect';
import useGetMe from 'features/userProfile/hooks/useGetMe';

export default function GlobalEffect() {
  // 로그인한 유저 정보 가져오기
  useGetMe();
  // 인터셉터
  useInterceptEffect();

  return null;
}
